import React, { useState, useEffect } from "react";
import { Redirect, BrowserRouter, Switch, useHistory } from "react-router-dom";
import { withStyles } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";
import Cookies from "js-cookie";

import SideNav from "./components/SideNav";
// import NewFeature from "./components/NewFeature";
import PrivateRoute from "../../components/PrivateRoute";
import ProductList from "../ProductList/ProductList";
import CreateProduct from "../CreateProduct/CreateProduct";
import EditProduct from "../CreateProduct/EditProduct";
import AnimatedLogo from "../../components/AnimatedLogo";
import OrderList from "../OrderList/OrderList";
import UserSettings from "../UserSettings/UserSettings";
import ShopSettings from "../ShopSettings/ShopSettings";
import PromoCodes from "../PromoCodes/PromoCodes";
import Delivery from "../Delivery";
import Subscription from "../Subscription/Subscription";
import { OrdersProvider } from "../../hooks/useOrdersContext";
import { ShopProvider } from "../../hooks/useShopContext";
import SubscriptionOld from "../Subscription/SubscriptionOld";
import Dashboard from "../Dashboard/Dashboard";
// import AnnouncementModal from "./components/NewFeature";

const styles = (theme) => ({
  mainPanel: {
    overflow: "auto",
    position: "relative",
    float: "right",
    width: "calc(100% - 240px)",
    minHeight: "100vh",
    overflowScrolling: "touch",
    backgroundColor: theme.palette.background.dark,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  container: {
    flexGrow: 1,
    width: "100%",
    padding: "10px 5px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  loading: {
    width: window.innerWidth,
    height: window.innerHeight,
    backgroundColor: theme.palette.background.default,
  },
});

const HomePage = ({ classes }) => {
  const history = useHistory();
  const isMobile = useMediaQuery((theme) => theme.breakpoints.down("sm"), { noSsr: true });

  const [loading, setLoading] = useState(true);
  const [drawerOpen, setDrawerOpen] = useState(isMobile ? false : true);

  useEffect(() => {
    if (!Cookies.get("t1")) {
      history.push("/login");
    }
    setLoading(false);
  }, [history]);

  if (loading) {
    return (
      <div className={`${classes.container} ${classes.loading}`}>
        <AnimatedLogo style={{ maxWidth: "10%" }} />
      </div>
    );
  }

  return (
    <BrowserRouter>
      <ShopProvider>
        {/* <AnnouncementModal /> */}
        <div style={{ display: !isMobile ? "flex" : "block" }}>
          <SideNav isMobile={isMobile} drawerOpen={drawerOpen} setDrawerOpen={setDrawerOpen} />

          {/* <div className={classes.mainPanel}> */}
          <div style={{ flexGrow: 1 }}>
            {/* <NewFeature /> */}
            <Switch>
              <PrivateRoute exact path="/home/shop/*" render={() => <ShopSettings />} />
              <PrivateRoute exact path="/home/subscription" render={() => <Subscription />} />
              <PrivateRoute
                exact
                path="/home/subscription/old"
                render={() => <SubscriptionOld />}
              />
              <PrivateRoute
                exact
                path="/home/orders"
                render={() => (
                  <OrdersProvider>
                    <OrderList />
                  </OrdersProvider>
                )}
              />
              <PrivateRoute exact path="/home/products" render={() => <ProductList />} />
              <PrivateRoute exact path="/home/products/categories" render={() => <ProductList />} />
              <PrivateRoute exact path="/home/products/layout" render={() => <ProductList />} />
              <PrivateRoute exact path="/home/promos" render={() => <PromoCodes />} />
              <PrivateRoute exact path="/home/delivery" render={() => <Delivery />} />
              <PrivateRoute exact path="/home/products/new" render={() => <CreateProduct />} />
              <PrivateRoute
                path="/home/products/:id"
                strict
                sensitive
                render={(match) => <EditProduct match={match} />}
              />
              <PrivateRoute exact path="/home/settings/*" render={() => <UserSettings />} />
              <PrivateRoute exact path="/home/dashboard" render={() => <Dashboard />} />
              <Redirect from="/home" to="/home/shop/general" />
            </Switch>
          </div>
        </div>
      </ShopProvider>
    </BrowserRouter>
  );
};

export default withStyles(styles)(HomePage);

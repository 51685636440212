import React, { useState, useEffect } from "react";
import { makeStyles, Typography, Button } from "@material-ui/core";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSnackbar } from "notistack";

import FaqDialog from "./faq/FaqDialog";
import FaqCard from "./faq/FaqCard";
import GenericContainer from "../../../components/containers/GenericContainer";
import useShopContext from "../../../hooks/useShopContext";
import { Add } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0),
  },
  headerDescription: {
    marginBottom: "12px",
  },
  faqContainer: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    margin: `${theme.spacing(1)}px 0`,
    borderRadius: 4,
  },
  gridItem: {
    padding: theme.spacing(1.5, 0),
  },
  leftMargin: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(4.5),
  },
  buttonContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: "10px 0 25px",
  },
}));

const FaqSettings = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { shopfront, setShopfront, loading } = useShopContext();

  const [faqDialog, setFaqDialog] = useState(false);
  const [faqList, setFaqList] = useState([]);
  const [faqIndex, setFaqIndex] = useState(-1);

  const selectFaqForEdit = (index) => {
    setFaqIndex(index);
    setFaqDialog(true);
  };

  const deleteFaq = (index) => {
    const reorderedFaqs = Array.from(faqList);
    reorderedFaqs.splice(index, 1);
    setFaqIndex(-1);
    updateFaqs(reorderedFaqs);
    enqueueSnackbar("FAQ deleted.", { variant: "success" });
  };

  const onDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const reorderedFaqs = Array.from(faqList);
    const [removed] = reorderedFaqs.splice(result.source.index, 1);
    reorderedFaqs.splice(result.destination.index, 0, removed);

    updateFaqs(reorderedFaqs);
  };

  const getListStyle = (isDraggingOver) => ({
    padding: 8,
    background: isDraggingOver ? "#FFF2D2" : "#ebebeb",
  });

  useEffect(() => {
    if (!loading) {
      setFaqList(shopfront.shop_settings?.faqs || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const updateFaqs = (newFaqs) => {
    setFaqList(newFaqs);
    const shop_settings = shopfront.shop_settings || {};
    shop_settings.faqs = newFaqs;
    setShopfront({
      ...shopfront,
      shop_settings,
    });
  };

  return (
    <>
      <GenericContainer>
        <Typography variant="h5" className={classes.header}>
          FAQ
        </Typography>
        <Typography variant="body1" color="textSecondary" className={classes.headerDescription}>
          Answer your customers' commonly asked questions.
        </Typography>

        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classes.faqContainer}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {faqList.map((faq, index) => (
                  <Draggable key={index} draggableId={`faq-${index}`} index={index}>
                    {(provided, snapshot) => (
                      <FaqCard
                        faq={faq}
                        index={index}
                        provided={provided}
                        selectFaqForEdit={selectFaqForEdit}
                        deleteFaq={deleteFaq}
                      />
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
        <Button
          startIcon={<Add />}
          onClick={() => {
            setFaqIndex(-1);
            setFaqDialog(true);
          }}
          className={classes.addButton}
        >
          Add FAQ
        </Button>
      </GenericContainer>
      <FaqDialog
        open={faqDialog}
        setOpen={setFaqDialog}
        faqList={faqList}
        setFaqList={updateFaqs}
        index={faqIndex}
      />
    </>
  );
};

export default FaqSettings;

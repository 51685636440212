import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Switch } from "react-router-dom";
import { Helmet } from "react-helmet";

import PageTitle from "../../components/PageTitle";
import ProductsTable from "./components/ProductsTable";
import PrivateRoute from "../../components/PrivateRoute";
import ProductCategories from "./components/ProductCategories";
import ShopProducts from "../ShopSettings/components/ShopProducts";

import useAuth from "../../hooks/useAuth";
import LoadingSplashScreen from "../../components/LoadingSplashScreen";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 190px)", // padding: 30px * 2, title: 80px + 50px, total 190px
    margin: 30,
  },
  footer: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: 50,
    background: theme.palette.common.white,
    width: "100%",
    boxShadow: "0px 3px 15px 0px rgba(0,0,0,0.3)",
    WebkitBoxShadow: "0px 3px 15px 0px rgba(0,0,0,0.3)",
    zIndex: 10,
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(1, 2),
  },
  primaryButton: {
    textTransform: "none",
    width: 120,
  },
}));

const ProductList = () => {
  const classes = useStyles();
  const { loading } = useAuth();

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Products | Carte</title>
      </Helmet>
      <PageTitle title="Products" />
      {loading ? <LoadingSplashScreen /> : null}
      <Switch>
        <PrivateRoute exact path="/home/products" render={() => <ProductsTable />} />
        <PrivateRoute
          exact
          path="/home/products/categories"
          render={() => (
            <>
              <ProductCategories />
            </>
          )}
        />
        <PrivateRoute
          exact
          path="/home/products/layout"
          render={() => (
            <>
              <ShopProducts />
            </>
          )}
        />
      </Switch>
    </div>
  );
};

export default ProductList;

import React, { useState, useEffect } from "react";
import {
  makeStyles,
  Typography,
  TextField,
  Button,
  InputAdornment,
  IconButton,
} from "@material-ui/core";
import { DragIndicator, Delete, Add } from "@material-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import GenericContainer from "../../../components/containers/GenericContainer";
import useShopContext from "../../../hooks/useShopContext";
import { getUrlIcon } from "../../../iconUtils";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0),
  },
  headerDescription: {
    marginBottom: "12px",
  },
  saveButton: {
    width: 150,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
  textField: {
    marginBottom: theme.spacing(1.5),
    borderRadius: "5px",
    width: "100%",
  },
  inputAdornment: {
    color: theme.palette.grey[600],
    display: "flex",
    gap: theme.spacing(0.5),
  },
  linkRow: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  dragHandle: {
    cursor: "move",
    color: theme.palette.grey[400],
  },
  addButton: {
    marginTop: theme.spacing(1),
  },
}));

const LinkSettings = () => {
  const classes = useStyles();
  const { shopfront, setShopfront, loading } = useShopContext();
  const [links, setLinks] = useState(shopfront.shop_settings?.links || []);

  const handleInputChange = (index, value) => {
    const newLinks = [...links];
    newLinks[index] = value;
    updateLinks(newLinks);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) return;
    const newLinks = [...links];
    const [removed] = newLinks.splice(result.source.index, 1);
    newLinks.splice(result.destination.index, 0, removed);
    updateLinks(newLinks);
  };

  const addNewLink = () => {
    updateLinks([...links, ""]);
  };

  const removeLink = (index) => {
    const newLinks = [...links];
    newLinks.splice(index, 1);
    updateLinks(newLinks);
  };

  const updateLinks = (newLinks) => {
    setLinks(newLinks);
    const shop_settings = shopfront.shop_settings || {};
    shop_settings.links = newLinks;
    setShopfront({
      ...shopfront,
      shop_settings,
    });
  };

  useEffect(() => {
    if (!loading) {
      setLinks(shopfront?.shop_settings?.links || []);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <GenericContainer>
      <Typography variant="h5" className={classes.header}>
        Social Links
      </Typography>
      <Typography variant="body1" color="textSecondary" className={classes.headerDescription}>
        These links will be displayed on your shopfront. Add or remove links as needed.
      </Typography>

      <DragDropContext onDragEnd={handleDragEnd}>
        <Droppable droppableId="links">
          {(provided) => (
            <div {...provided.droppableProps} ref={provided.innerRef}>
              {links.map((link, index) => {
                return (
                  <Draggable key={index} draggableId={`link-${index}`} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        className={classes.linkRow}
                      >
                        <div {...provided.dragHandleProps}>
                          <DragIndicator className={classes.dragHandle} />
                        </div>
                        <TextField
                          fullWidth
                          type="url"
                          value={link}
                          onChange={(e) => handleInputChange(index, e.target.value)}
                          variant="outlined"
                          className={classes.textField}
                          margin="dense"
                          autoComplete="off"
                          InputProps={{
                            startAdornment: (
                              <InputAdornment position="start" className={classes.inputAdornment}>
                                {getUrlIcon(link)}
                              </InputAdornment>
                            ),
                          }}
                        />
                        <IconButton size="small" onClick={() => removeLink(index)}>
                          <Delete />
                        </IconButton>
                      </div>
                    )}
                  </Draggable>
                );
              })}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button startIcon={<Add />} onClick={addNewLink} className={classes.addButton}>
        Add Link
      </Button>
    </GenericContainer>
  );
};

export default LinkSettings;

import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const createElement = (elementFormData, shopfrontId, vendorId) => {
  return Auth.client.post(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/shopfronts/${shopfrontId}/elements`,
    elementFormData,
  );
};

export const editElement = (elementFormData, elementId, shopfrontId, vendorId) => {
  return Auth.client.put(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/shopfronts/${shopfrontId}/elements/${elementId}`,
    elementFormData,
  );
};

export const deleteElement = (element, shopfrontId, vendorId) => {
  return Auth.client.delete(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/shopfronts/${shopfrontId}/elements/${element.element_id}`,
  );
};

export const reorderElements = (elementIdsArr, shopfrontId, vendorId) => {
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/shopfronts/${shopfrontId}/elements`,
    elementIdsArr,
  );
};

export const updateVendorLogo = (vendorId, image) => {
  let formData = new FormData();
  formData.set("company_photo", image);
  return Auth.client.put(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/logo`, formData);
};

export const updateShopLogo = (vendor_id, shopfront_id, image) => {
  let formData = new FormData();
  formData.set("logo", image);
  return Auth.client.put(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendor_id}/shopfronts/${shopfront_id}/logo`,
    formData,
  );
};

export const deleteVendorLogo = (vendorId) => {
  let formData = new FormData();
  formData.append("company_photo", "");
  return Auth.client.put(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/logo`, formData);
};

export const deleteShopLogo = (vendor_id, shopfront_id) => {
  let formData = new FormData();
  formData.append("logo", "");
  return Auth.client.put(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendor_id}/shopfronts/${shopfront_id}/logo`,
    formData,
  );
};

export const updateCustomDomain = (vendor_id, shopfront_id, domain) => {
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendor_id}/shopfronts/${shopfront_id}/cname`,
    {
      domain: domain,
      shopfront: shopfront_id,
    },
  );
};

// unused
export const updateUserSettings = (user) => {
  const updatedDetails = {
    subdomain: user.subdomain,
    tnc: user.tnc,
    enable_seasonal_features: user.enable_seasonal_features,
  };
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendors`, updatedDetails);
};

import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";
import { startOfDay } from "date-fns";

export const getVendorPromoCodes = (vendorId) => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/promocodes`);
};

export const createPromoCode = (vendorId, obj) => {
  const promo_code = { ...obj, start_date: startOfDay(obj.start_date) };
  return Auth.client.post(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/promocodes`,
    promo_code,
  );
};

export const editPromoCode = (vendorId, obj) => {
  const promo_code = { ...obj, start_date: startOfDay(obj.start_date) };
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/promocodes/${promo_code.id}`,
    promo_code,
  );
};

export const deletePromoCode = (vendorId, deletePromoId) => {
  return Auth.client.delete(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/promocodes/${deletePromoId}`,
  );
};

export const updatePromoCodeStatus = (vendorId, promoCode) => {
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/promocodes/${promoCode.promo_code_id}`,
    {
      active: !promoCode.active,
    },
  );
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  TextField,
  Button,
  Chip,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";

import useAuth from "../../../hooks/useAuth";
import { hasFeature } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  dialogHeader: {
    margin: theme.spacing(1, 0),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 3),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(3),
    padding: theme.spacing(1, 2),
  },
  image: {
    objectFit: "contain",
    height: 140,
  },
  chip: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    width: "80px",
  },
  dialogAction: {
    padding: theme.spacing(1, 2),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
}));

const EditImageDialog = ({ open, onClose, image, setImage, updateImage, deleteImage, edit }) => {
  const classes = useStyles();
  const { user } = useAuth();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      classes={{ paper: classes.paper }}
      onExited={() => setImage({ image_id: "", image: "", image_caption: "" })}
    >
      <div className={classes.dialogHeader}>
        <Typography variant="h5" style={{ fontWeight: 500 }}>
          Image Details
        </Typography>
        <IconButton size="small" onClick={onClose}>
          <Close />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        <img src={edit ? image.image : image.data} alt={`image_edited`} className={classes.image} />
        <div>
          <div style={{ display: "flex", alignItems: "center", gap: 8 }}>
            <Typography variant="body1">Image Caption</Typography>
            <Chip
              variant="outlined"
              size="small"
              label="Premium"
              classes={{ root: classes.chip }}
            />
          </div>
          <TextField
            variant="outlined"
            margin="dense"
            multiline
            rows={2}
            fullWidth
            placeholder="Max. 70 characters"
            value={image && image.image_caption ? image.image_caption : ""}
            onChange={(e) => setImage({ ...image, image_caption: e.target.value })}
            helperText={`Caption provides additional information to what the image is conveying.`}
            disabled={!hasFeature("product_image_caption", user.features)}
            inputProps={{ maxLength: 70 }}
          />
        </div>
      </DialogContent>
      <DialogActions className={classes.dialogAction}>
        <Button
          className={classes.deleteButton}
          onClick={() => {
            deleteImage(image);
            onClose();
          }}
        >
          Delete
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => updateImage(image)}
          disabled={!hasFeature("product_image_caption", user.features)}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditImageDialog;

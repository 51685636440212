import React, { useState } from "react";
import { IconButton, InputAdornment, makeStyles, TextField, Typography } from "@material-ui/core";
import { FilterNoneOutlined } from "@material-ui/icons";
import CopyToClipboard from "react-copy-to-clipboard";

import useShopContext from "../../../hooks/useShopContext";
import GenericContainer from "../../../components/containers/GenericContainer";
import { FRONTEND_URL } from "../../../config";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
}));

const ReferralDetails = () => {
  const classes = useStyles();
  const { shopfront } = useShopContext();

  const [copied, setCopied] = useState(false);

  return (
    <GenericContainer style={{ gap: 12 }}>
      <Typography variant="h5" className={classes.header}>
        Refer your friends!
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Loving our service? Refer your friends using the link below and get{" "}
        <b>25% off your next premium payment</b> for each successful referral! (Capped at 2
        referrals per month)
      </Typography>
      <TextField
        variant="outlined"
        margin="dense"
        helperText={copied ? "Copied!" : ""}
        value={`${FRONTEND_URL}/register?ref=${shopfront.subdomain}`}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <CopyToClipboard
                text={`${FRONTEND_URL}/register?ref=${shopfront.subdomain}`}
                onCopy={() => setCopied(true)}
              >
                <IconButton size="small">
                  <FilterNoneOutlined fontSize="small" />
                </IconButton>
              </CopyToClipboard>
            </InputAdornment>
          ),
        }}
      />
    </GenericContainer>
  );
};

export default ReferralDetails;

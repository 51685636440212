import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, CircularProgress, Grid, Hidden, IconButton } from "@material-ui/core";
import { DeleteOutline, DoneAll, FileCopy } from "@material-ui/icons";
import { Prompt, useHistory, useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useSnackbar } from "notistack";

import ProductDetails from "./components/ProductDetails";
import ProductVariants from "./components/ProductVariants";
import ProductImages from "./components/ProductImages";
import AdditionalQuestions from "./components/AdditionalQuestions";
import ProductPreview from "./components/ProductPreview";

import DeleteProductDialog from "./components/DeleteProductDialog";
import PageTitle from "../../components/PageTitle";
import { hasFeature } from "../../utils";

import {
  deleteProduct,
  duplicateProduct,
  getProduct,
  updateProduct,
  updateProductVariant,
} from "./api";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 30,
  },
  buttonBase: {
    textTransform: "none",
    margin: theme.spacing(0, 1),
    color: theme.palette.common.white,
  },
  deleteButton: {
    textTransform: "none",
    margin: theme.spacing(0, 1),
    color: theme.palette.error.main,
  },
  deleteCircularProgress: {
    color: theme.palette.error.main,
  },
  anchor: {
    textTransform: "none",
    textDecoration: "none",
  },
  header: {
    fontWeight: 600,
    margin: theme.spacing(2, 0),
  },
  gridItem: {
    maxWidth: "100%",
  },
  lastGridItem: {
    marginBottom: 40,
  },
  footer: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: 50,
    background: theme.palette.common.white,
    width: "100%",
    boxShadow: "0px 3px 15px 0px rgba(0,0,0,0.3)",
    WebkitBoxShadow: "0px 3px 15px 0px rgba(0,0,0,0.3)",
    zIndex: 10,
    display: "flex",
    justifyContent: "space-between",
    padding: theme.spacing(1, 1),
    [theme.breakpoints.up("md")]: {
      padding: theme.spacing(1, 2),
    },
  },
  footerLeft: {
    display: "flex",
    [theme.breakpoints.up("md")]: {
      marginLeft: 265,
    },
  },
  footerRight: {
    display: "flex",
  },
  duplicateButton: {
    color: theme.palette.common.black,
  },
}));

const EditProduct = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { user, vendor, loading: vendor_loading } = useAuth();

  const [product, setProduct] = useState({
    title: "",
    description: "",
    inventory: null,
    original_price: null,
    show_percentage_discount: false,
    price: 0,
    product_questions: [],
    use_variants: false,
    variants: [],
    available_start_time: null,
    available_end_time: null,
  });
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);

  const [images, setImages] = useState([]);
  const [formIsHalfFilled, setFormIsHalfFilled] = useState(false);
  const [updatingProduct, setUpdatingProduct] = useState(false);
  const [deletingProduct, setDeletingProduct] = useState(false);
  const [duplicating, setDuplicating] = useState(false);

  const [loading, setLoading] = useState(true);

  const history = useHistory();
  const { id } = useParams();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setUpdatingProduct(true);

    if (!product.title || !product.description) {
      enqueueSnackbar("Missing name or description", { variant: "error" });
      setUpdatingProduct(false);
      return;
    }

    if (images.length === 0) {
      enqueueSnackbar("Upload at least a photo. Photos sell.", { variant: "error" });
      setUpdatingProduct(false);
      return;
    }

    if (parseFloat(product.price) <= 0 || product.price === "") {
      enqueueSnackbar("Invalid price", { variant: "error" });
      setUpdatingProduct(false);
      return;
    }

    if (product.original_price !== null && product.original_price === "") {
      enqueueSnackbar("Original price should not be empty", { variant: "error" });
      setUpdatingProduct(false);
      return;
    }

    if (
      product.original_price !== null &&
      parseFloat(product.original_price) <= parseFloat(product.price)
    ) {
      enqueueSnackbar("Original price should be higher", { variant: "error" });
      setUpdatingProduct(false);
      return;
    }

    if (product.inventory && parseInt(product.inventory) <= 0) {
      enqueueSnackbar("Invalid inventory", { variant: "error" });
      setUpdatingProduct(false);
      return;
    }

    if (product.available_end_time && product.available_start_time) {
      if (product.available_end_time <= product.available_start_time) {
        enqueueSnackbar("End time has to be later than start time", { variant: "error" });
        setUpdatingProduct(false);
        return;
      }
    }

    setFormIsHalfFilled(false);

    let errorMsg;

    // call edit product endpoint
    await updateProduct(vendor.id, product, id)
      .then((res) => {
        // console.log(res);
      })
      .catch((err) => {
        console.log(err);
        errorMsg = err.response.data || "Error updating product";
      })
      .finally(() => setUpdatingProduct(false));

    if (!errorMsg && product.use_variants) {
      if (product.variants.length <= 1) {
        enqueueSnackbar("Set minimum 2 variants", { variant: "error" });
        setUpdatingProduct(false);
        return;
      }

      await updateProductVariant(
        product.product_id,
        product,
        vendor,
        hasFeature("product_image_caption", user.features),
      )
        .then((res) => {
          // console.log(res);
        })
        .catch((err) => {
          errorMsg = err.response.data || "Error updating variant";
        })
        .finally(() => setUpdatingProduct(false));
    }

    enqueueSnackbar(
      errorMsg
        ? `${errorMsg}, please try again or email help@carteapp.io for support`
        : `Product updated`,
      {
        variant: errorMsg ? "error" : "success",
      },
    );
    if (!errorMsg) {
      history.push("/home/products");
    }
  };

  const handleDelete = () => {
    setDeletingProduct(true);
    deleteProduct(vendor.id, id)
      .then((res) => {
        enqueueSnackbar("Product deleted!", { variant: "success" });
        history.push("/home/products");
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar(`Oops, something went wrong (${err.response.status})`, {
          variant: "error",
        });
        setDeletingProduct(false);
      });
  };

  const handleDuplicate = () => {
    setDuplicating(true);
    duplicateProduct(vendor.id, id)
      .then((res) => {
        // console.log(res);
        setDuplicating(false);
        enqueueSnackbar("Product duplicated", { variant: "success" });
        history.push("/home/products");
      })
      .catch((err) => {
        console.log(err);
        setDuplicating(false);
        enqueueSnackbar(`Error occured when duplicating this product`, { variant: "error" });
      });
  };

  const getProductDetails = () => {
    setLoading(true);
    getProduct(vendor.id, id)
      .then((res) => {
        // console.log(res);

        setProduct(res.data);

        setImages(res.data.images);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  useEffect(() => {
    if (!vendor_loading) {
      getProductDetails();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vendor_loading]);

  const alertUser = (e) => {
    e.preventDefault();
    e.returnValue = "";
  };

  useEffect(() => {
    if (formIsHalfFilled) {
      // covers for situations when history.action is POP or PUSH
      // PUSH means entering create product page from other pages
      // POP means when user clicks on browser refresh button
      window.addEventListener("beforeunload", alertUser);
      return () => {
        window.removeEventListener("beforeunload", alertUser);
      };
    }
  }, [formIsHalfFilled, history.action]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Edit Product | Carte</title>
      </Helmet>
      <Prompt
        when={formIsHalfFilled}
        message={() => {
          return "Changes you made may not be saved. Continue to leave site?";
        }}
      />

      <form onSubmit={handleSubmit}>
        <PageTitle title="Update Product" style={{ margin: 8 }} />
        <Grid container spacing={2} justify="center">
          <Grid container item xs={12} md={6} direction="column" spacing={3}>
            <Grid className={classes.gridItem} item>
              <ProductDetails
                product={product}
                setProduct={setProduct}
                formIsHalfFilled={formIsHalfFilled}
                setFormIsHalfFilled={setFormIsHalfFilled}
              />
            </Grid>

            <Grid className={classes.gridItem} item>
              <ProductImages
                images={images}
                setImages={setImages}
                formIsHalfFilled={formIsHalfFilled}
                setFormIsHalfFilled={setFormIsHalfFilled}
                product={product}
                setProduct={setProduct}
                loading={loading}
                edit
              />
            </Grid>

            <Grid className={classes.gridItem} item>
              <ProductVariants
                product={product}
                setProduct={setProduct}
                enqueueSnackbar={enqueueSnackbar}
                edit
              />
            </Grid>

            <Grid className={`${classes.gridItem} ${classes.lastGridItem}`} item>
              <AdditionalQuestions
                product={product}
                setProduct={setProduct}
                formIsHalfFilled={formIsHalfFilled}
                setFormIsHalfFilled={setFormIsHalfFilled}
                enqueueSnackbar={enqueueSnackbar}
              />
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid item md={6}>
              <ProductPreview product={product} images={images} loading={loading} edit />
            </Grid>
          </Hidden>
        </Grid>

        <div className={classes.footer}>
          <div className={classes.footerLeft}>
            <Hidden mdUp>
              <IconButton
                size="small"
                className={classes.duplicateButton}
                style={{ marginLeft: "10px" }}
                onClick={() => handleDuplicate()}
              >
                <FileCopy fontSize="small" />
              </IconButton>
            </Hidden>
            <Hidden smDown>
              {duplicating ? (
                <Button
                  className={`${classes.buttonBase} ${classes.duplicateButton}`}
                  startIcon={<CircularProgress size="1.5rem" color="inherit" />}
                >
                  Duplicating
                </Button>
              ) : (
                <Button
                  className={`${classes.buttonBase} ${classes.duplicateButton}`}
                  startIcon={<FileCopy size="1.5rem" color="inherit" />}
                  onClick={() => handleDuplicate()}
                >
                  Duplicate
                </Button>
              )}
            </Hidden>
          </div>
          <div className={classes.footerRight}>
            <Button
              onClick={() => setDeleteDialogOpen(true)}
              className={classes.deleteButton}
              startIcon={
                deletingProduct ? (
                  <CircularProgress size="1.5rem" className={classes.deleteCircularProgress} />
                ) : (
                  <DeleteOutline />
                )
              }
              disabled={updatingProduct}
            >
              {deletingProduct ? "Deleting" : "Delete"}
            </Button>
            <Button
              className={classes.buttonBase}
              variant="contained"
              color="primary"
              type="submit"
              startIcon={
                updatingProduct ? <CircularProgress size="1.5rem" color="inherit" /> : <DoneAll />
              }
              disabled={deletingProduct}
            >
              {updatingProduct ? "Saving Changes" : "Save Changes"}
            </Button>
          </div>
        </div>
      </form>
      <DeleteProductDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        product={product}
        handleDelete={handleDelete}
      />
    </div>
  );
};

export default EditProduct;

import React, { useEffect, useState } from "react";
import { Button, TextField, Typography, Grid } from "@material-ui/core";
import { useSnackbar } from "notistack";
import GenericContainer from "../../../components/containers/GenericContainer";
import useShopContext from "../../../hooks/useShopContext";
import { updateCustomDomain } from "../api";
import { SHOP_DOMAIN } from "../../../config";

const CustomDomain = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { shopfront, setShopfront, loading } = useShopContext();
  const [domain, setDomain] = useState(shopfront?.custom_domain?.domain || "");
  const [error, setError] = useState("");

  useEffect(() => {
    if (!loading) {
      setDomain(shopfront?.custom_domain?.domain || "");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const handleInputChange = (e) => {
    setDomain(e.target.value);
    setError("");
  };

  const validateDomain = (domain) => {
    const domainRegex = /^(?!:\/\/)([a-zA-Z0-9-_]+\.)*[a-zA-Z0-9][a-zA-Z0-9-_]+\.[a-zA-Z]{2,11}?$/;
    return domainRegex.test(domain);
  };

  const handleSubmit = () => {
    if (domain && !validateDomain(domain)) {
      setError("Invalid domain format");
      return;
    }

    updateCustomDomain(shopfront.vendor.id, shopfront.shopfront_id, domain)
      .then((res) => {
        enqueueSnackbar("Custom domain updated successfully!", { variant: "success" });
        setShopfront({
          ...shopfront,
          custom_domain: res.data,
        });
      })
      .catch((err) => {
        enqueueSnackbar(`Error: ${err.response.data}`, { variant: "error" });
      });
  };

  return (
    <GenericContainer>
      <Typography variant="h5" gutterBottom>
        Custom Domain
      </Typography>

      <Grid container spacing={3}>
        <Grid item xs={12}>
          <TextField
            label="Custom Domain"
            variant="outlined"
            fullWidth
            margin="normal"
            value={domain}
            onChange={handleInputChange}
            error={!!error}
            helperText={error}
          />
          <Typography variant="body2" color="textSecondary">
            Do not enter http or any trailing slashes. Kindly enter the domain name alone
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Save
          </Button>
        </Grid>
        {domain && (
          <Grid item xs={12}>
            {!shopfront?.custom_domain?.verified ? (
              <Typography variant="body2" color="textSecondary">
                Please add a CNAME record pointing to <strong>cname.{SHOP_DOMAIN}</strong> in your
                DNS settings. Click on the save button after adding the CNAME record.
              </Typography>
            ) : (
              <Typography variant="body2" color="textSecondary">
                Domain configured successfully.
              </Typography>
            )}
          </Grid>
        )}
      </Grid>
    </GenericContainer>
  );
};

export default CustomDomain;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";

import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";
import useAuth from "../../../hooks/useAuth";

const colors = ["#52796F", "#8A7090", "#FFA065"];

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "#E4E4E4 2px",
    backgroundColor: "#FFFFFF",
    maxWidth: "100%",
    padding: "25px",
  },
  root: {
    display: "flex",
    justifyContent: "space-between",
  },
  blockHeader: {
    fontWeight: "700",
    fontSize: "25px",
    marginBottom: theme.spacing(1),
  },
  flexItem: {
    flexGrow: 1,
    flexDirection: "column",
    flexBasis: 0,
    padding: "10px",
    margin: `${theme.spacing(1)}px`,
    borderRadius: "20px",
    color: "#FFFFFF",
  },
}));

const InfoBox = ({ value, name, index }) => {
  const classes = useStyles();
  return (
    <div className={classes.flexItem} style={{ backgroundColor: colors[index] }}>
      <Typography variant="h3" style={{ fontWeight: "700", marginBottom: "10px" }}>
        {value}
      </Typography>
      <Typography variant="h6">{name}</Typography>
    </div>
  );
};

const OrderInfoBlock = ({ paperStyle, containerStyle }) => {
  const { vendor, loading } = useAuth();
  const classes = useStyles();

  const [orderInfo, setOrderInfo] = useState();

  const getData = () => {
    Auth.client
      .get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendor.id}/metrics/orders`)
      .then((res) => {
        // console.log(res);
        setOrderInfo(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!loading) {
      getData();
    } // eslint-disable-next-line
  }, [loading]);

  // const orderInfo = [
  //   {
  //     name: "Orders Today",
  //     value: 15,
  //   },
  //   {
  //     name: "Orders Pending",
  //     value: 7,
  //   },
  //   {
  //     name: "Orders In Progress",
  //     value: 8,
  //   },
  // ];

  return (
    <Paper className={classes.paper} style={{ ...paperStyle }}>
      <Typography className={classes.blockHeader}>Orders Overview</Typography>
      <div className={classes.root} style={{ ...containerStyle }}>
        {orderInfo && orderInfo.map((order, i) => <InfoBox key={i} {...order} index={i} />)}
      </div>
    </Paper>
  );
};

export default OrderInfoBlock;

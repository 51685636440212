import React from "react";
import { makeStyles, useTheme } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "#E4E4E4 2px",
    backgroundColor: "#FFFFFF",
    maxWidth: "100%",
    padding: "15px",
    display: "flex",
    justifyContent: "space-between",
    marginBottom: theme.spacing(1),
  },
  flexItem: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  },
}));

const InfoBlock = ({ title, value, percentage, icon }) => {
  /*
    input: 
    {
      title: "",
      value: "" || 10,
      percentage: 0.10,
      icon: () => <Icon />
    }
   */

  const classes = useStyles();
  const theme = useTheme();

  return (
    <Paper className={classes.paper}>
      <div className={classes.flexItem}>
        <Typography variant="h6">{title}</Typography>
        <Typography style={{ fontSize: "32px", fontWeight: "700", lineHeight: 1 }}>{value}</Typography>
      </div>
      <div className={classes.flexItem} style={{ justifyContent: "flex-end" }}>
        <Typography
          variant="h5"
          style={{ color: percentage < 0 ? theme.palette.error.main : theme.palette.success.main, fontWeight: "700" }}
        >
          {percentage && (percentage < 0 ? `↓${parseInt(percentage * -100)}%` : `↑${parseInt(percentage * 100)}%`)}
        </Typography>
      </div>
      <div className={classes.flexItem} style={{ alignItems: "flex-end" }}>
        {icon()}
      </div>
    </Paper>
  );
};

export default InfoBlock;

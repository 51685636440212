import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Checkbox,
  FormControlLabel,
  FormGroup,
  TextField,
  Typography,
  InputAdornment,
} from "@material-ui/core";
import { DateRangeTwoTone } from "@material-ui/icons";
import { add, format, getDay, isEqual, startOfDay } from "date-fns";
import { useSnackbar } from "notistack";

import GenericContainer from "../../../components/containers/GenericContainer";
import Calendar from "../../../components/Calendar/Calendar";

import { updateVendorDeliveryScheduleInfo } from "../api";
import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
  settingsIcon: {
    marginRight: theme.spacing(1.5),
  },
  flexContainer: {
    display: "flex",
    margin: theme.spacing(1, 0),
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  flexItem: {
    flex: 1,
    padding: theme.spacing(1),
  },
  optionsContainer: {
    flex: 1,
    padding: theme.spacing(3, 3, 0),
    [theme.breakpoints.down("sm")]: {
      padding: theme.spacing(1),
    },
  },
  blockedDateDelivery: {
    backgroundColor: theme.palette.error.main,
    opacity: 0.8,
  },
  blockedDateSelfCollection: {
    backgroundColor: theme.palette.warning.main,
    opacity: 0.8,
  },
  blockedDate: {
    background: `linear-gradient(135deg, ${theme.palette.error.main} 50%, ${theme.palette.warning.main} 50%);`,
    opacity: 0.8,
  },
  saveContainer: {
    margin: theme.spacing(2, 0),
    display: "flex",
    justifyContent: "flex-end",
  },
  legendContainer: {
    display: "flex",
    gap: theme.spacing(1),
    padding: theme.spacing(2),
    alignItems: "center",
    justifyContent: "center",
  },
  legendText: {
    fontSize: 12,
    [theme.breakpoints.down("xs")]: {
      fontSize: 8,
    },
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
}));

const weekdays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"];

const Schedule = () => {
  const { vendor, setVendor, loading } = useAuth();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [minDate, setMinDate] = useState(new Date());
  const [date, setDate] = useState(new Date());
  const [blockedWeekDaysDelivery, setBlockedWeekDaysDelivery] = useState([]);
  const [blockedDatesDelivery, setBlockedDatesDelivery] = useState([]);

  const [blockedWeekDaysSelfCollection, setBlockedWeekDaysSelfCollection] = useState([]);
  const [blockedDatesSelfCollection, setBlockedDatesSelfCollection] = useState([]);
  const [maxDateRange, setMaxDateRange] = useState(120);
  const [daysInAdvance, setDaysInAdvance] = useState(3);

  useEffect(() => {
    if (!loading && vendor) {
      setDaysInAdvance(vendor.days_in_advance);
      const minDate = add(new Date(), { days: vendor.days_in_advance });
      setMinDate(minDate);
      setDate(minDate);
      setMaxDateRange(vendor.max_date_range);
      if (vendor.blocked_dates_delivery && vendor.blocked_week_days_delivery) {
        setBlockedWeekDaysDelivery(vendor.blocked_week_days_delivery);
        setBlockedDatesDelivery(vendor.blocked_dates_delivery.map((date) => new Date(date)));

        setBlockedWeekDaysSelfCollection(vendor.blocked_week_days_self_collection);
        setBlockedDatesSelfCollection(
          vendor.blocked_dates_self_collection.map((date) => new Date(date)),
        );
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const tileClassName = (event) => {
    const deliveryBlocked =
      blockedWeekDaysDelivery[getDay(event.date)] ||
      blockedDatesDelivery.find((d) => isEqual(startOfDay(d), startOfDay(event.date)));
    const selfCollectionBlocked =
      blockedWeekDaysSelfCollection[getDay(event.date)] ||
      blockedDatesSelfCollection.find((d) => isEqual(startOfDay(d), startOfDay(event.date)));

    if (deliveryBlocked && selfCollectionBlocked) {
      return classes.blockedDate;
    }
    if (deliveryBlocked) {
      return classes.blockedDateDelivery;
    }
    if (selfCollectionBlocked) {
      return classes.blockedDateSelfCollection;
    }
  };

  const handleSaveSchedule = async () => {
    await updateVendorDeliveryScheduleInfo(
      vendor.id,
      blockedDatesDelivery,
      blockedWeekDaysDelivery,
      blockedDatesSelfCollection,
      blockedWeekDaysSelfCollection,
      maxDateRange,
      daysInAdvance,
    )
      .then((res) => {
        // console.log(res);
        setVendor(res.data);
        enqueueSnackbar("Schedule updated", { variant: "success" });
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar("Something went wrong. Try again later", { variant: "error" });
      });
  };

  return (
    <GenericContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <DateRangeTwoTone className={classes.settingsIcon} />
        <Typography variant="h5" className={classes.header}>
          Delivery Schedule
        </Typography>
      </div>
      <Typography variant="body2" color="textSecondary">
        Applicable to delivery options with selectable dates
      </Typography>

      <div className={classes.flexContainer}>
        <div className={classes.flexItem}>
          <Calendar
            value={date}
            onChange={(value, event) => {
              setDate(value);
            }}
            tileClassName={tileClassName}
            minDate={minDate}
            maxDate={add(minDate, { days: maxDateRange - 1 })}
          />
          <div className={classes.legendContainer}>
            <div style={{ height: 10, width: 10 }} className={classes.blockedDateDelivery} />
            <Typography className={classes.legendText} color="textSecondary">
              Delivery
            </Typography>
            <div style={{ height: 10, width: 10 }} className={classes.blockedDateSelfCollection} />
            <Typography className={classes.legendText} color="textSecondary">
              Self Collection
            </Typography>
            <div style={{ height: 10, width: 10 }} className={classes.blockedDate} />
            <Typography className={classes.legendText} color="textSecondary">
              Both
            </Typography>
          </div>
        </div>
        <div className={classes.optionsContainer}>
          <Typography variant="body1" style={{ fontWeight: "bold", marginBottom: 8 }}>
            Today's date: {format(date, "dd-MM-yyyy")}
          </Typography>
          <TextField
            margin="dense"
            variant="outlined"
            autoComplete="off"
            fullWidth
            type="number"
            name="days_in_advance"
            label="Day(s) in Advance"
            InputProps={{
              endAdornment: <InputAdornment position="end">day(s)</InputAdornment>,
            }}
            classes={{ root: classes.textField }}
            value={daysInAdvance}
            onWheel={(event) => event.target.blur()}
            onChange={(e) => setDaysInAdvance(e.target.value)}
          />
          <TextField
            margin="dense"
            variant="outlined"
            autoComplete="off"
            fullWidth
            type="number"
            name="max_date_range"
            label="Limit Selection"
            InputProps={{
              endAdornment: <InputAdornment position="end">day(s)</InputAdornment>,
            }}
            classes={{ root: classes.textField }}
            value={maxDateRange}
            onWheel={(event) => event.target.blur()}
            onChange={(e) => setMaxDateRange(e.target.value)}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={
                  !!blockedDatesDelivery.find((d) => isEqual(startOfDay(d), startOfDay(date)))
                }
                onChange={(e) => {
                  if (e.target.checked) {
                    setBlockedDatesDelivery([...blockedDatesDelivery, date]);
                  } else {
                    setBlockedDatesDelivery(
                      blockedDatesDelivery.filter((d) => d.getTime() !== date.getTime()),
                    );
                  }
                }}
              />
            }
            label={<Typography variant="body2">Block out delivery</Typography>}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={!!blockedDatesSelfCollection.find((d) => d.getTime() === date.getTime())}
                onChange={(e) => {
                  if (e.target.checked) {
                    setBlockedDatesSelfCollection([...blockedDatesSelfCollection, date]);
                  } else {
                    setBlockedDatesSelfCollection(
                      blockedDatesSelfCollection.filter((d) => d.getTime() !== date.getTime()),
                    );
                  }
                }}
              />
            }
            label={<Typography variant="body2">Block out self collection</Typography>}
          />

          <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 16 }}>
            Block out recurring (Delivery):
          </Typography>
          <FormGroup row>
            {Object.entries(blockedWeekDaysDelivery).map(([key, value]) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    color="primary"
                    checked={value}
                    onChange={(e) => {
                      setBlockedWeekDaysDelivery({
                        ...blockedWeekDaysDelivery,
                        [key]: e.target.checked,
                      });
                    }}
                  />
                }
                label={<Typography variant="body2">{weekdays[key]}</Typography>}
              />
            ))}
          </FormGroup>

          <Typography variant="body1" style={{ fontWeight: "bold", marginTop: 16 }}>
            Block out recurring (Self-Collection):
          </Typography>
          <FormGroup row>
            {Object.entries(blockedWeekDaysSelfCollection).map(([key, value]) => (
              <FormControlLabel
                key={key}
                control={
                  <Checkbox
                    color="primary"
                    checked={value}
                    onChange={(e) => {
                      setBlockedWeekDaysSelfCollection({
                        ...blockedWeekDaysSelfCollection,
                        [key]: e.target.checked,
                      });
                    }}
                  />
                }
                label={<Typography variant="body2">{weekdays[key]}</Typography>}
              />
            ))}
          </FormGroup>

          <div className={classes.saveContainer}>
            <Button variant="contained" color="primary" onClick={handleSaveSchedule}>
              Save
            </Button>
          </div>
        </div>
      </div>
    </GenericContainer>
  );
};

export default Schedule;

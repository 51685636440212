import React from "react";
import { makeStyles, Typography, Grid, Switch, TextField } from "@material-ui/core";
import { ForumTwoTone } from "@material-ui/icons";

import GenericContainer from "../../../components/containers/GenericContainer";

import useShopContext from "../../../hooks/useShopContext";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0),
  },
  headerDescription: {
    marginBottom: "12px",
  },
  gridItem: {
    padding: theme.spacing(1.5, 0),
  },
  settingsIcon: {
    marginRight: theme.spacing(1.5),
  },
  leftMargin: {
    margin: theme.spacing(1),
    marginLeft: theme.spacing(4.5),
  },
  saveButton: {
    width: 150,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    marginTop: theme.spacing(1),
  },
}));

const EngagementSettings = () => {
  const classes = useStyles();
  const { shopfront, setShopfront } = useShopContext();

  return (
    <GenericContainer>
      <Typography variant="h5" className={classes.header}>
        Engagement Settings
      </Typography>
      <Typography variant="body1" color="textSecondary" className={classes.headerDescription}>
        Customize how you would like to engage with your customers
      </Typography>
      <Grid container>
        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ForumTwoTone className={classes.settingsIcon} />
            <Typography variant="h6" style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <b>Special Requests</b>
            </Typography>
            <Switch
              color="primary"
              checked={shopfront.shop_settings?.use_special_request || false}
              onChange={(e) => {
                const shop_settings = shopfront.shop_settings || {};
                shop_settings.use_special_request = e.target.checked;
                setShopfront({
                  ...shopfront,
                  shop_settings,
                });
              }}
            />
          </div>
          <div className={classes.leftMargin}>
            <Typography variant="body2" style={{ marginBottom: 8 }}>
              Allow customers to add special requests during checkout
            </Typography>
            {shopfront.shop_settings?.use_special_request && (
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                autoComplete="off"
                type="text"
                label="Custom Prompt (Optional)"
                multiline
                rows={3}
                value={shopfront.shop_settings?.special_request_description || ""}
                onChange={(e) => {
                  const shop_settings = shopfront.shop_settings || {};
                  shop_settings.special_request_description = e.target.value;
                  setShopfront({
                    ...shopfront,
                    shop_settings,
                  });
                }}
              />
            )}
          </div>
        </Grid>

        <Grid item xs={12} className={classes.gridItem}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <ForumTwoTone className={classes.settingsIcon} />
            <Typography variant="h6" style={{ flexGrow: 1, display: "flex", alignItems: "center" }}>
              <b>Checkout Message</b>
            </Typography>
            <Switch
              color="primary"
              checked={shopfront.shop_settings?.use_checkout_message || false}
              onChange={(e) => {
                const shop_settings = shopfront.shop_settings || {};
                shop_settings.use_checkout_message = e.target.checked;
                setShopfront({
                  ...shopfront,
                  shop_settings,
                });
              }}
            />
          </div>
          <div className={classes.leftMargin}>
            <Typography variant="body2" style={{ marginBottom: 8 }}>
              Personalize your checkout message
            </Typography>
            {shopfront.shop_settings?.use_checkout_message && (
              <TextField
                margin="dense"
                variant="outlined"
                fullWidth
                autoComplete="off"
                type="text"
                label="Message"
                multiline
                rows={3}
                value={shopfront.shop_settings?.checkout_message || ""}
                onChange={(e) => {
                  const shop_settings = shopfront.shop_settings || {};
                  shop_settings.checkout_message = e.target.value;
                  setShopfront({
                    ...shopfront,
                    shop_settings,
                  });
                }}
              />
            )}
          </div>
        </Grid>
      </Grid>
    </GenericContainer>
  );
};

export default EngagementSettings;

import { isAfter, startOfDay } from "date-fns";

import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const updateVendorFulfillmentInfo = (vendor) => {
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendor.id}/fulfillment-settings`,
    vendor,
  );
};

export const updateDaysForAdvancedorders = (vendor) => {
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendors/${vendor.id}`, {
    days_in_advance: vendor.days_in_advance,
  });
};

export const updateVendorDeliveryScheduleInfo = (
  vendorId,
  blockedDatesDelivery,
  blockedWeekDaysDelivery,
  blockedDatesSelfCollection,
  blockedWeekDaysSelfCollection,
  maxDateRange,
  daysInAdvance,
) => {
  const today = new Date();

  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/fulfillment-settings`,
    {
      blocked_dates_delivery: blockedDatesDelivery
        .filter((date) => isAfter(date, today))
        .map((date) => startOfDay(date).toISOString()),
      blocked_week_days_delivery: blockedWeekDaysDelivery,
      blocked_dates_self_collection: blockedDatesSelfCollection
        .filter((date) => isAfter(date, today))
        .map((date) => startOfDay(date).toISOString()),
      blocked_week_days_self_collection: blockedWeekDaysSelfCollection,
      max_date_range: maxDateRange,
      days_in_advance: daysInAdvance,
    },
  );
};

export const createCollectionOption = (vendorId, collectionOption) => {
  return Auth.client.post(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/collection-options`,
    collectionOption,
  );
};

export const updateCollectionOption = (vendorId, collectionOption) => {
  return Auth.client.put(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/collection-options/${collectionOption.collection_option_id}`,
    collectionOption,
  );
};

export const deleteCollectionOption = (vendorId, collectionOption) => {
  return Auth.client.delete(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/collection-options/${collectionOption.collection_option_id}`,
  );
};

export const createDeliveryOption = (vendorId, deliveryOption) => {
  return Auth.client.post(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/delivery-options`,
    deliveryOption,
  );
};

export const updateDeliveryOption = (vendorId, deliveryOption) => {
  return Auth.client.put(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/delivery-options/${deliveryOption.delivery_option_id}`,
    deliveryOption,
  );
};

export const deleteDeliveryOption = (vendorId, deliveryOption) => {
  return Auth.client.delete(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/delivery-options/${deliveryOption.delivery_option_id}`,
  );
};

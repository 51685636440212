import React, { useEffect, useState } from "react";
import { Chip, IconButton, Paper, Switch, TextField, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete, createFilterOptions } from "@material-ui/lab";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Check, DragHandle, MoreVert, Warning } from "@material-ui/icons";
import { useSnackbar } from "notistack";

import GenericContainer from "../../../components/containers/GenericContainer";
import CategoryDialog from "./CategoryDialog";
import DeleteCategoryDialog from "./DeleteCategoryDialog";

import useAuth from "../../../hooks/useAuth";
import {
  addProductCategory,
  deleteProductCategory,
  fetchProducts,
  getProductCategories,
  reorderProductCategories,
  updateProductCategory,
} from "../api";
import { hasFeature } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  subheader: {
    marginBottom: theme.spacing(1),
  },
  productContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.spacing(0.5),
  },
  paperRoot: {
    display: "flex",
    justifyContent: "space-between",
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(1),
    alignItems: "center",
    gap: theme.spacing(1),
  },
  productCategoryLabel: {
    // display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      width: "45%",
      whiteSpace: "nowrap",
      overflow: "hidden",
      textOverflow: "ellipsis",
    },
  },
  chip: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
}));

const ProductCategories = () => {
  const classes = useStyles();

  const { enqueueSnackbar } = useSnackbar();
  const { user, vendor, loading } = useAuth();

  const filter = createFilterOptions();

  const [products, setProducts] = useState([]);
  const [categories, setCategories] = useState([]);
  const [categoryDialog, setCategoryDialog] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState({
    product_category_name: "",
    product_category_id: "",
    products: [],
  });

  const [deleteDialog, setDeleteDialog] = useState(false);

  const getProductsAndCategories = () => {
    getProductCategories(vendor.id)
      .then((res) => {
        // console.log(res);
        setCategories(res.data);
      })
      .catch((err) => console.log(err));

    fetchProducts(vendor.id, { active: true, variants: false })
      .then((res) => {
        // console.log(res);
        setProducts(res.data);
      })
      .catch((err) => console.log(err));
  };

  const handleAddCategory = (newValue) => {
    addProductCategory(vendor.id, newValue)
      .then((res) => {
        setCategories(res.data);
        enqueueSnackbar("Category created successfully!", { variant: "success" });
      })
      .catch((err) => {
        // console.log(err);
        if (err.response.status === 409) {
          enqueueSnackbar("The category already existed!", { variant: "error" });
        } else {
          enqueueSnackbar(err?.response?.data || "Error!", { variant: "error" });
        }
      });
  };

  const handleUpdateCategory = (updatedCategoryObj) => {
    updateProductCategory(vendor.id, updatedCategoryObj)
      .then((res) => {
        setCategoryDialog(false);
        setCategories(res.data);
        enqueueSnackbar("Category updated successfully!", { variant: "success" });
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar(err?.response?.data || "Problem encountered when updating the category", {
          variant: "error",
        });
      });
  };

  const handleDeleteCategory = () => {
    deleteProductCategory(vendor.id, selectedCategory)
      .then((res) => {
        setDeleteDialog(false);
        setCategoryDialog(false);
        setCategories(res.data);
        enqueueSnackbar("Category deleted successfully!", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(err?.response?.data || "Problem encountered when updating the category", {
          variant: "error",
        });
      });
  };

  useEffect(() => {
    if (!loading) {
      getProductsAndCategories();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let reorderedCategories = reorder(categories, result.source.index, result.destination.index);
    setCategories(reorderedCategories);

    const categoryIds = reorderedCategories.map((category) => category.product_category_id);
    reorderProductCategories(categoryIds)
      .then((res) => {
        // console.log(res);
        enqueueSnackbar("Category re-ordered successfully!", { variant: "success" });
      })
      .catch((err) => console.log(err));
  };

  const getListStyle = (isDraggingOver) => ({
    padding: 8,
    background: isDraggingOver ? "#FFF2D2" : "#ebebeb",
  });

  return (
    <>
      <GenericContainer>
        <div style={{ display: "flex", justifyContent: "space-between", alignItems: "center" }}>
          <Typography variant="h5" className={classes.header}>
            Categories
            <Chip
              variant="outlined"
              size="small"
              label="Premium"
              classes={{ root: classes.chip }}
            />
          </Typography>
          <Switch
            checked={hasFeature("product_categories", user.features)}
            disabled={true}
            color="primary"
          />
        </div>
        {hasFeature("product_categories", user.features) && (
          <>
            <Typography
              variant="body2"
              color="textSecondary"
              className={classes.subheader}
              style={{ marginBottom: "16px" }}
            >
              Organize your products with categories
            </Typography>
            <Autocomplete
              freeSolo
              onChange={(event, newValueObj, reason) => {
                if (newValueObj) {
                  handleAddCategory(newValueObj.inputValue);
                }
              }}
              options={[]}
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                //   Suggest the creation of a new value
                if (params.inputValue !== "") {
                  filtered.push({
                    inputValue: params.inputValue,
                    title: `Add "${params.inputValue}"`,
                  });
                }
                return filtered;
              }}
              renderOption={(option) => option.title}
              getOptionLabel={(option) => {
                if (typeof option === "string") {
                  return option;
                }
                if (option.inputValue) {
                  return option.inputValue;
                }

                return option.title;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Enter New Category"
                  variant="outlined"
                  style={{ marginBottom: "24px" }}
                />
              )}
            />
            <Typography variant="body2" color="textSecondary" className={classes.subheader}>
              Drag to re-order your categories using the right icon. Click on the left icon to edit
              a category.
            </Typography>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    className={classes.productContainer}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {categories &&
                      categories.length > 0 &&
                      categories.map((category, index) => (
                        <Draggable
                          key={category.product_category_id}
                          draggableId={category.product_category_id}
                          index={index}
                        >
                          {(provided, snapshot) => (
                            <Paper
                              className={classes.paperRoot}
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                            >
                              <Typography className={classes.productCategoryLabel}>
                                {category.product_category_name}
                              </Typography>
                              <div style={{ display: "flex", gap: "8px", alignItems: "center" }}>
                                {category.products.length > 0 ? (
                                  <Chip
                                    label={
                                      category.products.length > 1
                                        ? `${category.products.length} Products`
                                        : `${category.products.length} Product`
                                    }
                                    size="small"
                                    icon={<Check />}
                                    style={{ padding: "4px" }}
                                  />
                                ) : (
                                  <Chip
                                    label="No Product"
                                    size="small"
                                    icon={<Warning />}
                                    color="primary"
                                    style={{ padding: "4px" }}
                                  />
                                )}
                                <IconButton
                                  size="small"
                                  onClick={() => {
                                    setSelectedCategory(category);
                                    setCategoryDialog(true);
                                  }}
                                >
                                  <MoreVert fontSize="small" />
                                </IconButton>
                                <div
                                  {...provided.dragHandleProps}
                                  style={{ display: "flex", alignItems: "center" }}
                                >
                                  <DragHandle />
                                </div>
                              </div>
                            </Paper>
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </>
        )}
      </GenericContainer>
      <CategoryDialog
        categoryDialog={categoryDialog}
        setCategoryDialog={setCategoryDialog}
        selectedCategory={selectedCategory}
        setSelectedCategory={setSelectedCategory}
        updateCategory={handleUpdateCategory}
        setDeleteDialog={setDeleteDialog}
        products={products}
      />
      <DeleteCategoryDialog
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
        deleteCategory={handleDeleteCategory}
      />
    </>
  );
};

export default ProductCategories;

import React from "react";
import { withStyles } from "@material-ui/core/styles";
import {
  Paper,
  TextField,
  Typography,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Chip,
} from "@material-ui/core";
import { DateTimePicker } from "@material-ui/pickers";

import useAuth from "../../../hooks/useAuth";
import { CURRENCY_PREFIX_MAP } from "../../../config/currency";
import { hasFeature } from "../../../utils";

const styles = (theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
  subheader: {
    marginBottom: theme.spacing(2),
  },
  paper: {
    borderRadius: "10px",
    height: "auto",
    padding: theme.spacing(3),
    display: "flex",
    flexDirection: "column",
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  selectTimeBased: {
    display: "flex",
    alignItems: "center",
  },
  chip: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  dateTimeSection: {
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(4),
    gap: theme.spacing(1),
  },
});

const ProductDetails = (props) => {
  const { classes, product, setProduct, formIsHalfFilled, setFormIsHalfFilled } = props;
  const { vendor, user } = useAuth();

  const currencyPrefix = CURRENCY_PREFIX_MAP[vendor.currency];

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setProduct({ ...product, [name]: value });

    if (!formIsHalfFilled) {
      setFormIsHalfFilled(true);
    }
  };

  return (
    <Paper elevation={3} className={classes.paper}>
      <Typography variant="h5" className={classes.header}>
        Details
      </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.subheader}>
        Tell your customers more about your product
      </Typography>

      <TextField
        variant="outlined"
        autoComplete="off"
        fullWidth
        name="title"
        label="Name"
        classes={{ root: classes.textField }}
        onChange={handleInputChange}
        value={product.title}
      />

      <TextField
        variant="outlined"
        autoComplete="off"
        fullWidth
        name="description"
        label="Description"
        classes={{ root: classes.textField }}
        onChange={handleInputChange}
        value={product.description}
        multiline
        minRows={4}
        maxRows={15}
      />

      {product.original_price === null && (
        <TextField
          variant="outlined"
          autoComplete="off"
          type="number"
          inputProps={{
            min: 0,
            step: 0.01,
          }}
          name="price"
          label="Price"
          fullWidth
          InputProps={{
            startAdornment: <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
          }}
          classes={{ root: classes.textField }}
          onWheel={(event) => event.target.blur()}
          onChange={handleInputChange}
          value={product.price}
        />
      )}

      <div>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              disabled={product.use_variants}
              checked={product.original_price !== null}
              onChange={(e) => {
                setProduct({
                  ...product,
                  original_price: e.target.checked ? product.price : null,
                  price: e.target.checked ? "" : product.original_price,
                });
              }}
            />
          }
          label="Set Product On Sale"
        />
      </div>

      {product.original_price !== null && (
        <div style={{ marginLeft: 32 }}>
          <Typography variant="body2" color="textSecondary">
            Original price should be higher than the final price
          </Typography>
          <TextField
            autoComplete="off"
            type="number"
            inputProps={{
              min: 0,
              step: 0.01,
            }}
            name="original_price"
            label="Original Price"
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
            }}
            classes={{ root: classes.textField }}
            onWheel={(event) => event.target.blur()}
            onChange={handleInputChange}
            value={product.original_price}
          />
          <TextField
            autoComplete="off"
            type="number"
            inputProps={{
              min: 0,
              step: 0.01,
            }}
            name="price"
            label="Final Price"
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
            }}
            classes={{ root: classes.textField }}
            onWheel={(event) => event.target.blur()}
            onChange={handleInputChange}
            value={product.price}
          />
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={product.show_percentage_discount}
                onChange={(e) => {
                  setProduct({
                    ...product,
                    show_percentage_discount: e.target.checked,
                  });
                }}
              />
            }
            label="Show Percentage Discount"
          />
        </div>
      )}

      <div>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              disabled={product.use_variants}
              checked={product.inventory !== null}
              onChange={(e) => {
                setProduct({
                  ...product,
                  inventory: e.target.checked ? 0 : null,
                });
              }}
            />
          }
          label="Set Inventory/Quota"
        />
      </div>

      {product.inventory !== null && (
        <div style={{ marginLeft: 32 }}>
          <Typography variant="body2" color="textSecondary">
            Leave at 0 to mark as sold out
          </Typography>
          <TextField
            autoComplete="off"
            type="number"
            inputProps={{
              min: 0,
            }}
            name="inventory"
            label="Inventory"
            fullWidth
            classes={{ root: classes.textField }}
            onWheel={(event) => event.target.blur()}
            value={product.inventory}
            onChange={handleInputChange}
          />
        </div>
      )}

      <div className={classes.selectTimeBased}>
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              disabled={!hasFeature("product_schedule_release", user.features)}
              checked={product.available_start_time !== null && product.available_end_time !== null}
              onChange={(e) => {
                setProduct({
                  ...product,
                  available_start_time: e.target.checked ? new Date() : null,
                  available_end_time: e.target.checked ? new Date() : null,
                });
              }}
            />
          }
          label="Schedule Release"
        />
        <Chip variant="outlined" size="small" label="Premium" classes={{ root: classes.chip }} />
      </div>

      {product.available_start_time !== null && product.available_end_time !== null && (
        <div className={classes.dateTimeSection}>
          <Typography variant="body2" color="textSecondary">
            Product will only be available for sale during the time frame below
          </Typography>
          <DateTimePicker
            autoOk
            disableToolbar
            ampm={false}
            error={false}
            helperText={null}
            variant="inline"
            label="Start Time"
            margin="dense"
            minDate={new Date()}
            format="dd-MM-yyyy HH:mm"
            value={product.available_start_time}
            onChange={(dateTime) => setProduct({ ...product, available_start_time: dateTime })}
          />
          <DateTimePicker
            autoOk
            disableToolbar
            ampm={false}
            error={false}
            helperText={null}
            variant="inline"
            label="End Time"
            margin="dense"
            minDate={new Date()}
            format="dd-MM-yyyy HH:mm"
            value={product.available_end_time}
            onChange={(dateTime) => setProduct({ ...product, available_end_time: dateTime })}
          />
        </div>
      )}
    </Paper>
  );
};

export default withStyles(styles)(ProductDetails);

import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Typography, Snackbar } from "@material-ui/core/";
import { Add, PeopleAlt, AddShoppingCart, TrendingUp, ErrorOutline } from "@material-ui/icons";
import Alert from "@material-ui/lab/Alert";

import PageTitle from "../../components/PageTitle";
import ChartBlock from "./components/ChartBlock";
import InfoBlock from "../Dashboard/components/InfoBlock";
import DoughnutChartBlock from "./components/DoughnutChartBlock";
import OrderInfoBlock from "./components/OrderInfoBlock";

import Auth from "../../Auth";
import { BACKEND_URL, API_VERSION } from "../../config";
import useAuth from "../../hooks/useAuth";

// sample data for charts
const doughnutChartData = [
  {
    name: "Product 1",
    value: 20,
  },
  {
    name: "Product 2",
    value: 45,
  },
  {
    name: "Product 3",
    value: 10,
  },
];

// const lineChartData = [
//   { x: "Jan", Orders: "10" },
//   { x: "Feb", Orders: "20" },
//   { x: "Mar", Orders: "15" },
//   { x: "Apr", Orders: "30" },
//   { x: "May", Orders: "40" },
//   { x: "Jun", Orders: "45" },
// ];

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    minHeight: "calc(100vh - 190px)", // padding: 30px * 2, title: 80px + 50px, total 190px
  },
  leftCol: {
    flexGrow: 2,
    paddingRight: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
  },
  rightCol: {
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
    maxWidth: "350px",
  },
  button: {
    minWidth: "300px",
    marginBottom: theme.spacing(1),
    padding: theme.spacing(1),
  },
  disclaimer: {
    margin: "15px 0 10px",
    display: "flex",
    alignItems: "center",
  },
  icon: {
    marginRight: "5px",
  },
}));

const Dashboard = () => {
  const { vendor, loading } = useAuth();
  const classes = useStyles();

  const [infoBlocks, setInfoBlocks] = useState();
  const [noticeOpen, setNoticeOpen] = useState(false);

  useEffect(() => {
    if (!loading) {
      if (vendor && !vendor.paynow_mobile && !vendor.uen) {
        setNoticeOpen(true);
      }

      Auth.client
        .get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendor.id}/metrics/growth`)
        .then((res) => {
          // console.log(res);
          setInfoBlocks(
            res.data.map((block, i) => ({
              ...block,
              icon:
                i === 0
                  ? () => <AddShoppingCart fontSize="large" />
                  : i === 1
                  ? () => <PeopleAlt fontSize="large" />
                  : () => <TrendingUp fontSize="large" />,
            })),
          );
        })
        .catch((err) => {
          console.log(err);
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <React.Fragment>
      <Snackbar
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
        open={noticeOpen}
      >
        <Alert
          action={
            <Button component={NavLink} to="/home/settings?tab=1" style={{ color: "inherit" }}>
              Settings
            </Button>
          }
          severity="error"
        >
          Hi there! Set up your PayNow to start receiving payments
        </Alert>
      </Snackbar>
      <PageTitle title="Dashboard" />
      <div className={classes.root}>
        <div className={classes.leftCol}>
          <ChartBlock />
          <OrderInfoBlock />
        </div>
        <div className={classes.rightCol}>
          <Button
            component={NavLink}
            to="/home/products/new"
            variant="contained"
            color="secondary"
            className={classes.button}
            startIcon={<Add />}
          >
            <Typography variant="h5">New Product</Typography>
          </Button>
          <DoughnutChartBlock data={doughnutChartData} />
          {infoBlocks && infoBlocks.map((block, i) => <InfoBlock key={i} {...block} />)}
        </div>
      </div>
      <Typography className={classes.disclaimer} variant="body1">
        <ErrorOutline className={classes.icon} color="error" />
        We try our best to provide accurate insights, do let us know if you spot anything unusual
      </Typography>
    </React.Fragment>
  );
};

export default Dashboard;

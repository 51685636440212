import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  InputAdornment,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

import { createDeliveryOption } from "../api";

const useStyles = makeStyles((theme) => ({
  dialogRoot: {
    width: "400px",
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
}));

const DeliveryOptionDialog = ({ vendor, setVendor, open, setOpen }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [deliveryOption, setDeliveryOption] = useState({
    name: "",
    delivery_fee: 0,
    enable_delivery_slots: false,
    free_delivery_min_spend: null,
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDeliveryOption({ ...deliveryOption, [name]: value });
    // console.log(vendor);
  };

  const handleCreateDeliveryOption = () => {
    if (deliveryOption.name === "") {
      enqueueSnackbar("Delivery option name cannot be empty", { variant: "error" });
      return;
    }

    createDeliveryOption(vendor.id, deliveryOption)
      .then((res) => {
        // console.log(res.data);
        setVendor(res.data);
      })
      .catch((err) => console.log(err))
      .finally(() => setOpen(false));
  };

  return (
    <Dialog
      classes={{ paper: classes.dialogRoot }}
      maxWidth="md"
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{
        onExit: () => {
          setDeliveryOption({
            name: "",
            delivery_fee: 0,
            enable_delivery_slots: false,
            free_delivery_min_spend: null,
          });
        },
      }}
    >
      <DialogTitle>New Delivery Option</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          name="name"
          label="Delivery Option Name"
          classes={{ root: classes.textField }}
          value={deliveryOption.name}
          onChange={(e) => handleInputChange(e)}
        />
        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          type="number"
          name="delivery_fee"
          label="Delivery Fee"
          InputProps={{
            startAdornment: <InputAdornment position="start">$</InputAdornment>,
          }}
          classes={{ root: classes.textField }}
          value={deliveryOption.delivery_fee}
          onChange={(e) => handleInputChange(e)}
          inputProps={{
            min: 0,
          }}
          onWheel={(e) => e.target.blur()}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={deliveryOption.free_delivery_min_spend !== null}
              onChange={(e) => {
                setDeliveryOption({
                  ...deliveryOption,
                  free_delivery_min_spend: e.target.checked ? 0 : null,
                });
              }}
            />
          }
          label="Free delivery with minimum spend"
        />
        {deliveryOption.free_delivery_min_spend !== null && (
          <div>
            <TextField
              autoComplete="off"
              type="number"
              name="free_delivery_min_spend"
              label="Minimum Spending"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
              }}
              classes={{ root: classes.textField }}
              value={deliveryOption.free_delivery_min_spend}
              onChange={(e) => handleInputChange(e)}
              inputProps={{
                min: 0,
              }}
              onWheel={(e) => e.target.blur()}
            />
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={handleCreateDeliveryOption}>
          Add Delivery Option
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryOptionDialog;

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button } from "@material-ui/core";
import { Save, Visibility } from "@material-ui/icons";
import { Helmet } from "react-helmet";
import { Redirect, Switch } from "react-router-dom";
import validator from "validator";
import { useSnackbar } from "notistack";

import PrivateRoute from "../../components/PrivateRoute";
import PageTitle from "../../components/PageTitle";
import ShopGeneral from "./components/ShopGeneral";
import ShopGeoBlocking from "./components/ShopGeoBlocking";
import PaymentSettingsAlert from "../../components/alerts/PaymentSettingsAlert";
import ShopLanding from "./components/ShopLanding";
import ShopTheme from "./components/ShopTheme";
import ShopElements from "./components/ShopElements";

import useShopContext from "../../hooks/useShopContext";
import { validateSubdomain } from "./helpers/utils";
import LoadingSplashScreen from "../../components/LoadingSplashScreen";
import SocialLinkSettings from "./components/SocialLinkSettings";
import FaqSettings from "./components/FaqSettings";
import EngagementSettings from "./components/EngagementSettings";
import CustomDomain from "./components/CustomDomain";
import { getShopDomain } from "../../utils";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 190px)", // padding: 30px * 2, title: 80px + 50px, total 190px
    marginBottom: 100,
    margin: 30,
  },
  paper: {
    flex: 1,
    borderRadius: "10px",
    padding: theme.spacing(3),
    maxWidth: 700,
    margin: theme.spacing(1, 0),
  },
  footer: {
    position: "fixed",
    bottom: 0,
    right: 0,
    height: 50,
    background: theme.palette.common.white,
    width: "100%",
    boxShadow: "0px 3px 15px 0px rgba(0,0,0,0.3)",
    WebkitBoxShadow: "0px 3px 15px 0px rgba(0,0,0,0.3)",
    zIndex: 10,
    display: "flex",
    justifyContent: "flex-end",
    padding: theme.spacing(1, 2),
  },
  btn: {
    textTransform: "none",
    margin: theme.spacing(0, 1),
  },
  primaryButton: {
    textTransform: "none",
    width: 120,
  },
}));

const ShopSettings = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { shopfront, updateShopfront, loading } = useShopContext();
  const [isSaving, setIsSaving] = useState(false);

  const handleUpdate = () => {
    try {
      if (!validateSubdomain(shopfront.subdomain)) {
        enqueueSnackbar("Your shop URL should contain only lowercase letters (a-z)", {
          variant: "error",
        });
        return;
      }

      if (
        shopfront?.shop_settings?.tnc &&
        !validator.isURL(shopfront?.shop_settings?.tnc || "", {
          protocols: ["http", "https"],
          require_protocol: true,
          allow_underscores: true,
        })
      ) {
        enqueueSnackbar("Please input a valid T&C URL", { variant: "error" });
        return;
      }

      if (shopfront.meta_tag_is_active) {
        if (shopfront.meta_tag_content === "" || shopfront.meta_pixel_id === "") {
          enqueueSnackbar("Meta content and pixel ID must be filled!", { variant: "error" });
          return;
        }
      }

      if (shopfront.geo_blocked && shopfront.restricted_countries?.length === 0) {
        enqueueSnackbar("Choose at least 1 country to be denied access", { variant: "error" });
        return;
      }

      // if (shopfront.use_background_image && shopfront.background_image === null) {
      //   enqueueSnackbar("Please upload a background image", { variant: "error" });
      //   return;
      // }

      setIsSaving(true);
      updateShopfront()
        .then(() => {
          enqueueSnackbar("Updated!", { variant: "success" });
        })
        .catch((err) => {
          if (err.response.status === 409) {
            enqueueSnackbar("Shop URL has been taken. Please choose a different one", {
              variant: "error",
            });
          } else {
            enqueueSnackbar(err.response.data || "Error!", { variant: "error" });
          }
          console.log(err);
        })
        .finally(() => {
          setIsSaving(false);
        });
    } catch (error) {
      enqueueSnackbar(`Something went wrong: ${error}`, { variant: "error" });
      setIsSaving(false);
    }
  };

  return (
    <>
      <Helmet>
        <title>Shop Settings | Carte</title>
      </Helmet>
      <div className={classes.root}>
        {loading || isSaving ? <LoadingSplashScreen /> : null}
        <PageTitle title="Your Shop" />
        <PaymentSettingsAlert />
        <Switch>
          <PrivateRoute
            exact
            path="/home/shop/general"
            render={() => (
              <>
                <ShopGeneral />
                <ShopGeoBlocking />
              </>
            )}
          />
          <PrivateRoute
            exact
            path="/home/shop/landing"
            render={() => (
              <>
                <ShopLanding />
                <ShopElements />
              </>
            )}
          />
          <PrivateRoute
            exact
            path="/home/shop/theme-layout"
            render={() => (
              <>
                <ShopTheme />
              </>
            )}
          />
          <PrivateRoute
            exact
            path="/home/shop/engagements"
            render={() => (
              <>
                <EngagementSettings />
              </>
            )}
          />
          <PrivateRoute
            exact
            path="/home/shop/social"
            render={() => (
              <>
                <SocialLinkSettings />
              </>
            )}
          />
          <PrivateRoute
            exact
            path="/home/shop/faqs"
            render={() => (
              <>
                <FaqSettings />
              </>
            )}
          />
          <PrivateRoute
            exact
            path="/home/shop/custom-domain"
            render={() => (
              <>
                <CustomDomain />
              </>
            )}
          />
          <Redirect from="/home/shop" to="/home/shop/general" />
        </Switch>
      </div>
      <div className={classes.footer}>
        <Button
          href={`${getShopDomain(shopfront)}`}
          target="_blank"
          variant="outlined"
          className={classes.btn}
          startIcon={<Visibility />}
        >
          View Shop
        </Button>
        <Button
          className={classes.primaryButton}
          variant="contained"
          color="primary"
          startIcon={<Save />}
          onClick={handleUpdate}
          disabled={isSaving}
        >
          {isSaving ? "Saving..." : "Save"}
        </Button>
      </div>
    </>
  );
};

export default ShopSettings;

import React, { Fragment, useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { Autocomplete } from "@material-ui/lab";
import { CheckBox, CheckBoxOutlineBlank } from "@material-ui/icons";
import { DateTimePicker } from "@material-ui/pickers";
import { useSnackbar } from "notistack";

import { getAllProducts, scheduleProductsRelease } from "../api";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  dialogTitle: {
    display: "flex",
    alignItems: "center",
    gap: theme.spacing(1),
  },
  loaderContainer: {
    display: "flex",
    justifyContent: "center",
    padding: theme.spacing(5, 0),
  },
  autocomplete: {
    marginBottom: theme.spacing(2),
  },
  chip: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  dateTimeSection: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
}));

const ScheduleReleaseDialog = ({ vendorId, open, onClose }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [products, setProducts] = useState([]);
  const [selectedProducts, setSelectedProducts] = useState([]);
  const [loading, setLoading] = useState(true);

  const [timeBasedValues, setTimeBasedValues] = useState({
    available_start_time: new Date(),
    available_end_time: new Date(),
  });

  const [saving, setSaving] = useState(false);

  const handleSave = () => {
    setSaving(true);

    const startTime = new Date(timeBasedValues.available_start_time);
    const endTime = new Date(timeBasedValues.available_end_time);

    if (endTime <= startTime) {
      enqueueSnackbar("End time has to be later than start time", { variant: "error" });
      setSaving(false);
      return;
    }

    let productIds = [];
    for (const selectedProduct of selectedProducts) {
      productIds.push(selectedProduct.product_id);
      if (selectedProduct.use_variants) {
        const variantIds = selectedProduct.variants.map((variant) => variant.product_id);
        productIds = productIds.concat(variantIds);
      }
    }
    const obj = { ...timeBasedValues, product_ids: productIds };

    scheduleProductsRelease(vendorId, obj)
      .then((res) => {
        enqueueSnackbar("Products updated!", { variant: "success" });
        onClose();
      })
      .catch((err) => {
        enqueueSnackbar(err.response.data, { variant: "error" });
      })
      .finally(() => setSaving(false));
  };

  useEffect(() => {
    getAllProducts(vendorId).then((res) => {
      setProducts(res.data);
      setTimeout(() => setLoading(false), 500);
    });
    // eslint-disable-next-line
  }, []);

  return (
    <Dialog
      open={open}
      onClose={(event, reason) => reason !== "backdropClick" && onClose()}
      classes={{ paper: classes.dialog }}
      TransitionProps={{
        onExited: () => {
          setSelectedProducts([]);
          setTimeBasedValues({ available_start_time: new Date(), available_end_time: new Date() });
          setSaving(false);
        },
      }}
    >
      <DialogTitle>
        <div className={classes.dialogTitle}>
          Schedule Release For Products
          <Chip variant="outlined" size="small" label="Premium" classes={{ root: classes.chip }} />
        </div>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <div className={classes.loaderContainer}>
            <CircularProgress />
          </div>
        ) : (
          <>
            <Autocomplete
              multiple
              disableCloseOnSelect
              size="small"
              value={selectedProducts}
              onChange={(event, selectedProductsArr) => {
                setSelectedProducts(selectedProductsArr);
              }}
              options={products}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) =>
                option.title === value.title && option.product_id === value.product_id
              }
              renderOption={(option, { selected }) => {
                return (
                  <Fragment>
                    <Checkbox
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={selectedProducts.some(
                        (product) =>
                          product["title"] === option.title &&
                          product["product_id"] === option.product_id,
                      )}
                    />
                    {option.title}
                  </Fragment>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Products"
                  placeholder="Select or enter product name"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="secondary"
                    label={option.title}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
              className={classes.autocomplete}
            />
            <div className={classes.dateTimeSection}>
              <Typography variant="body2" color="textSecondary">
                Products will only be available for sale during the time frame below
              </Typography>
              <DateTimePicker
                autoOk
                disableToolbar
                ampm={false}
                variant="inline"
                label="Start Time"
                margin="dense"
                minDate={new Date()}
                format="dd-MM-yyyy HH:mm"
                value={timeBasedValues.available_start_time}
                onChange={(dateTime) =>
                  setTimeBasedValues({ ...timeBasedValues, available_start_time: dateTime })
                }
              />
              <DateTimePicker
                autoOk
                disableToolbar
                ampm={false}
                variant="inline"
                label="End Time"
                margin="dense"
                minDate={new Date()}
                format="dd-MM-yyyy HH:mm"
                value={timeBasedValues.available_end_time}
                onChange={(dateTime) =>
                  setTimeBasedValues({ ...timeBasedValues, available_end_time: dateTime })
                }
              />
            </div>
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={saving} onClick={() => onClose()}>
          Cancel
        </Button>
        {saving ? (
          <Button
            color="primary"
            variant="contained"
            startIcon={<CircularProgress size="1.5rem" color="inherit" />}
          >
            Saving
          </Button>
        ) : (
          <Button
            disabled={selectedProducts.length < 1}
            color="primary"
            variant="contained"
            onClick={() => handleSave()}
          >
            Save
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ScheduleReleaseDialog;

import React, { useState } from "react";
import {
  Button,
  CircularProgress,
  SwipeableDrawer,
  makeStyles,
  FormControl,
  Select,
  MenuItem,
  Typography,
  Chip,
} from "@material-ui/core";
import { KeyboardDatePicker } from "@material-ui/pickers";

import useOrdersContext from "../../../hooks/useOrdersContext";

import Auth from "../../../Auth";
import { exportCSVFile, parseDate, toISODateString } from "../../../utils";
import { parseCartItems } from "../helpers/csvUtils";
import { BACKEND_URL, API_VERSION } from "../../../config";

const useStyles = makeStyles((theme) => ({
  drawerRoot: {
    width: 350,
    padding: theme.spacing(2),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      width: "80vw",
    },
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: 16,
  },
  chip: {
    margin: 2,
  },
}));

const statusMap = {
  UNCOMPLETED: "Uncompleted",
  PENDING: "Pending",
  PENDING_VERIFICATION: "Pending Verification",
  PAID: "Paid",
  PENDING_DELIVERY: "Delivery in progress",
  COMPLETED: "Completed",
  ABANDONED: "Abandoned",
  FAILED: "Failed",
};

const FilterDrawer = ({ vendorId, open, setOpen }) => {
  const classes = useStyles();
  const { status, setStatus, pageSize, setPageSize, startDate, setStartDate, endDate, setEndDate } =
    useOrdersContext();

  const [exportLoading, setExportLoading] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState(status || []);
  const [selectedPageSize, setSelectedPageSize] = useState(pageSize || 10);
  const [selectedStartDate, setSelectedStartDate] = useState(startDate);
  const [selectedEndDate, setSelectedEndDate] = useState(endDate);

  const iOS = typeof navigator !== "undefined" && /iPad|iPhone|iPod/.test(navigator.userAgent);

  const exportOrders = () => {
    setExportLoading(true);
    const headers = {
      ID: "ID",
      Date: "Date",
      Customer: "Customer",
      Email: "Email",
      Mobile: "Mobile",
      Amount: "Amount",
      "Payment Method": "Payment Method",
      Status: "Status",
      Product: "Product",
      "Promo Code": "Promo Code",
      "Delivery Address": "Delivery Address",
      "Delivery Date": "Delivery Date",
      "Self Collection Address": "Self Collection Address",
      "Self Collection Date": "Self Collection Date",
    };

    Auth.client
      .get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/orders`, {
        params: {
          dump: 1,
          txn_status: selectedStatus && selectedStatus.length > 0 ? selectedStatus.join(",") : null,
          start_date: selectedStartDate ? toISODateString(selectedStartDate) : null,
          end_date: selectedEndDate ? toISODateString(selectedEndDate) : null,
        },
      })
      .then((res) => {
        const forCSV = res.data.map((transaction) => ({
          ID: transaction.public_transaction_id,
          Date: parseDate(transaction.date_edited),
          Customer: transaction.customer.customer_name,
          Email: transaction.customer.customer_email,
          Mobile: transaction.customer.contact_number,
          Amount: transaction.total_price,
          "Payment Method": transaction.payment_method === "ST" ? "Credit Card" : "PayNow",
          Status: transaction.status,
          Product: parseCartItems(transaction.cart_items),
          "Promo Code": transaction.promo_code ? transaction.promo_code : "",
          "Delivery Address":
            transaction.delivery_address === ""
              ? "-"
              : transaction.delivery_address.replace(/,/g, " "),
          "Delivery Date": transaction.delivery_date ? parseDate(transaction.delivery_date) : "-",
          "Self Collection Address": transaction.collection_option
            ? transaction.collection_option.address
            : "-",
          "Self Collection Date": transaction.collection_date
            ? parseDate(transaction.collection_date)
            : "-",
        }));

        exportCSVFile(headers, forCSV, "orders");
      })
      .catch((err) => console.log(err))
      .finally(() => setExportLoading(false));
  };

  const deleteStatus = (value) => {
    setSelectedStatus(selectedStatus.filter((s) => s !== value));
  };

  const applySettings = () => {
    setStatus(selectedStatus);
    setPageSize(selectedPageSize);
    setStartDate(selectedStartDate);
    setEndDate(selectedEndDate);
    setOpen(false);
  };

  return (
    <SwipeableDrawer
      classes={{ paper: classes.drawerRoot }}
      anchor="right"
      open={open}
      onClose={() => setOpen(false)}
      onOpen={() => setOpen(true)}
      disableBackdropTransition={!iOS}
      disableDiscovery={iOS}
    >
      <Typography variant="h5" style={{ fontWeight: 500 }} color="textSecondary" gutterBottom>
        Filters
      </Typography>

      <Typography variant="body2" color="textSecondary">
        Date Range
      </Typography>
      <KeyboardDatePicker
        autoOk
        disableToolbar
        disableFuture
        variant="inline"
        label="From"
        format="dd-MM-yyyy"
        inputVariant="outlined"
        margin="dense"
        error={false}
        onChange={(date) => setSelectedStartDate(date)}
        value={selectedStartDate}
      />
      <KeyboardDatePicker
        autoOk
        disableToolbar
        disableFuture
        variant="inline"
        label="To"
        format="dd-MM-yyyy"
        inputVariant="outlined"
        margin="dense"
        error={false}
        onChange={(date) => setSelectedEndDate(date)}
        value={selectedEndDate}
      />

      <Typography variant="body2" color="textSecondary">
        Status
      </Typography>
      <FormControl variant="outlined" margin="dense" style={{ margin: 0 }}>
        <Select multiple value={selectedStatus} onChange={(e) => setSelectedStatus(e.target.value)}>
          <MenuItem value="UNCOMPLETED">Uncompleted</MenuItem>
          <MenuItem value="PENDING">Pending Payment</MenuItem>
          <MenuItem value="PENDING_VERIFICATION">Pending Verification</MenuItem>
          <MenuItem value="PAID">Paid</MenuItem>
          <MenuItem value="PENDING_DELIVERY">Delivery in progress</MenuItem>
          <MenuItem value="COMPLETED">Completed</MenuItem>
          <MenuItem value="ABANDONED">Abandoned</MenuItem>
          <MenuItem value="FAILED">Failed</MenuItem>
        </Select>
      </FormControl>
      <div className={classes.chipContainer}>
        {selectedStatus.map((s) => (
          <Chip
            className={classes.chip}
            color="primary"
            variant="outlined"
            label={statusMap[s]}
            size="small"
            onDelete={() => deleteStatus(s)}
          />
        ))}
      </div>

      <Typography
        variant="h5"
        style={{ fontWeight: 500, marginTop: 16 }}
        color="textSecondary"
        gutterBottom
      >
        Settings
      </Typography>
      <Typography variant="body2" color="textSecondary">
        Rows per Page
      </Typography>
      <FormControl variant="outlined" margin="dense" style={{ margin: 0, marginBottom: 16 }}>
        <Select value={selectedPageSize} onChange={(e) => setSelectedPageSize(e.target.value)}>
          {[10, 20, 30, 40, 50].map((ps) => (
            <MenuItem key={ps} value={ps}>
              {ps}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      <Button
        style={{ marginTop: "auto" }}
        variant="outlined"
        disabled={exportLoading}
        onClick={() => exportOrders()}
        disableRipple
        disableFocusRipple
      >
        {exportLoading ? <CircularProgress size={24} /> : "Export w/ filters (.CSV)"}
      </Button>
      <Button
        variant="contained"
        color="primary"
        disabled={exportLoading}
        onClick={applySettings}
        disableRipple
        disableFocusRipple
      >
        Apply
      </Button>
    </SwipeableDrawer>
  );
};

export default FilterDrawer;

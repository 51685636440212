import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Paper,
  Typography,
  TextField,
  Switch,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  InputAdornment,
  DialogActions,
  Card,
  CardMedia,
  CardContent,
  FormControlLabel,
  Checkbox,
  Menu,
  MenuItem,
  Chip,
} from "@material-ui/core";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { Delete, DragHandle, MoreVert } from "@material-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";
import { getPercentageDiscount } from "../helpers/utils";
import useAuth from "../../../hooks/useAuth";
import { CURRENCY_PREFIX_MAP } from "../../../config/currency";
import { hasFeature } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    borderRadius: "10px",
    padding: theme.spacing(3),
    // maxWidth: 700,
    margin: theme.spacing(1, 0),
    display: "flex",
    flexDirection: "column",
  },
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  subheader: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  colorPickerContainer: {
    display: "flex",
  },
  colorPicker: {
    height: 100,
  },
  dropzoneContainer: {
    margin: theme.spacing(1, 0),
    width: "50%",
    maxWidth: 250,
    minHeight: 70,
    height: 100,
    // padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      height: 90,
    },
  },
  dropzoneParagraph: {
    fontSize: 14,
  },
  imageItem: {
    userSelect: "none",
    margin: theme.spacing(1),
    height: "120px",
    position: "relative",
    width: "fit-content",
  },
  image: {
    objectFit: "contain",
    height: "120px",
  },
  deleteBtn: {
    position: "absolute",
    top: -12,
    right: -12,
  },
  variantCardRoot: {
    display: "flex",
    margin: theme.spacing(1, 0),
  },
  variantCardMedia: {
    width: 150,
    height: 150,
    objectFit: "cover",
    margin: "auto 0",
    [theme.breakpoints.down("sm")]: {
      height: 100,
      width: 100,
      margin: "auto",
      // paddingTop: "46%",
    },
    // width: "30%",
  },
  variantImage: {
    objectFit: "contain",
  },
  pricingSection: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "flex-start",
      gap: "0px",
      marginBottom: "5px",
    },
  },
  discountLabel: {
    height: "16px",
    fontSize: "0.75rem",
    "& .MuiChip-label": {
      padding: "0px 8px",
    },
  },
  variantCardActions: {
    display: "flex",
    flexDirection: "column",
    padding: theme.spacing(1),
    alignItems: "center",
  },
  chip: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    width: "80px",
  },
  variantContainer: {
    padding: theme.spacing(1),
    borderRadius: theme.spacing(0.5),
  },
}));

const VariantDialog = ({
  classes,
  vendor,
  open,
  onClose,
  product,
  setProduct,
  enqueueSnackbar,
  editVariantIndex,
  hasImageCaption,
}) => {
  const currencyPrefix = CURRENCY_PREFIX_MAP[vendor.currency];

  const [variant, setVariant] = useState({
    title: "",
    description: "",
    original_price: null,
    show_percentage_discount: false,
    price: product?.price,
    product_image: null,
    product_image_caption: "",
    inventory: null,
  });

  useEffect(() => {
    if (editVariantIndex !== undefined) {
      setVariant(product.variants[editVariantIndex]);
    }
  }, [product, editVariantIndex]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setVariant({ ...variant, [name]: value });
  };

  const handleCheckInventory = (e) => {
    setVariant({
      ...variant,
      inventory: e.target.checked ? 0 : null,
    });
  };

  const handleAddVariant = () => {
    if (!variant.title || !variant.description) {
      enqueueSnackbar("Missing variant name or description", { variant: "error" });
      return;
    }

    if (!variant.price || variant.price < 0) {
      enqueueSnackbar("Invalid price", { variant: "error" });
      return;
    }

    if (variant.original_price !== null && variant.original_price === "") {
      enqueueSnackbar("Original price should not be empty", { variant: "error" });
      return;
    }

    if (
      variant.original_price !== null &&
      parseFloat(variant.original_price) <= parseFloat(variant.price)
    ) {
      enqueueSnackbar("Original price should be higher", { variant: "error" });
      return;
    }

    if (!variant.product_image) {
      enqueueSnackbar("Upload an image", { variant: "error" });
      return;
    }

    // editing local state
    if (editVariantIndex !== undefined) {
      let newVariants = product.variants.map((v, i) => {
        if (i === editVariantIndex) {
          return variant;
        }
        return v;
      });

      setProduct({
        ...product,
        variants: newVariants,
      });
      onClose();
      return;
    }

    // simple appending to variant array
    let newVariants = [...product.variants];
    newVariants.push(variant);
    setProduct({
      ...product,
      variants: newVariants,
    });

    onClose();
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      onExited={() =>
        setVariant({
          title: "",
          description: "",
          original_price: null,
          show_percentage_discount: false,
          price: product?.price,
          product_image: null,
          inventory: null,
        })
      }
    >
      <DialogTitle>Add Variant</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          name="title"
          label="Variant Name"
          classes={{ root: classes.textField }}
          onChange={handleInputChange}
          value={variant.title}
        />

        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          name="description"
          label="Description"
          classes={{ root: classes.textField }}
          onChange={handleInputChange}
          value={variant.description}
          multiline
          rows={2}
        />

        {variant.original_price === null && (
          <TextField
            variant="outlined"
            autoComplete="off"
            type="number"
            name="price"
            label="Price"
            fullWidth
            InputProps={{
              startAdornment: <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
            }}
            inputProps={{ min: 0, step: 0.01 }}
            classes={{ root: classes.textField }}
            onChange={handleInputChange}
            value={variant.price}
            onWheel={(e) => e.target.blur()}
          />
        )}

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={variant.original_price !== null}
              onChange={(e) =>
                setVariant({
                  ...variant,
                  original_price: e.target.checked ? variant.price : null,
                  price: e.target.checked ? "" : variant.original_price,
                })
              }
            />
          }
          label="Set Variant On Sale"
        />
        {variant.original_price !== null && (
          <div style={{ marginLeft: 32 }}>
            <Typography variant="body2" color="textSecondary">
              Original price should be higher than the final price
            </Typography>
            <TextField
              autoComplete="off"
              type="number"
              inputProps={{
                min: 0,
                step: 0.01,
              }}
              name="original_price"
              label="Original Price"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
              }}
              classes={{ root: classes.textField }}
              onWheel={(event) => event.target.blur()}
              onChange={handleInputChange}
              value={variant.original_price}
            />
            <TextField
              autoComplete="off"
              type="number"
              name="price"
              label="Final Price"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
              }}
              inputProps={{ min: 0, step: 0.01 }}
              classes={{ root: classes.textField }}
              onChange={handleInputChange}
              value={variant.price}
              onWheel={(e) => e.target.blur()}
            />

            <FormControlLabel
              control={
                <Checkbox
                  color="primary"
                  checked={variant.show_percentage_discount}
                  onChange={(e) => {
                    setVariant({
                      ...variant,
                      show_percentage_discount: e.target.checked,
                    });
                  }}
                />
              }
              label="Show Percentage Discount"
            />
          </div>
        )}

        <br />

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={variant.inventory !== null}
              onChange={handleCheckInventory}
            />
          }
          label="Set Inventory/Quota"
        />
        {variant.inventory !== null && (
          <div style={{ marginLeft: 32 }}>
            <Typography variant="body2" color="textSecondary">
              Stop taking orders when inventory/quota is 0
            </Typography>
            <TextField
              autoComplete="off"
              type="number"
              inputProps={{
                min: 1,
              }}
              name="inventory"
              label="Inventory"
              fullWidth
              classes={{ root: classes.textField }}
              onWheel={(event) => event.target.blur()}
              value={variant.inventory}
              onChange={handleInputChange}
            />
          </div>
        )}

        {variant.product_image ? (
          <Fragment>
            <div className={classes.imageItem}>
              <img
                className={classes.image}
                src={
                  variant.product_image.data ? variant.product_image.data : variant.product_image
                }
                alt="product_image"
              />
              <IconButton
                disableRipple
                size="small"
                className={classes.deleteBtn}
                onClick={() => setVariant({ ...variant, product_image: null })}
              >
                <Delete />
              </IconButton>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: 8, marginTop: 16 }}>
              <Typography variant="body1">Image Caption</Typography>
              <Chip
                variant="outlined"
                size="small"
                label="Premium"
                classes={{ root: classes.chip }}
              />
            </div>
            <TextField
              variant="outlined"
              margin="dense"
              multiline
              rows={2}
              fullWidth
              placeholder="Max. 70 characters"
              value={variant.product_image_caption ? variant.product_image_caption : ""}
              onChange={(e) => setVariant({ ...variant, product_image_caption: e.target.value })}
              helperText={`Caption provides additional information to what the image is conveying.`}
              disabled={!hasImageCaption}
              inputProps={{ maxLength: 70 }}
            />
          </Fragment>
        ) : (
          <Fragment>
            <Typography
              variant="body2"
              color="textSecondary"
              style={{ marginTop: "16px", marginBottom: "8px" }}
            >
              Recommended aspect ratio: <span style={{ fontStyle: "italic" }}>1:1</span>
            </Typography>
            <DropzoneAreaBase
              dropzoneText={
                <div>
                  Drop image here or{" "}
                  <Button variant="contained" color="primary">
                    Upload Image
                  </Button>
                </div>
              }
              dropzoneParagraphClass={classes.dropzoneParagraph}
              dropzoneClass={classes.dropzoneContainer}
              acceptedFiles={["image/*"]}
              filesLimit={1}
              onAdd={(images) => setVariant({ ...variant, product_image: images[0] })}
              showPreviewsInDropzone={false}
              maxFileSize={10000000} // allow up to 10MB
            />
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={() => handleAddVariant()}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const VariantCard = ({
  classes,
  vendor,
  variant,
  index,
  edit,
  onDeleteVariant,
  onEditVariant,
  provided,
}) => {
  const currencyPrefix = CURRENCY_PREFIX_MAP[vendor.currency];

  const [anchor, setAnchor] = useState(null);
  const open = Boolean(anchor);

  const handleOpenMenu = (event) => {
    setAnchor(event.currentTarget);
  };

  const handleCloseMenu = () => {
    setAnchor(null);
  };

  return (
    <Card ref={provided.innerRef} className={classes.variantCardRoot} {...provided.draggableProps}>
      <CardMedia
        component="img"
        alt={`variant-${index}`}
        className={classes.variantCardMedia}
        image={variant.product_image.data ? variant.product_image.data : variant.product_image}
      />
      <CardContent style={{ flex: 1, paddingRight: "0px" }}>
        <Typography variant="h6">{variant.title}</Typography>
        <Typography variant="body1" color="textSecondary">
          <div className={classes.pricingSection}>
            {variant.original_price && (
              <span
                style={{ textDecoration: "line-through" }}
              >{`${currencyPrefix}${variant.original_price}`}</span>
            )}
            {currencyPrefix}
            {variant.price}
            {variant.original_price && variant.show_percentage_discount && (
              <Chip
                label={getPercentageDiscount(variant.original_price, variant.price) + `%`}
                className={classes.discountLabel}
              />
            )}
          </div>
        </Typography>
        {variant.inventory !== null && variant.inventory >= 0 && (
          <Typography variant="body1" color="textSecondary">
            <i>Inventory: {variant.inventory}</i>
          </Typography>
        )}
        <Typography variant="body2" style={{ whiteSpace: "pre-wrap" }}>
          {variant.description}
        </Typography>
      </CardContent>
      <div className={classes.variantCardActions}>
        <div {...provided.dragHandleProps} style={{ color: "#000", opacity: 0.54 }}>
          <DragHandle />
        </div>
        <IconButton
          onClick={handleOpenMenu}
          size="small"
          id="qn-menu-button"
          aria-controls="qn-menu"
          aria-haspopup="true"
          aria-expanded={open ? "true" : undefined}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="qn-menu"
          aria-labelledby="qn-menu-button"
          anchorEl={anchor}
          open={open}
          onClose={handleCloseMenu}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          getContentAnchorEl={null}
        >
          <MenuItem
            onClick={(e) => {
              handleCloseMenu(e);
              onEditVariant();
            }}
          >
            Edit
          </MenuItem>
          <MenuItem
            onClick={(e) => {
              handleCloseMenu(e);
              onDeleteVariant(variant);
            }}
          >
            Remove
          </MenuItem>
        </Menu>
      </div>
    </Card>
  );
};

const ProductVariants = ({ product, setProduct, enqueueSnackbar, edit }) => {
  const classes = useStyles();
  const { user, vendor } = useAuth();

  const [variantDialogOpen, setVariantDialogOpen] = useState(false);
  const [editVariantIndex, setEditVariantIndex] = useState();

  const onDeleteVariant = (variant) => {
    if (edit) {
      Auth.client
        .delete(
          `${BACKEND_URL}/${API_VERSION}/vendors/${vendor.id}/products/${product.product_id}/variants/${variant.product_id}`,
        )
        .then((res) => {
          setProduct({ ...product, variants: res.data.variants });
          enqueueSnackbar("Variant deleted", { variant: "success" });
        })
        .catch((err) => console.log(err));
    } else {
      const newVariants = product.variants.filter((v) => v.title !== variant.title);

      setProduct({
        ...product,
        variants: newVariants,
      });
    }
  };

  const onEditVariant = (index) => {
    setEditVariantIndex(index);
    setVariantDialogOpen(true);
  };

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    let reorderedVariants = reorder(
      product.variants,
      result.source.index,
      result.destination.index,
    );
    setProduct({
      ...product,
      variants: reorderedVariants,
    });
  };

  const getListStyle = (isDraggingOver) => ({
    background: isDraggingOver ? "#FFF2D2" : "#ebebeb",
  });

  return (
    <Fragment>
      <Paper className={classes.paper}>
        <Typography variant="h5" className={classes.header}>
          Variants
          <Switch
            style={{ float: "right" }}
            checked={product.use_variants}
            onChange={(e) =>
              setProduct({
                ...product,
                original_price: null,
                use_variants: e.target.checked,
                inventory: null,
              })
            }
            color="primary"
          />
        </Typography>
        {product.use_variants && (
          <Fragment>
            <Typography variant="body2" color="textSecondary" className={classes.subheader}>
              Do you have different variations of your product?
            </Typography>
            <DragDropContext onDragEnd={onDragEnd}>
              <Droppable droppableId="droppable" direction="vertical">
                {(provided, snapshot) => (
                  <div
                    className={classes.variantContainer}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                    {...provided.droppableProps}
                  >
                    {product.variants &&
                      product.variants.map((variant, i) => (
                        <Draggable
                          key={variant.product_id}
                          draggableId={variant.product_id}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <VariantCard
                              index={i}
                              edit={edit}
                              classes={classes}
                              vendor={vendor}
                              variant={variant}
                              onDeleteVariant={onDeleteVariant}
                              onEditVariant={() => onEditVariant(i)}
                              provided={provided}
                            />
                          )}
                        </Draggable>
                      ))}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
            <Button
              style={{ marginTop: 16 }}
              fullWidth
              variant="contained"
              color="secondary"
              onClick={() => setVariantDialogOpen(true)}
            >
              Add Variant
            </Button>
          </Fragment>
        )}
      </Paper>
      {variantDialogOpen && (
        <VariantDialog
          classes={classes}
          vendor={vendor}
          edit={edit}
          open={variantDialogOpen}
          onClose={() => {
            setVariantDialogOpen(false);
            setEditVariantIndex();
          }}
          product={product}
          setProduct={setProduct}
          enqueueSnackbar={enqueueSnackbar}
          editVariantIndex={editVariantIndex}
          hasImageCaption={hasFeature("product_image_caption", user.features)}
        />
      )}
    </Fragment>
  );
};

export default ProductVariants;

import {
  Divider,
  InputAdornment,
  Switch,
  TextField,
  Tooltip,
  Typography,
  Chip,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { HelpOutline } from "@material-ui/icons";
import React from "react";

import GenericContainer from "../../../components/containers/GenericContainer";

import useShopContext from "../../../hooks/useShopContext";
import DragonIcon from "../../../assets/dragon-body.svg";
import SantaIcon from "../../../assets/santa-body.svg";
import ReindeerIcon from "../../../assets/reindeer-body.svg";
import CsyIcon from "../../../assets/csy-body.svg";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[3],
    fontWeight: "normal",
  },
  infoIcon: {
    marginLeft: "8px",
    color: "grey",
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  toggleSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  chip: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    marginLeft: theme.spacing(1.5),
  },
  formControl: {
    margin: theme.spacing(1, 0),
  },
  menuItem: {
    display: "flex",
    alignItems: "center",
    justifyContent: "start",
  },
}));

const ShopGeneral = () => {
  const classes = useStyles();
  const { shopfront, setShopfront } = useShopContext();

  return (
    <GenericContainer>
      <Typography variant="h5" className={classes.header}>
        General Settings
      </Typography>
      <TextField
        variant="outlined"
        margin="dense"
        autoComplete="off"
        fullWidth
        name="title"
        label="URL"
        required
        classes={{ root: classes.textField }}
        InputProps={{
          endAdornment: <InputAdornment position="end">.carteapp.io</InputAdornment>,
        }}
        onChange={(e) => {
          setShopfront({ ...shopfront, subdomain: e.target.value });
        }}
        value={shopfront.subdomain ? shopfront.subdomain : ""}
      />

      <TextField
        variant="outlined"
        margin="dense"
        autoComplete="off"
        fullWidth
        name="title"
        label="Shop Name"
        classes={{ root: classes.textField }}
        onChange={(e) => setShopfront({ ...shopfront, title: e.target.value })}
        value={shopfront.title}
      />

      <div style={{ display: "flex", alignItems: "center" }}>
        <TextField
          id="tnc"
          variant="outlined"
          margin="dense"
          autoComplete="off"
          type="text"
          name="tnc"
          label="Terms & Conditions"
          placeholder="Enter link"
          style={{ flex: 1 }}
          value={shopfront.shop_settings?.tnc || ""}
          onChange={(e) => {
            const shop_settings = shopfront.shop_settings || {};
            shop_settings.tnc = e.target.value;
            setShopfront({
              ...shopfront,
              shop_settings,
            });
          }}
          classes={{ root: classes.textField }}
        />
        <Tooltip
          classes={{ tooltip: classes.tooltip }}
          enterTouchDelay={250}
          title={
            <Typography variant="body2">
              (Optional) Add a link to your terms and conditions, we will require the customer to
              agree before purchase
            </Typography>
          }
          placement="bottom-end"
        >
          <HelpOutline className={classes.infoIcon} />
        </Tooltip>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.toggleSection}>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography>Deactivate Shop</Typography>
          <Tooltip
            classes={{ tooltip: classes.tooltip }}
            enterTouchDelay={250}
            title={<Typography variant="body2">To stop receiving orders</Typography>}
            placement="right"
          >
            <HelpOutline className={classes.infoIcon} />
          </Tooltip>
        </div>

        <Switch
          checked={!shopfront.is_active}
          onChange={(e) => setShopfront({ ...shopfront, is_active: !e.target.checked })}
          color="primary"
        />
      </div>

      <div className={classes.toggleSection}>
        <Typography>Festive Features</Typography>
        <Switch
          checked={shopfront.shop_settings?.enable_seasonal_features || false}
          onChange={(e) => {
            const shop_settings = shopfront.shop_settings || {};
            shop_settings.enable_seasonal_features = e.target.checked;
            setShopfront({
              ...shopfront,
              shop_settings,
            });
          }}
          color="primary"
        />
      </div>
      {shopfront.shop_settings?.enable_seasonal_features && (
        <FormControl variant="outlined" className={classes.formControl} fullWidth>
          <InputLabel>Mascot Type</InputLabel>
          <Select
            label="Mascot Type"
            value={shopfront.shop_settings?.mascot || "dragon"}
            onChange={(e) => {
              const shop_settings = shopfront.shop_settings || {};
              shop_settings.mascot = e.target.value;
              setShopfront({
                ...shopfront,
                shop_settings,
              });
            }}
          >
            <MenuItem value="">
              <em>Select a mascot</em>
            </MenuItem>
            <MenuItem value="dragon">
              <div className={classes.menuItem}>
                <img
                  src={DragonIcon}
                  alt="dragon"
                  height="24"
                  width="24"
                  style={{ marginRight: "8px", alignSelf: "center" }}
                />
                Dragon
              </div>
            </MenuItem>
            <MenuItem value="santa">
              <div className={classes.menuItem}>
                <img
                  src={SantaIcon}
                  alt="santa"
                  height="24"
                  width="24"
                  style={{ marginRight: "8px", alignSelf: "center" }}
                />
                Santa
              </div>
            </MenuItem>
            <MenuItem value="reindeer">
              <div className={classes.menuItem}>
                <img
                  src={ReindeerIcon}
                  alt="reindeer"
                  height="24"
                  width="24"
                  style={{ marginRight: "8px", alignSelf: "center" }}
                />
                Reindeer
              </div>
            </MenuItem>
            <MenuItem value="csy">
              <div className={classes.menuItem}>
                <img
                  src={CsyIcon}
                  alt="csy"
                  height="24"
                  width="24"
                  style={{ marginRight: "8px", alignSelf: "center" }}
                />
                Caishen
              </div>
            </MenuItem>
          </Select>
        </FormControl>
      )}
      <div className={classes.toggleSection}>
        <Typography>Push sold-out products to bottom</Typography>
        <Switch
          checked={shopfront.reorder_soldout_products}
          onChange={(e) =>
            setShopfront({ ...shopfront, reorder_soldout_products: e.target.checked })
          }
          color="primary"
        />
      </div>
      <div className={classes.toggleSection}>
        <Typography>
          Show product categories
          <Chip variant="outlined" size="small" label="Premium" classes={{ root: classes.chip }} />
        </Typography>
        <Switch
          checked={shopfront.use_categories}
          onChange={(e) => setShopfront({ ...shopfront, use_categories: e.target.checked })}
          color="primary"
        />
      </div>
    </GenericContainer>
  );
};

export default ShopGeneral;

import React, { useState, useEffect } from "react";
import {
  LineChart,
  Line,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Tooltip,
  CartesianGrid,
  Label,
} from "recharts";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import ToggleButton from "@material-ui/lab/ToggleButton";
import ToggleButtonGroup from "@material-ui/lab/ToggleButtonGroup";

import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";
import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "#E4E4E4 2px",
    backgroundColor: "#FFFFFF",
    maxWidth: "100%",
    padding: "25px",
    marginBottom: theme.spacing(1),
  },
  headerDiv: {
    display: "flex",
    justifyContent: "space-between",
  },
  blockHeader: {
    fontWeight: "700",
    fontSize: "25px",
  },
  toggleButton: {
    padding: `3px ${theme.spacing(1)}px`,
  },
  toggleButtonGroup: {
    margin: `8px 0`,
  },
}));

const ChartBlock = ({ headerStyle }) => {
  const { vendor, loading } = useAuth();
  const classes = useStyles();

  const [filter, setFilter] = useState("Month");
  const [data, setData] = useState();

  const getData = (filter) => {
    Auth.client
      .get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendor.id}/metrics/earnings`, {
        params: {
          sort: filter.toLowerCase(),
        },
      })
      .then((res) => {
        // console.log(res);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!loading) {
      getData(filter);
    } // eslint-disable-next-line
  }, [loading]);

  const handleFilterChange = (e, value) => {
    if (value) {
      setFilter(value);
      getData(value);
    }
  };

  return (
    <Paper className={classes.paper}>
      <div className={classes.headerDiv} style={{ ...headerStyle }}>
        <div>
          <Typography className={classes.blockHeader}>Earnings</Typography>
          <Typography variant="h3" style={{ fontWeight: "700", marginBottom: "10px" }}>
            ${data && data.reduce((x, y) => x + parseFloat(y.Orders), 0).toFixed(2)}
          </Typography>
        </div>
        <div>
          <ToggleButtonGroup
            className={classes.toggleButtonGroup}
            value={filter}
            exclusive
            onChange={handleFilterChange}
            aria-label="text alignment"
          >
            <ToggleButton className={classes.toggleButton} value="Week">
              Week
            </ToggleButton>
            <ToggleButton className={classes.toggleButton} value="Month">
              Month
            </ToggleButton>
            <ToggleButton className={classes.toggleButton} value="Year">
              Year
            </ToggleButton>
          </ToggleButtonGroup>
        </div>
      </div>
      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={data}>
          <CartesianGrid strokeDasharray="3 3" />
          <Line type="linear" dataKey="Orders" stroke="#FFC000" dot={false} strokeWidth={3} />
          <XAxis dataKey="x">
            <Label value={filter} position="bottom" offset={-6} />
          </XAxis>
          <YAxis
            type="number"
            label={{ value: "Orders", angle: -90, position: "left", offset: -20 }}
          />
          <Tooltip />
        </LineChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default ChartBlock;

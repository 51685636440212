import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Paper,
  FormControlLabel,
  Checkbox,
  Typography,
  Chip,
  Collapse,
  useMediaQuery,
  Popper,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CheckBox, CheckBoxOutlineBlank, ExpandMore } from "@material-ui/icons";
import { useSnackbar } from "notistack";

import { deleteCollectionOption, updateCollectionOption } from "../api";
import { ISO_CODES, ISO_COUNTRY_MAP } from "../../../config/countries";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1, 0),
  },
  paperRoot: {
    padding: theme.spacing(2, 3),
    width: "100%",
    margin: theme.spacing(0.5, 0),
  },
  selectProductsContainer: {
    margin: theme.spacing(1, 0),
  },
  geoBlockContainer: {
    marginBottom: theme.spacing(3),
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5, 1),
  },
  paperActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      width: "100%",
    },
  },
  errorButton: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  updateButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  expandButton: {
    margin: theme.spacing(1, 0, 0),
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

const CollectionOptionCard = ({ initialOption, vendor, setVendor, products }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [collectionOption, setCollectionOption] = useState(initialOption);
  const [expand, setExpand] = useState(false);

  const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"), { noSsr: true });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setCollectionOption({ ...collectionOption, [name]: value });
  };

  const handleRemoveCountry = (country) => {
    let arr = [...collectionOption.allowed_countries];
    arr = arr.filter((iso) => iso !== country);
    setCollectionOption({ ...collectionOption, allowed_countries: arr });
  };

  const handleUpdateCollectionOption = () => {
    if (collectionOption.name === "") {
      enqueueSnackbar("Collection option name cannot be empty", { variant: "error" });
      return;
    }

    if (collectionOption.address === "") {
      enqueueSnackbar("Collection address cannot be empty", { variant: "error" });
      return;
    }

    if (collectionOption.geo_blocked && collectionOption.allowed_countries.length === 0) {
      enqueueSnackbar("Please select at least 1 country to display collection option to", {
        variant: "error",
      });
      return;
    }

    if (collectionOption.restrict_to_selected_products && collectionOption.products.length === 0) {
      enqueueSnackbar("Please select at least 1 product to display collection option to", {
        variant: "error",
      });
      return;
    }

    let updatedCollectionOption = {
      ...collectionOption,
      products: collectionOption.products.map((product) => product.product_id),
    };

    updateCollectionOption(vendor.id, updatedCollectionOption)
      .then((res) => {
        // console.log(res);
        setVendor(res.data);
        enqueueSnackbar("Updated", { variant: "success" });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Something went wrong. Try again later", { variant: "error" });
      });
  };

  const handleDeleteCollectionOption = () => {
    deleteCollectionOption(vendor.id, collectionOption)
      .then((res) => {
        // console.log(res);
        setVendor(res.data);
        enqueueSnackbar("Deleted", { variant: "success" });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Something went wrong. Try again later", { variant: "error" });
      });
  };

  const CustomPopper = (props) => {
    return <Popper {...props} placement={smDown ? "top" : "bottom"} />;
  };

  return (
    <Paper className={classes.paperRoot} elevation={3}>
      <TextField
        variant="outlined"
        autoComplete="off"
        fullWidth
        name="name"
        label="Collection Option Name"
        classes={{ root: classes.textField }}
        value={collectionOption.name}
        onChange={(e) => handleInputChange(e)}
      />
      <Collapse in={expand}>
        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          name="address"
          label="Collection Address"
          classes={{ root: classes.textField }}
          value={collectionOption.address}
          onChange={(e) => handleInputChange(e)}
        />
        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          multiline
          rows={4}
          name="instructions"
          label="Collection Instructions (Optional)"
          classes={{ root: classes.textField }}
          value={collectionOption.instructions}
          onChange={(e) => handleInputChange(e)}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={collectionOption.enable_collection_slots}
              onChange={(e) =>
                setCollectionOption({
                  ...collectionOption,
                  enable_collection_slots: e.target.checked,
                })
              }
            />
          }
          label={
            <Typography variant="body2">
              Allow customers to select a date for self collection
            </Typography>
          }
        />

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={collectionOption.restrict_to_selected_products}
              onChange={(e) => {
                setCollectionOption({
                  ...collectionOption,
                  restrict_to_selected_products: e.target.checked,
                });
              }}
            />
          }
          label={<Typography variant="body2">Restrict option to selected products</Typography>}
        />
        {collectionOption.restrict_to_selected_products && (
          <div className={classes.selectProductsContainer}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              PopperComponent={CustomPopper}
              value={collectionOption.products}
              onChange={(event, selectedProductsArr) => {
                setCollectionOption({ ...collectionOption, products: selectedProductsArr });
              }}
              options={products}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) => option.product_id === value.product_id}
              renderOption={(option, { selected }) => {
                return (
                  <Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={collectionOption.products.some(
                        (product) => product["product_id"] === option.product_id,
                      )}
                    />
                    {option.title}
                  </Fragment>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Products"
                  placeholder="Select or enter product name"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={option.title}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </div>
        )}

        <div className={classes.geoBlockContainer}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={collectionOption.geo_blocked}
                onChange={(e) =>
                  setCollectionOption({ ...collectionOption, geo_blocked: e.target.checked })
                }
              />
            }
            label={<Typography variant="body2">Restrict option to selected countries</Typography>}
          />
          {collectionOption.geo_blocked && (
            <Fragment>
              <Autocomplete
                id="Search countries"
                options={ISO_CODES}
                getOptionLabel={(option) => ISO_COUNTRY_MAP[option]}
                renderOption={(option) => ISO_COUNTRY_MAP[option]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search countries"
                    style={{ marginTop: 8, marginBottom: 16 }}
                  />
                )}
                onChange={(event, iso, reason) => {
                  if (reason === "select-option") {
                    let arr = [...collectionOption.allowed_countries];
                    arr.push(iso);
                    setCollectionOption({ ...collectionOption, allowed_countries: arr });
                  }
                }}
                getOptionDisabled={(option) => collectionOption.allowed_countries.includes(option)}
              />
              <div className={classes.chipContainer}>
                {collectionOption.allowed_countries.map((country, idx) => (
                  <Chip
                    key={idx}
                    color="primary"
                    variant="outlined"
                    label={ISO_COUNTRY_MAP[country]}
                    onDelete={() => handleRemoveCountry(country)}
                  />
                ))}
              </div>
            </Fragment>
          )}
        </div>
      </Collapse>

      <div className={classes.paperActions}>
        <Button
          className={classes.errorButton}
          variant="outlined"
          onClick={handleDeleteCollectionOption}
        >
          Delete
        </Button>
        <Button
          className={classes.updateButton}
          variant="contained"
          color="secondary"
          onClick={handleUpdateCollectionOption}
        >
          Update
        </Button>
      </div>
      <Button fullWidth className={classes.expandButton} onClick={() => setExpand(!expand)}>
        <ExpandMore className={expand ? classes.expandOpen : classes.expand} />
      </Button>
    </Paper>
  );
};

export default CollectionOptionCard;

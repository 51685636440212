import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, TextField, Typography, IconButton } from "@material-ui/core";
import { Delete } from "@material-ui/icons";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { useSnackbar } from "notistack";

import GenericContainer from "../../../components/containers/GenericContainer";

import useAuth from "../../../hooks/useAuth";
import { updateVendor } from "../api";
import { updateVendorLogo, deleteVendorLogo } from "../../ShopSettings/api";
import LoadingSplashScreen from "../../../components/LoadingSplashScreen";

const useStyles = makeStyles((theme) => ({
  detailsSection: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
  },
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0),
  },
  button: {
    boxShadow: "none",
  },
  mobileField: {
    "& input::-webkit-clear-button, & input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button":
      {
        display: "none",
      },
  },
  infoIcon: {
    marginLeft: "8px",
  },
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: "rgba(0, 0, 0, 0.87)",
    boxShadow: theme.shadows[1],
  },
  primaryButton: {
    width: 150,
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-end",
    alignItems: "center",
    margin: theme.spacing(1, 0),
  },

  image: {
    height: "150px",
    objectFit: "contain",
  },
  deleteBtn: {
    position: "relative",
    bottom: 145,
    right: 18,
    height: 36,
    width: 35,
    [theme.breakpoints.down("sm")]: {
      right: 11,
      width: 20,
    },
  },
  previewContainer: {
    width: "100%",
    margin: 0,
  },
  previewGridItem: {
    maxWidth: "100%",
    flexBasis: "auto",
    padding: 0,
  },
  dropzoneContainer: {
    margin: theme.spacing(1, 0),
    width: "50%",
    maxWidth: 250,
    minHeight: 70,
    height: 110,
    // padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      height: 95,
    },
  },
  dropzoneParagraph: {
    fontSize: 14,
    padding: theme.spacing(0, 1),
  },
}));

const ShopSettings = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { vendor, setVendor, loading } = useAuth();

  const handleInputChange = (e) => {
    setVendor({
      ...vendor,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdateDetails = () => {
    updateVendor(vendor)
      .then((res) => {
        // console.log(res);
        setVendor(res.data);
        enqueueSnackbar("Details updated successfully!", { variant: "success" });
      })
      .catch((err) => {
        // console.log(err);
        enqueueSnackbar("Failed to update details!", { variant: "error" });
      });
  };

  const handleAddLogo = (imageArr) => {
    setVendor({ ...vendor, company_photo: imageArr[0].data });
    updateVendorLogo(vendor.id, imageArr[0].file).then((res) => {
      //   console.log(res);
    });
  };

  const handleDeleteLogo = () => {
    setVendor({ ...vendor, company_photo: null });
    deleteVendorLogo(vendor.id).then((res) => {
      //   console.log(res);
    });
  };

  return (
    <GenericContainer>
      {loading ? <LoadingSplashScreen /> : null}
      <div className={classes.detailsSection}>
        <Typography variant="h5" className={classes.header}>
          Shop
        </Typography>
        <div style={{ marginTop: 8 }}>
          {vendor.company_photo ? (
            <div className={classes.imageItem}>
              <img src={vendor.company_photo} className={classes.image} alt="vendors's logo" />
              <IconButton
                disableRipple
                size="small"
                className={classes.deleteBtn}
                onClick={() => handleDeleteLogo()}
              >
                <Delete />
              </IconButton>
            </div>
          ) : (
            <>
              <DropzoneAreaBase
                dropzoneText={
                  <>
                    Drop image here or{" "}
                    <Button variant="contained" color="primary" style={{ color: "#fff" }}>
                      Upload Image
                    </Button>
                  </>
                }
                dropzoneParagraphClass={classes.dropzoneParagraph}
                dropzoneClass={classes.dropzoneContainer}
                acceptedFiles={["image/*"]}
                filesLimit={1}
                maxFileSize={10000000} // allow up to 10MB
                onAdd={(newPhoto) => handleAddLogo(newPhoto)}
              />
              <Typography color="textSecondary" variant="body2">
                Transparent PNG files work best! :)
              </Typography>
            </>
          )}
        </div>
        <TextField
          id="name"
          label="Name"
          variant="outlined"
          margin="dense"
          autoComplete="off"
          type="text"
          name="vendor_name"
          value={vendor.vendor_name ? vendor.vendor_name : ""}
          onChange={handleInputChange}
        />
        <TextField
          id="mobile"
          variant="outlined"
          margin="dense"
          autoComplete="off"
          type="number"
          className={classes.mobileField}
          name="contact_number"
          label="Mobile"
          value={vendor.contact_number ? vendor.contact_number : ""}
          onChange={handleInputChange}
        />
        <div className={classes.buttonContainer}>
          <Button
            className={classes.primaryButton}
            variant="contained"
            color="primary"
            onClick={handleUpdateDetails}
          >
            Save Details
          </Button>
        </div>
      </div>
    </GenericContainer>
  );
};

export default ShopSettings;

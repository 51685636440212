import React, { Fragment, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  TextField,
  Button,
  Paper,
  InputAdornment,
  FormControlLabel,
  Checkbox,
  Typography,
  Chip,
  Collapse,
  Popper,
  useMediaQuery,
} from "@material-ui/core";
import { Autocomplete } from "@material-ui/lab";
import { CheckBox, CheckBoxOutlineBlank, ExpandMore } from "@material-ui/icons";
import { useSnackbar } from "notistack";

import { deleteDeliveryOption, updateDeliveryOption } from "../api";
import { CURRENCY_PREFIX_MAP } from "../../../config/currency";
import { ISO_CODES, ISO_COUNTRY_MAP } from "../../../config/countries";

const useStyles = makeStyles((theme) => ({
  textField: {
    margin: theme.spacing(1, 0),
  },
  paperRoot: {
    padding: theme.spacing(2, 3),
    width: "100%",
    margin: theme.spacing(0.5, 0),
  },
  selectProductsContainer: {
    margin: theme.spacing(1, 0),
  },
  geoBlockContainer: {
    marginBottom: theme.spacing(3),
  },
  chipContainer: {
    display: "flex",
    flexWrap: "wrap",
    gap: theme.spacing(1.5, 1),
  },
  paperActions: {
    display: "flex",
    justifyContent: "flex-end",
    gap: theme.spacing(1),
    [theme.breakpoints.down("sm")]: {
      justifyContent: "center",
      width: "100%",
    },
  },
  errorButton: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  updateButton: {
    [theme.breakpoints.down("sm")]: {
      width: "100%",
    },
  },
  expandButton: {
    margin: theme.spacing(1, 0, 0),
  },
  expand: {
    transform: "rotate(0deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
  expandOpen: {
    transform: "rotate(180deg)",
    transition: theme.transitions.create("transform", {
      duration: theme.transitions.duration.shortest,
    }),
  },
}));

const DeliveryOptionCard = ({ initialOption, vendor, setVendor, products }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const currencyPrefix = CURRENCY_PREFIX_MAP[vendor.currency];

  const [deliveryOption, setDeliveryOption] = useState(initialOption);
  const [expand, setExpand] = useState(false);

  const smDown = useMediaQuery((theme) => theme.breakpoints.down("sm"), { noSsr: true });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setDeliveryOption({ ...deliveryOption, [name]: value });
    // console.log(vendor);
  };

  const handleRemoveCountry = (country) => {
    let arr = [...deliveryOption.allowed_countries];
    arr = arr.filter((iso) => iso !== country);
    setDeliveryOption({ ...deliveryOption, allowed_countries: arr });
  };

  const handleUpdateDeliveryOption = () => {
    if (deliveryOption.name === "") {
      enqueueSnackbar("Delivery option name cannot be empty", { variant: "error" });
      return;
    }

    if (deliveryOption.delivery_fee === "") {
      enqueueSnackbar("Delivery fee cannot be empty", { variant: "error" });
      return;
    }

    if (deliveryOption.geo_blocked && deliveryOption.allowed_countries.length === 0) {
      enqueueSnackbar("Please select at least 1 country to display delivery option to", {
        variant: "error",
      });
      return;
    }

    if (deliveryOption.restrict_to_selected_products && deliveryOption.products.length === 0) {
      enqueueSnackbar("Please select at least 1 product to display delivery option to", {
        variant: "error",
      });
      return;
    }

    let updatedDeliveryOption = {
      ...deliveryOption,
      products: deliveryOption.products.map((product) => product.product_id),
    };

    updateDeliveryOption(vendor.id, updatedDeliveryOption)
      .then((res) => {
        // console.log(res);
        setVendor(res.data);
        enqueueSnackbar("Updated", { variant: "success" });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Something went wrong. Try again later", { variant: "error" });
      });
  };

  const handleDeleteDeliveryOption = () => {
    deleteDeliveryOption(vendor.id, deliveryOption)
      .then((res) => {
        // console.log(res);
        setVendor(res.data);
        enqueueSnackbar("Deleted", { variant: "success" });
      })
      .catch((err) => {
        console.log(err);
        enqueueSnackbar("Something went wrong. Try again later", { variant: "error" });
      });
  };

  const CustomPopper = (props) => {
    return <Popper {...props} placement={smDown ? "top" : "bottom"} />;
  };

  return (
    <Paper className={classes.paperRoot} elevation={3}>
      <TextField
        variant="outlined"
        autoComplete="off"
        fullWidth
        name="name"
        label="Delivery Option Name"
        classes={{ root: classes.textField }}
        value={deliveryOption.name ? deliveryOption.name : ""}
        onChange={(e) => handleInputChange(e)}
      />
      <Collapse in={expand}>
        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          type="number"
          name="delivery_fee"
          label="Delivery Fee"
          InputProps={{
            startAdornment: <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
          }}
          inputProps={{
            min: 0,
          }}
          classes={{ root: classes.textField }}
          value={deliveryOption.delivery_fee ? deliveryOption.delivery_fee : ""}
          onChange={(e) => handleInputChange(e)}
          onWheel={(e) => e.target.blur()}
        />
        <TextField
          variant="outlined"
          autoComplete="off"
          fullWidth
          multiline
          rows={4}
          name="instructions"
          label="Delivery Instructions (Optional)"
          classes={{ root: classes.textField }}
          value={deliveryOption.instructions ? deliveryOption.instructions : ""}
          onChange={(e) => handleInputChange(e)}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={deliveryOption.enable_delivery_slots}
              onChange={(e) => {
                setDeliveryOption({
                  ...deliveryOption,
                  enable_delivery_slots: e.target.checked,
                });
              }}
            />
          }
          label={<Typography variant="body2">Allow customers to select a delivery date</Typography>}
        />
        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={deliveryOption.free_delivery_min_spend !== null}
              onChange={(e) => {
                setDeliveryOption({
                  ...deliveryOption,
                  free_delivery_min_spend: e.target.checked ? 0 : null,
                });
              }}
            />
          }
          label={<Typography variant="body2">Free delivery with minimum spend</Typography>}
        />
        {deliveryOption.free_delivery_min_spend !== null && (
          <div>
            <TextField
              autoComplete="off"
              type="number"
              inputProps={{
                min: 0,
              }}
              name="free_delivery_min_spend"
              label="Minimum Spending"
              fullWidth
              InputProps={{
                startAdornment: <InputAdornment position="start">{currencyPrefix}</InputAdornment>,
              }}
              classes={{ root: classes.textField }}
              value={deliveryOption.free_delivery_min_spend}
              onChange={(e) => handleInputChange(e)}
              onWheel={(e) => e.target.blur()}
            />
          </div>
        )}

        <FormControlLabel
          control={
            <Checkbox
              color="primary"
              checked={deliveryOption.restrict_to_selected_products}
              onChange={(e) => {
                setDeliveryOption({
                  ...deliveryOption,
                  restrict_to_selected_products: e.target.checked,
                });
              }}
            />
          }
          label={<Typography variant="body2">Restrict option to selected products</Typography>}
        />
        {deliveryOption.restrict_to_selected_products && (
          <div className={classes.selectProductsContainer}>
            <Autocomplete
              multiple
              disableCloseOnSelect
              PopperComponent={CustomPopper}
              value={deliveryOption.products}
              onChange={(event, selectedProductsArr) => {
                setDeliveryOption({ ...deliveryOption, products: selectedProductsArr });
              }}
              options={products}
              getOptionLabel={(option) => option.title}
              getOptionSelected={(option, value) => option.product_id === value.product_id}
              renderOption={(option, { selected }) => {
                return (
                  <Fragment>
                    <Checkbox
                      color="primary"
                      icon={<CheckBoxOutlineBlank fontSize="small" />}
                      checkedIcon={<CheckBox fontSize="small" />}
                      style={{ marginRight: 8 }}
                      checked={deliveryOption.products.some(
                        (product) => product["product_id"] === option.product_id,
                      )}
                    />
                    {option.title}
                  </Fragment>
                );
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  label="Select Products"
                  placeholder="Select or enter product name"
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((option, index) => (
                  <Chip
                    variant="outlined"
                    color="primary"
                    label={option.title}
                    size="small"
                    {...getTagProps({ index })}
                  />
                ))
              }
            />
          </div>
        )}

        <div className={classes.geoBlockContainer}>
          <FormControlLabel
            control={
              <Checkbox
                color="primary"
                checked={deliveryOption.geo_blocked}
                onChange={(e) => {
                  setDeliveryOption({ ...deliveryOption, geo_blocked: e.target.checked });
                }}
              />
            }
            label={<Typography variant="body2">Restrict option to selected countries</Typography>}
          />
          {deliveryOption.geo_blocked && (
            <Fragment>
              <Autocomplete
                id="Search countries"
                options={ISO_CODES}
                getOptionLabel={(option) => ISO_COUNTRY_MAP[option]}
                renderOption={(option) => ISO_COUNTRY_MAP[option]}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Search countries"
                    style={{ marginTop: 8, marginBottom: 16 }}
                  />
                )}
                onChange={(event, iso, reason) => {
                  if (reason === "select-option") {
                    let arr = [...deliveryOption.allowed_countries];
                    arr.push(iso);
                    setDeliveryOption({ ...deliveryOption, allowed_countries: arr });
                  }
                }}
                getOptionDisabled={(option) => deliveryOption.allowed_countries.includes(option)}
              />
              <div className={classes.chipContainer}>
                {deliveryOption.allowed_countries.map((country, idx) => (
                  <Chip
                    key={idx}
                    color="primary"
                    variant="outlined"
                    label={ISO_COUNTRY_MAP[country]}
                    onDelete={() => handleRemoveCountry(country)}
                  />
                ))}
              </div>
            </Fragment>
          )}
        </div>
      </Collapse>

      <div className={classes.paperActions}>
        <Button
          className={classes.errorButton}
          variant="outlined"
          onClick={handleDeleteDeliveryOption}
        >
          Delete
        </Button>
        <Button
          className={classes.updateButton}
          variant="contained"
          color="secondary"
          onClick={handleUpdateDeliveryOption}
        >
          Update
        </Button>
      </div>
      <Button fullWidth className={classes.expandButton} onClick={() => setExpand(!expand)}>
        <ExpandMore className={expand ? classes.expandOpen : classes.expand} />
      </Button>
    </Paper>
  );
};

export default DeliveryOptionCard;

import {
  Facebook,
  Instagram,
  Link,
  Telegram,
  WhatsApp,
  Twitter,
  YouTube,
  LinkedIn,
  Reddit,
  GitHub,
  Pinterest,
  Email,
  Phone,
} from "@material-ui/icons";
import { SvgIcon } from "@material-ui/core";
import { ReactComponent as TikTokIconSvg } from "./assets/tiktok-brands-solid.svg"; // Adjust the path as needed

export function TikTok(props) {
  return (
    <SvgIcon {...props}>
      <TikTokIconSvg />
    </SvgIcon>
  );
}

export const getUrlIcon = (url, fontSize = "medium") => {
  const urlLower = url.toLowerCase();
  console.log(urlLower);
  // Social media
  if (urlLower.includes("facebook.com") || urlLower.includes("fb.com")) {
    return <Facebook fontSize={fontSize} />;
  }
  if (urlLower.includes("instagram.com")) {
    return <Instagram fontSize={fontSize} />;
  }
  if (urlLower.includes("twitter.com") || urlLower.includes("x.com")) {
    return <Twitter fontSize={fontSize} />;
  }
  if (urlLower.includes("youtube.com") || urlLower.includes("youtu.be")) {
    return <YouTube fontSize={fontSize} />;
  }
  if (urlLower.includes("linkedin.com")) {
    return <LinkedIn fontSize={fontSize} />;
  }
  if (urlLower.includes("reddit.com")) {
    return <Reddit fontSize={fontSize} />;
  }
  if (urlLower.includes("github.com")) {
    return <GitHub fontSize={fontSize} />;
  }
  if (urlLower.includes("pinterest.com")) {
    return <Pinterest fontSize={fontSize} />;
  }
  // TOOD: check if usage is allowed
  // if (urlLower.includes("tiktok.com")) {
  //   return <TikTok fontSize={fontSize} />;
  // }

  // Messaging
  if (urlLower.includes("telegram.me") || urlLower.includes("t.me")) {
    return <Telegram fontSize={fontSize} />;
  }
  if (urlLower.includes("whatsapp.com") || urlLower.includes("wa.me")) {
    return <WhatsApp fontSize={fontSize} />;
  }

  // Contact
  if (urlLower.startsWith("mailto:")) {
    return <Email fontSize={fontSize} />;
  }
  if (urlLower.startsWith("tel:")) {
    return <Phone fontSize={fontSize} />;
  }

  return <Link fontSize={fontSize} />;
};

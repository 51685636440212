import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";

import PageTitle from "../../components/PageTitle";
import DeliverySettings from "./containers/DeliverySettings";
import Schedule from "./containers/Schedule";
import CollectionSettings from "./containers/CollectionSettings";
import { Helmet } from "react-helmet";
import { fetchProducts } from "../ProductList/api";
import useAuth from "../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  root: {
    margin: 30,
  },
}));

const Delivery = (props) => {
  const classes = useStyles();
  const { vendor, loading } = useAuth();

  const [products, setProducts] = useState([]);

  const fetchData = () => {
    fetchProducts(vendor.id, { active: true, variants: false }).then((res) => {
      setProducts(res.data);
    });
  };

  useEffect(() => {
    if (!loading) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loading]);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Delivery | Carte</title>
      </Helmet>
      <PageTitle title="Delivery/Collection" />
      <Schedule {...props} />
      <DeliverySettings products={products} {...props} />
      <CollectionSettings products={products} {...props} />
    </div>
  );
};

export default Delivery;

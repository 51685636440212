import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Switch, Typography } from "@material-ui/core";
import { AirportShuttleTwoTone } from "@material-ui/icons";

import GenericContainer from "../../../components/containers/GenericContainer";
import DeliveryOptions from "../components/DeliveryOptions";
import DeliveryOptionDialog from "../components/DeliveryOptionDialog";
import { updateVendorFulfillmentInfo } from "../api";
import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
    flexGrow: 1,
  },
  optionContainer: {
    margin: theme.spacing(2, 0, 1),
  },
  settingsIcon: {
    marginRight: theme.spacing(1.5),
  },
}));

const DeliverySettings = ({ products, ...props }) => {
  const { vendor, setVendor } = useAuth();
  const classes = useStyles();

  const [deliveryOptionDialogOpen, setDeliveryOptionDialogOpen] = useState(false);

  return (
    <GenericContainer>
      <div style={{ display: "flex", alignItems: "center" }}>
        <AirportShuttleTwoTone className={classes.settingsIcon} />
        <Typography variant="h5" className={classes.header}>
          Delivery
        </Typography>
        <Switch
          color="primary"
          checked={vendor?.delivery || false}
          onChange={(e) => {
            setVendor({ ...vendor, delivery: e.target.checked });
            updateVendorFulfillmentInfo({ ...vendor, delivery: e.target.checked });
          }}
        />
      </div>
      <Typography variant="body2" color="textSecondary">
        Set your delivery options, minimum spending and/or delivery fees.
      </Typography>

      {vendor?.delivery && (
        <div className={classes.optionContainer}>
          <DeliveryOptions
            deliveryOptions={vendor.delivery_options}
            vendor={vendor}
            setVendor={setVendor}
            products={products}
            {...props}
          />
          <Button
            variant="contained"
            fullWidth
            color="primary"
            style={{ marginTop: 8 }}
            onClick={() => setDeliveryOptionDialogOpen(true)}
          >
            Add Delivery Option
          </Button>
        </div>
      )}

      <DeliveryOptionDialog
        open={deliveryOptionDialogOpen}
        setOpen={setDeliveryOptionDialogOpen}
        vendor={vendor}
        setVendor={setVendor}
      />
    </GenericContainer>
  );
};

export default DeliverySettings;

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Paper, Typography } from "@material-ui/core";
import { PieChart, Pie, ResponsiveContainer, Tooltip, Cell } from "recharts";

import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";
import useAuth from "../../../hooks/useAuth";

const colors = ["#202091", "#833AB4", "#C13C3C"];

const useStyles = makeStyles((theme) => ({
  paper: {
    border: "#E4E4E4 2px",
    backgroundColor: "#FFFFFF",
    maxWidth: "100%",
    padding: "25px",
    marginBottom: theme.spacing(1),
  },
  blockHeader: {
    fontWeight: "700",
    fontSize: "25px",
  },
}));

const DoughnutChartBlock = () => {
  const { vendor, loading } = useAuth();
  const classes = useStyles();

  const [data, setData] = useState();

  const getData = () => {
    Auth.client
      .get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendor.id}/metrics/products`)
      .then((res) => {
        // console.log(res);
        setData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    if (!loading) {
      getData();
    } // eslint-disable-next-line
  }, [loading]);

  return (
    <Paper className={classes.paper}>
      <Typography className={classes.blockHeader}>Earnings By Product</Typography>
      <ResponsiveContainer width="100%" height={200}>
        <PieChart>
          <Pie data={data} dataKey="value" nameKey="name" innerRadius={60} outerRadius={80} label>
            {data && data.map((entry, i) => <Cell key={`entry-${i}`} fill={colors[i % 3]} />)}
          </Pie>
          <Tooltip />
          {/* <Legend layout="vertical" align="center" verticalAlign="bottom" /> */}
        </PieChart>
      </ResponsiveContainer>
    </Paper>
  );
};

export default DoughnutChartBlock;

import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";
import { BrowserRouter } from "react-router-dom";
import { ThemeProvider } from "@material-ui/styles";
import CssBaseline from "@material-ui/core/CssBaseline";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import { Button } from "@material-ui/core";
import { SnackbarProvider } from "notistack";

import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import theme from "../src/theme";
import DateFnsUtils from "@date-io/date-fns";
import { AuthProvider } from "./hooks/useAuth";
import * as Sentry from "@sentry/browser";

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({ dsn: process.env.REACT_APP_SENTRY_DSN });
}

// add action to all snackbars
const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

let render = () => {
  ReactDOM.render(
    <BrowserRouter>
      <AuthProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <ThemeProvider theme={theme}>
            <SnackbarProvider
              maxSnack={2}
              ref={notistackRef}
              autoHideDuration={3000}
              action={(key) => (
                <Button onClick={onClickDismiss(key)} style={{ color: "#fff" }}>
                  Dismiss
                </Button>
              )}
              anchorOrigin={{ vertical: "top", horizontal: "right" }}
            >
              <CssBaseline />
              <App />
            </SnackbarProvider>
          </ThemeProvider>
        </MuiPickersUtilsProvider>
      </AuthProvider>
    </BrowserRouter>,
    document.getElementById("root"),
  );
};

// adding this hot module allows changes to be seen immediately on the local
// host without the page having to be refreshed entirely for changes to be seen
if (module.hot) {
  module.hot.accept("./App", () => {
    setTimeout(render);
  });
}

render();

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Link,
} from "@material-ui/core";

import { fetchProducts } from "../api";
import { exportCSVFile, getShopDomain } from "../../../utils";
import useShopContext from "../../../hooks/useShopContext";
import useAuth from "../../../hooks/useAuth";

const useStyles = makeStyles((theme) => ({
  dialog: {
    width: "40%",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  chip: {
    color: theme.palette.error.main,
    border: `1px solid ${theme.palette.error.main}`,
  },
  link: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const ExportProductsDialog = ({ open, onClose }) => {
  const classes = useStyles();
  const { shopfront } = useShopContext();
  const { vendor } = useAuth();

  const UploadProductsTutorialURL =
    "https://www.facebook.com/business/help/125074381480892?id=725943027795860";
  const MetaCommerceManagerURL = "https://business.facebook.com/commerce/";

  const [exporting, setExporting] = useState(false);

  const exportActiveProducts = () => {
    setExporting(true);

    const headers = {
      id: "id",
      title: "title",
      description: "description",
      availability: "availability",
      quantity_to_sell_on_facebook: "quantity_to_sell_on_facebook",
      condition: "condition",
      price: "price",
      link: "link",
      image_link: "image_link",
      additional_image_link: "additional_image_link",
      brand: "brand",
      sale_price: "sale_price",
      item_group_id: "item_group_id",
    };

    fetchProducts(vendor.id, { active: true })
      .then((res) => {
        // console.log(res);
        let forCSV = [];
        res.data.forEach((product) => {
          if (product.use_variants) {
            const variantsArr = product.variants.map((variant) => ({
              id: variant.product_id,
              title: variant.title,
              description: `"${variant.description}"`,
              availability: variant.inventory !== 0 ? "in stock" : "out of stock",
              quantity_to_sell_on_facebook: variant.inventory !== null ? variant.inventory : "",
              condition: "new",
              price: variant.original_price
                ? `${variant.original_price} ${vendor?.currency.toUpperCase()}`
                : `${variant.price} ${vendor?.currency.toUpperCase()}`,
              link: `${getShopDomain(shopfront)}/products/${product.product_id}`,
              image_link: variant.product_image,
              additional_image_link: "",
              brand: shopfront?.title,
              sale_price: variant.original_price
                ? `${variant.price} ${vendor?.currency.toUpperCase()}`
                : "",
              item_group_id: product.product_id,
            }));
            forCSV = forCSV.concat(variantsArr);
          } else {
            forCSV.push({
              id: product.product_id,
              title: product.title,
              description: `"${product.description}"`,
              availability: product.inventory !== 0 ? "in stock" : "out of stock",
              quantity_to_sell_on_facebook: product.inventory !== null ? product.inventory : "",
              condition: "new",
              price: product.original_price
                ? `${product.original_price} ${vendor?.currency.toUpperCase()}`
                : `${product.price} ${vendor?.currency.toUpperCase()}`,
              link: `${getShopDomain(shopfront)}/products/${product.product_id}`,
              image_link: product.images[0].image,
              additional_image_link:
                product.images.length > 1
                  ? `"${product.images
                      .slice(1)
                      .map((imgObj) => imgObj.image)
                      .join(", ")}"`
                  : "",
              brand: shopfront?.title,
              sale_price: product.original_price
                ? `${product.price} ${vendor?.currency.toUpperCase()}`
                : "",
              item_group_id: "",
            });
          }
        });

        exportCSVFile(headers, forCSV, "products");
      })
      .catch((err) => console.log(err))
      .finally(() => setExporting(false));
  };

  return (
    <Dialog open={open} onClose={() => onClose()} classes={{ paper: classes.dialog }}>
      <DialogTitle>Export Products for Meta/Instagram Shop</DialogTitle>
      <DialogContent>
        Add active products to your Meta/Instagram shop by uploading the .CSV file generated here to
        your{" "}
        <Link className={classes.link} href={MetaCommerceManagerURL} target="__blank">
          Commerce Manager
        </Link>{" "}
        in Meta.
        <br />
        <br />
        Refer{" "}
        <Link className={classes.link} href={UploadProductsTutorialURL} target="__blank">
          here
        </Link>{" "}
        for more information (under 'Upload a new data feed to a catalogue').
      </DialogContent>
      <DialogActions>
        <Button variant="contained" disabled={exporting} onClick={() => onClose()}>
          Cancel
        </Button>
        {exporting ? (
          <Button
            color="primary"
            variant="contained"
            startIcon={<CircularProgress size="1.5rem" color="inherit" />}
          >
            Exporting
          </Button>
        ) : (
          <Button color="primary" variant="contained" onClick={() => exportActiveProducts()}>
            Export (.CSV)
          </Button>
        )}
      </DialogActions>
    </Dialog>
  );
};

export default ExportProductsDialog;

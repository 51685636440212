import React, { Fragment, useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Dialog,
  DialogActions,
  DialogContent,
  Typography,
  Button,
  IconButton,
  CircularProgress,
  Card,
  TextField,
  FormControlLabel,
  Checkbox,
} from "@material-ui/core";
import { Close } from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { useSnackbar } from "notistack";

import useOrdersContext from "../../../hooks/useOrdersContext";

import { sendDeliveryNotification, updateOrderStatus } from "../api";
import { parseDate } from "../../../utils";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "60%",
    maxHeight: "90vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  dialogHeader: {
    margin: theme.spacing(1, 0),
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: theme.spacing(1, 3),
  },
  dialogContent: {
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
  },
  button: {
    margin: theme.spacing(0, 1),
    width: 150,
  },
  denseSelect: {
    padding: theme.spacing(1),
  },
  orderContainer: {
    flex: 1,
    overflow: "hidden",
    overflowY: "auto",
    padding: theme.spacing(1),
    display: "flex",
    flexDirection: "column",
    gap: theme.spacing(1),
    backgroundColor: theme.palette.grey[200],
    borderRadius: 4,
  },
  loadingContainer: {
    width: "100%",
    display: "grid",
    placeItems: "center",
    padding: theme.spacing(2),
  },
  alert: {
    margin: theme.spacing(1),
  },
  cardRoot: {
    overflow: "visible",
    padding: theme.spacing(2, 3),
  },
  label: {
    fontSize: 14,
  },
}));

const DeliveryNotificationDialog = ({ open, setOpen, rows, selectedRows }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { refreshOrders } = useOrdersContext();

  const [loading, setLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [updateStatus, setUpdateStatus] = useState(false);

  const [submitLoading, setSubmitLoading] = useState(false);

  const handleInputChange = (e, index) => {
    const clonedOrder = { ...orders[index], [e.target.name]: e.target.value };
    const clonedOrders = [...orders];
    clonedOrders[index] = clonedOrder;
    setOrders(clonedOrders);
  };

  const handleConfirm = async () => {
    setSubmitLoading(true);
    for (const order of orders) {
      await sendDeliveryNotification({ transaction: order, updateStatus: updateStatus })
        .then(() => {
          if (updateStatus) {
            updateOrderStatus({
              vendorId: order.vendor_id,
              id: order.transaction_id,
              status: "PENDING_DELIVERY",
            });
          }
        })
        .catch((err) => enqueueSnackbar(err.response.data, { variant: "error" }));
    }

    setSubmitLoading(false);
    enqueueSnackbar("Notifications sent", { variant: "success" });
    refreshOrders();
    setOpen(false);
  };

  useEffect(() => {
    setLoading(true);
    setOrders(
      rows
        .filter((row) => selectedRows.includes(row.id))
        .map((row) => ({
          ...row,
          delivery_partner: row.delivery_partner || "",
          delivery_tracking_id: row.delivery_tracking_id || "",
        })),
    );
    setLoading(false);
  }, [rows, selectedRows]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: classes.paper }}>
      <div className={classes.dialogHeader}>
        <Typography variant="h4" style={{ fontWeight: 500 }}>
          Send Delivery Notification
        </Typography>
        <IconButton size="small" onClick={() => setOpen(false)}>
          <Close />
        </IconButton>
      </div>
      <DialogContent className={classes.dialogContent}>
        <Alert className={classes.alert} severity="info">
          {/* <AlertTitle>An email will be sent out with </AlertTitle> */}
          To prevent spam, we will only send up to <strong>5</strong> emails (including invoices)
          per order.
        </Alert>

        <FormControlLabel
          control={
            <Checkbox
              checked={updateStatus}
              onChange={(e) => setUpdateStatus(e.target.checked)}
              color="primary"
            />
          }
          label="Update status to Pending Delivery"
        />

        {loading ? (
          <div className={classes.loadingContainer}>
            <CircularProgress />
          </div>
        ) : (
          <Fragment>
            <Typography variant="body2" color="textSecondary">
              {orders.length} item(s) selected; Leave fields empty if not applicable.
            </Typography>
            <div className={classes.orderContainer}>
              {orders.map((row, index) => (
                <Card key={row.id} className={classes.cardRoot} variant="outlined">
                  <Typography className={classes.label} color="textSecondary">
                    ID
                  </Typography>
                  <Typography style={{ fontWeight: 500 }} gutterBottom>
                    {row.public_transaction_id}
                  </Typography>

                  <Typography className={classes.label} color="textSecondary">
                    Customer
                  </Typography>
                  <Typography gutterBottom>{row.customer.customer_name}</Typography>

                  <Typography className={classes.label} color="textSecondary">
                    Address
                  </Typography>
                  <Typography gutterBottom> {row.delivery_address || "NA"}</Typography>

                  <Typography className={classes.label} color="textSecondary">
                    Delivery Date
                  </Typography>
                  <Typography gutterBottom>
                    {" "}
                    {row.delivery_date ? parseDate(row.delivery_date) : "NA"}
                  </Typography>

                  <TextField
                    variant="outlined"
                    label="Delivery Partner"
                    name="delivery_partner"
                    margin="dense"
                    fullWidth
                    onChange={(e) => handleInputChange(e, index)}
                    value={row.delivery_partner}
                  />
                  <TextField
                    variant="outlined"
                    label="Tracking Number"
                    name="delivery_tracking_id"
                    margin="dense"
                    fullWidth
                    onChange={(e) => handleInputChange(e, index)}
                    value={row.delivery_tracking_id}
                  />
                  <Typography variant="body2" color="textSecondary">
                    <i>Email limit: {row.email_quota}/5</i>
                  </Typography>
                </Card>
              ))}
            </div>
          </Fragment>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={handleConfirm}
          className={classes.button}
          color="primary"
          variant="contained"
          disabled={selectedRows.length <= 0 || submitLoading}
        >
          {submitLoading ? <CircularProgress size={24} /> : "Confirm"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryNotificationDialog;

import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const fetchProducts = (vendorId, queryParams) => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/products`, {
    params: queryParams,
  });
};

export const updateProductStatus = (product, changeActivationOfProduct) => {
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${product.vendor_id}/products/${product.product_id}`,
    changeActivationOfProduct,
  );
};

export const getAllProducts = (vendorId) => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/products`, {
    params: { dump: 1 },
  });
};

export const reorderProducts = (vendorId, productIds) => {
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/products`,
    productIds,
  );
};

export const scheduleProductsRelease = (vendorId, scheduleReleaseObj) => {
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/schedule-product-release`,
    scheduleReleaseObj,
  );
};

export const getProductCategories = (vendorId) => {
  return Auth.client.get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/product-categories`);
};

export const addProductCategory = (vendorId, categoryName) => {
  return Auth.client.post(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/product-categories`, {
    product_category_name: categoryName,
    products: [],
  });
};

export const updateProductCategory = (vendorId, updatedCategoryObj) => {
  return Auth.client.put(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/product-categories/${updatedCategoryObj.product_category_id}`,
    updatedCategoryObj,
  );
};

export const deleteProductCategory = (vendorId, selectedCategory) => {
  return Auth.client.delete(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/product-categories/${selectedCategory.product_category_id}`,
  );
};

export const reorderProductCategories = (vendorId, categoryIds) => {
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/product-categories`,
    categoryIds,
  );
};

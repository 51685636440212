import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Add } from "@material-ui/icons";
import { Button, Typography } from "@material-ui/core";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { useSnackbar } from "notistack";

import GenericContainer from "../../../../components/containers/GenericContainer";
import ElementDialog from "./ElementDialog";
import ElementCard from "./ElementCard";
import DeleteElementDialog from "./DeleteElementDialog";

import useShopContext from "../../../../hooks/useShopContext";
import { reorder } from "../../../../utils";
import { reorderElements, createElement, editElement, deleteElement } from "../../api";
import { validateElement } from "../../helpers/validation";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
  subheader: {
    marginBottom: theme.spacing(1),
  },
  elementsContainer: {
    display: "flex",
    flexDirection: "column",
    margin: `${theme.spacing(1)}px 0`,
    borderRadius: 4,
  },
  link: {
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const ShopElements = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { shopfront, setShopfront, refreshShopfront } = useShopContext();

  const [elementDialogOpen, setElementDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [element, setElement] = useState({
    title: "",
    order: 0,
    element_type: "",
    image: [],
  });

  const getListStyle = (isDraggingOver) => ({
    padding: 8,
    background: isDraggingOver ? "#FFF2D2" : "#ebebeb",
  });

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const elements = shopfront.elements;

    let reorderedElements = reorder(elements, result.source.index, result.destination.index);
    setShopfront({ ...shopfront, elements: reorderedElements });
    reorderElements(
      reorderedElements.map((element) => element.element_id),
      shopfront.shopfront_id,
      shopfront.vendor.id,
    ).catch((err) => enqueueSnackbar(`Error: ${err.response.data}`, { variant: "error" }));
  };

  const handleCreateElement = () => {
    const result = validateElement(element);
    if (!result.valid) {
      enqueueSnackbar(result.errorMsg, { variant: "error" });
      return;
    }

    const formData = new FormData();

    for (const [key, value] of Object.entries(element)) {
      if (key === "image") {
        value.length > 0 && formData.append(key, value[0].file);
      } else {
        formData.append(key, value);
      }
    }

    createElement(formData, shopfront.shopfront_id, shopfront.vendor.id)
      .then((res) => {
        // console.log(res);
        refreshShopfront();
        setElementDialogOpen(false);
        enqueueSnackbar("Element created!", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(`Error: ${err.response.data}`, { variant: "error" });
      });
  };

  const handleEditElement = () => {
    const result = validateElement(element);
    if (!result.valid) {
      enqueueSnackbar(result.errorMsg, { variant: "error" });
      return;
    }

    const formData = new FormData();

    for (const [key, value] of Object.entries(element)) {
      if (key === "image" && Array.isArray(value)) {
        value.length > 0 ? formData.append(key, value[0].file) : formData.append(key, null);
      } else {
        formData.append(key, value);
      }
    }

    editElement(formData, element.element_id, shopfront.shopfront_id, shopfront.vendor.id)
      .then((res) => {
        // console.log(res);
        refreshShopfront();
        setElementDialogOpen(false);
        enqueueSnackbar("Element updated!", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(`Error: ${err.response.data}`, { variant: "error" });
      });
  };

  const handleDeleteElement = () => {
    deleteElement(element, shopfront.shopfront_id, shopfront.vendor.id)
      .then((res) => {
        // console.log(res);
        refreshShopfront();
        setDeleteDialogOpen(false);
        enqueueSnackbar("Element deleted!", { variant: "success" });
      })
      .catch((err) => {
        enqueueSnackbar(`Error: ${err.response.data}`, { variant: "error" });
      });
  };

  const selectElementForEdit = (selectedElement) => {
    setElement(selectedElement);
    setElementDialogOpen(true);
  };

  if (!shopfront?.use_welcome_page) return null;

  return (
    <GenericContainer>
      <Typography variant="h5" className={classes.header}>
        Landing Page Elements
      </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.subheader}>
        Organize information and links on your landing page.
      </Typography>

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable" direction="vertical">
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              className={classes.elementsContainer}
              style={getListStyle(snapshot.isDraggingOver)}
              {...provided.droppableProps}
            >
              {shopfront.elements?.map((element, index) => (
                <Draggable key={element.element_id} draggableId={element.element_id} index={index}>
                  {(provided, snapshot) => (
                    <ElementCard
                      element={element}
                      provided={provided}
                      selectElementForEdit={() => selectElementForEdit(element)}
                      selectElementForDelete={() => setElement(element)}
                      setDeleteDialogOpen={setDeleteDialogOpen}
                    />
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </DragDropContext>

      <Button
        startIcon={<Add />}
        variant="contained"
        color="primary"
        fullWidth
        onClick={() => setElementDialogOpen(true)}
      >
        Add
      </Button>
      <ElementDialog
        open={elementDialogOpen}
        setOpen={setElementDialogOpen}
        element={element}
        setElement={setElement}
        handleCreateElement={handleCreateElement}
        handleEditElement={handleEditElement}
      />
      <DeleteElementDialog
        open={deleteDialogOpen}
        setOpen={setDeleteDialogOpen}
        handleDelete={handleDeleteElement}
        setElement={setElement}
      />
    </GenericContainer>
  );
};

export default ShopElements;

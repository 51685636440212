import React, { Fragment, useState, useCallback } from "react";
import { withStyles } from "@material-ui/core/styles";

import {
  FormControl,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Snackbar,
  InputAdornment,
  Menu,
} from "@material-ui/core";
import { FilterList, HelpOutline, MoreVert, Refresh, Search } from "@material-ui/icons";
import { DataGrid } from "@mui/x-data-grid";
import { debounce } from "lodash";
import { Helmet } from "react-helmet";

import PageTitle from "../../components/PageTitle";
import OrderDialog from "./components/OrderDialog";
import UpdateStatusDialog from "./components/UpdateStatusDialog";
import FilterDrawer from "./components/FilterDrawer";
import DeliveryNotificationDialog from "./components/DeliveryNotificationDialog";
import ColumnDefinitionDialog from "./components/ColumnDefinitionDialog";
import useOrdersContext from "../../hooks/useOrdersContext";

import { updateOrderStatus } from "./api";
import { statuses, statusMap, statusColors, paymentMethodMap } from "./helpers/utils";
import { defaultColumns, loadingColumnMapping } from "./helpers/columns";
import { parseDate } from "../../utils";
import { CURRENCY_PREFIX_MAP } from "../../config/currency";

const styles = (theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 190px)", // padding: 30px * 2, title: 80px + 50px, total 190px
    margin: 30,
  },
  row: {
    width: "100%",
    display: "flex",
    margin: theme.spacing(1, 0),
    gap: theme.spacing(1),
    justifyContent: "flex-end",
    alignItems: "center",
  },
  search: {
    background: theme.palette.common.white,
    margin: theme.spacing(0.5, 0),
    [theme.breakpoints.down("sm")]: {
      flex: 1,
    },
  },
  iconButton: {
    height: 40,
    background: theme.palette.common.white,
    minWidth: 0,
    padding: 5,
    margin: theme.spacing(0.5, 0),
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },
  icon: {
    color: theme.palette.grey[600],
  },
  popoverText: {
    fontSize: "12px",
  },
  dataGrid: {
    fontSize: "14px",
  },
  denseSelect: {
    padding: theme.spacing(1),
  },
});

const OrderList = (props) => {
  const { classes } = props;
  const {
    orders,
    loading,
    totalCount,
    searchValue,
    setSearchValue,
    pageNum,
    setPageNum,
    pageSize,
    setSortParams,
    refreshOrders,
    searchOrders,
    vendorId,
    orderColumnDefs,
  } = useOrdersContext();

  // for sorting
  const [sortModel, setSortModel] = useState([]);

  // filters
  const [filterDrawerOpen, setFilterDrawerOpen] = useState(false);

  // row selection
  const [selectedRows, setSelectedRows] = useState([]);
  const [menuAnchor, setMenuAnchor] = useState(null);
  const [statusDialogOpen, setStatusDialogOpen] = useState(false);
  const [deliveryNotificationDialogOpen, setDeliveryNotificationDialogOpen] = useState(false);

  const [orderDialogOpen, setOrderDialogOpen] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState();

  const [columnDefDialog, setColumnDefDialog] = useState(false);

  const [undoSbOpen, setUndoSbOpen] = useState(false);
  const [undoSb, setUndoSb] = useState({
    message: "Test",
    prevState: "",
  });
  const handleSbClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setUndoSbOpen(false);
  };

  const handleSort = (newModel = []) => {
    // if statement to prevent the infinite loop by confirming model is
    // different than the current sortModel state
    if (JSON.stringify(newModel) !== JSON.stringify(sortModel)) {
      setSortModel(newModel);
      setSortParams(
        newModel.reduce(
          (prev, curr) => ({
            ...prev,
            [curr.field]: `${curr.sort === "desc" ? "-" : ""}${curr.field}`,
          }),
          {},
        ),
      );
    }
  };

  // eslint-disable-next-line
  const debouncedSearch = useCallback(
    debounce((searchTerm) => {
      searchOrders(searchTerm);
    }, 1500),
    [],
  );

  const handleSearch = (event) => {
    setSearchValue(event.target.value);
    debouncedSearch(event.target.value);
  };

  const StatusSelect = ({ params }) => {
    return (
      <FormControl variant="outlined" style={{ width: "100%" }}>
        <Select
          id="status-select"
          value={params.row.status}
          onChange={(e) => {
            setUndoSb({
              id: params.row.id,
              message: `Order ${params.row.public_transaction_id} status changed: ${e.target.value}`,
              prevState: params.row.status,
            });
            setUndoSbOpen(true);
            console.log(params.row);
            updateStatus(params.row, e.target.value);
          }}
          classes={{ outlined: classes.denseSelect }}
        >
          <MenuItem disabled value="UNCOMPLETED">
            Uncompleted
          </MenuItem>
          <MenuItem value="PENDING">Pending</MenuItem>
          <MenuItem value="PENDING_VERIFICATION">Pending Verification</MenuItem>
          <MenuItem value="PAID">Paid</MenuItem>
          <MenuItem value="PENDING_DELIVERY">Pending Delivery</MenuItem>
          <MenuItem value="COMPLETED">Completed</MenuItem>
          <MenuItem disabled value="FAILED">
            Failed
          </MenuItem>
        </Select>
      </FormControl>
    );
  };

  const columnDefMapping = {
    public_transaction_id: {
      field: "public_transaction_id",
      headerName: "Order ID",
      sortable: false,
      width: 120,
      renderCell: (params) => (
        <Typography
          variant="body2"
          style={{
            whiteSpace: "normal",
            wordWrap: "break-word",
          }}
        >
          {params.value}
        </Typography>
      ),
    },
    date_created: {
      field: "date_created",
      headerName: "Order Date",
      valueFormatter: (params) => {
        // console.log(params);
        return parseDate(params.value);
      },
      sortable: false,
      width: 160,
    },
    delivery_option: {
      field: "delivery_option",
      headerName: "Delivery Option",
      valueFormatter: (params) => {
        return params.value ? params.value?.name : "-";
      },
      sortable: false,
      width: 180,
    },
    delivery_date: {
      field: "delivery_date",
      headerName: "Delivery Date",
      valueFormatter: (params) => {
        return params.value ? parseDate(params.value) : "-";
      },
      sortable: true,
      width: 170,
    },
    delivery_recipient_name: {
      field: "delivery_recipient_name",
      headerName: "Recipient Name",
      valueFormatter: (params) => (params.value ? params.value : "-"),
      sortable: true,
      width: 200,
    },
    collection_option: {
      field: "collection_option",
      headerName: "Self Collection Option",
      valueFormatter: (params) => {
        return params.value ? params.value?.name : "-";
      },
      sortable: false,
      width: 220,
    },
    collection_date: {
      field: "collection_date",
      headerName: "Self Collection Date",
      valueFormatter: (params) => {
        return params.value ? parseDate(params.value) : "-";
      },
      sortable: true,
      width: 215,
    },
    customer_name: {
      field: "customer_name",
      headerName: "Customer Details",
      sortable: false,
      width: 200,
      renderCell: (params) => (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Typography variant="body2">{params.value}</Typography>
          <Tooltip
            title={
              <Fragment>
                <Typography className={classes.popoverText}>
                  <b>Name: </b>
                  {params.row.customer.customer_name}
                </Typography>
                <Typography className={classes.popoverText}>
                  <b>Email: </b>
                  {params.row.customer.customer_email}
                </Typography>
                <Typography className={classes.popoverText}>
                  <b>Mobile: </b>
                  {params.row.customer.contact_number}
                </Typography>
                <Typography className={classes.popoverText}>
                  <b>Telegram: </b>
                  {params.row.customer.telegram_handle}
                </Typography>
                <Typography className={classes.popoverText}>
                  <b>Instagram: </b>
                  {params.row.customer.instagram_handle}
                </Typography>
              </Fragment>
            }
          >
            <IconButton disableRipple size="small">
              <HelpOutline fontSize="small" />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    total_price: {
      field: "total_price",
      headerName: "Order Amount",
      sortable: false,
      width: 150,
      valueFormatter: (params) => `${CURRENCY_PREFIX_MAP[params.row.currency]}${params.value}`,
    },
    promo_code: {
      field: "promo_code",
      headerName: "Promo Code",
      sortable: false,
      width: 150,
      valueFormatter: (params) => {
        return params.value ? params.value : "-";
      },
    },
    payment_method: {
      field: "payment_method",
      headerName: "Payment Method",
      sortable: false,
      width: 200,
      valueFormatter: (params) => paymentMethodMap[params.value],
    },
    status: {
      field: "status",
      headerName: "Order Status",
      sortable: false,
      width: 180,
      renderCell: (params) => (
        <Typography
          variant="body2"
          style={{ color: statusColors[statuses.indexOf(statusMap[params.value])] }}
        >
          {statusMap[params.value]}
        </Typography>
      ),
    },
    update_status: {
      field: "transaction_id",
      headerName: "Update Status",
      sortable: false,
      width: 200,
      renderCell: (params) => <StatusSelect params={params} />,
    },
    delivery_address: {
      field: "delivery_address",
      headerName: "Delivery Address",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              lineHeight: "1.1em",
            }}
          >
            {params.value ? params.value : "-"}
          </Typography>
        );
      },
    },
    special_request: {
      field: "special_request",
      headerName: "Special Requests",
      sortable: false,
      width: 300,
      renderCell: (params) => {
        return (
          <Typography
            variant="body2"
            style={{
              whiteSpace: "normal",
              wordWrap: "break-word",
              lineHeight: "1.1em",
            }}
          >
            {params.value ? params.value : "-"}
          </Typography>
        );
      },
    },
  };

  // string separated column keys
  // specify order and combination of column definitions
  const columns = orderColumnDefs
    ? orderColumnDefs.split(",").map((col) => columnDefMapping[col])
    : defaultColumns.map((col) => columnDefMapping[col]);

  const updateStatus = (order, newStatus) => {
    updateOrderStatus({ vendorId: order.vendor_id, id: order.id, status: newStatus })
      .then((res) => {
        // console.log(res);
        refreshOrders();
      })
      .catch((err) => console.log(err));
  };

  const handleCellClick = (params, event) => {
    // to disable order modal appearing when updating status from table
    params.field === "transaction_id" && event.stopPropagation();
  };

  return (
    <Fragment>
      <Helmet>
        <title>Orders | Carte</title>
      </Helmet>
      <div className={classes.root}>
        <PageTitle title="Orders" />
        <div className={classes.row}>
          <TextField
            variant="outlined"
            margin="dense"
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <Search color="disabled" />
                </InputAdornment>
              ),
            }}
            className={classes.search}
            size="small"
            value={searchValue}
            onChange={handleSearch}
          />
          <Button
            variant="outlined"
            className={classes.iconButton}
            onClick={() => refreshOrders()}
            disableRipple
            disableFocusRipple
          >
            <Refresh className={classes.icon} />
          </Button>
          <Button
            variant="outlined"
            className={classes.iconButton}
            onClick={() => setFilterDrawerOpen(true)}
            disableRipple
            disableFocusRipple
          >
            <FilterList className={classes.icon} />
          </Button>
          <Button
            aria-controls="simple-menu"
            aria-haspopup="true"
            variant="outlined"
            className={classes.iconButton}
            onClick={(e) => setMenuAnchor(e.currentTarget)}
            disableRipple
            disableFocusRipple
          >
            <MoreVert className={classes.icon} />
          </Button>
          <Menu
            id="simple-menu"
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={() => setMenuAnchor(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            getContentAnchorEl={null}
          >
            <MenuItem
              onClick={() => {
                setStatusDialogOpen(true);
                setMenuAnchor(false);
              }}
            >
              Update Status
            </MenuItem>
            <MenuItem
              onClick={() => {
                setDeliveryNotificationDialogOpen(true);
                setMenuAnchor(false);
              }}
            >
              Send Delivery Notification
            </MenuItem>
            <MenuItem
              onClick={() => {
                setColumnDefDialog(true);
                setMenuAnchor(false);
              }}
            >
              Reorder Columns
            </MenuItem>
          </Menu>
        </div>
        <Paper style={{ width: "100%", height: "70vh" }}>
          {loading ? (
            <DataGrid
              className={classes.dataGrid}
              rows={[...Array(10)].map((item, idx) => ({ id: idx }))}
              columns={defaultColumns.map((col) => loadingColumnMapping[col])}
              checkboxSelection
            />
          ) : (
            <DataGrid
              className={classes.dataGrid}
              rows={orders && orders}
              columns={columns}
              hideFooterRowCount
              rowCount={totalCount}
              onPageChange={(page) => setPageNum(page)}
              sortingMode="server"
              sortModel={sortModel}
              onSortModelChange={handleSort}
              onRowClick={(params) => {
                setSelectedOrder(params.row);
                setOrderDialogOpen(true);
              }}
              pagination
              paginationMode="server"
              pageSize={pageSize}
              page={pageNum}
              checkboxSelection
              disableSelectionOnClick
              onSelectionModelChange={(selection) => setSelectedRows(selection)}
              selectionModel={selectedRows}
              onCellClick={handleCellClick}
              componentsProps={{
                checkbox: { disableFocusRipple: true, disableRipple: true },
              }}
            />
          )}
        </Paper>
      </div>

      <OrderDialog open={orderDialogOpen} setOpen={setOrderDialogOpen} order={selectedOrder} />

      <UpdateStatusDialog
        vendorId={vendorId}
        open={statusDialogOpen}
        setOpen={setStatusDialogOpen}
        rows={orders && orders}
        selectedRows={selectedRows || []}
      />

      <DeliveryNotificationDialog
        open={deliveryNotificationDialogOpen}
        setOpen={setDeliveryNotificationDialogOpen}
        rows={orders && orders}
        selectedRows={selectedRows || []}
      />

      <ColumnDefinitionDialog open={columnDefDialog} setOpen={setColumnDefDialog} />

      <Snackbar
        open={undoSbOpen}
        autoHideDuration={5000}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
        }}
        onClose={handleSbClose}
        message={undoSb.message}
        action={
          <Button
            color="primary"
            onClick={() => {
              updateStatus(undoSb, undoSb.prevState);
              handleSbClose();
            }}
          >
            UNDO
          </Button>
        }
      />

      <FilterDrawer vendorId={vendorId} open={filterDrawerOpen} setOpen={setFilterDrawerOpen} />
    </Fragment>
  );
};

export default withStyles(styles)(OrderList);

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@material-ui/core";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  textField: {
    marginBottom: "10px",
    width: "100%",
  },
}));

const FaqDialog = ({ open, setOpen, faqList, setFaqList, index }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [faq, setFaq] = useState({ question: "", answer: "" });

  useEffect(() => {
    if (index !== -1) {
      setFaq(faqList[index]);
    } else {
      setFaq({ question: "", answer: "" });
    }
  }, [index, faqList]);

  const handleInputChange = (e) => {
    setFaq({
      ...faq,
      [e.target.name]: e.target.value,
    });
  };

  const createFaq = () => {
    if (faq.question === "" || faq.answer === "") {
      enqueueSnackbar(`Question and answer cannot be empty`, { variant: "error" });
      return;
    }

    const updatedFaqList =
      index === -1 ? [...faqList, faq] : faqList.map((item, i) => (i === index ? faq : item));

    setFaqList(updatedFaqList);
    setOpen(false);
  };

  const editMode = index !== -1;

  return (
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      TransitionProps={{
        onExited: () => setFaq({ question: "", answer: "" }),
      }}
    >
      <DialogTitle>{editMode ? "Update" : "New"} FAQ</DialogTitle>
      <DialogContent>
        <TextField
          label="Question"
          name="question"
          value={faq.question}
          onChange={handleInputChange}
          variant="outlined"
          className={classes.textField}
          margin="dense"
          autoComplete="off"
          autoFocus
        />
        <TextField
          label="Answer"
          name="answer"
          value={faq.answer}
          onChange={handleInputChange}
          variant="outlined"
          className={classes.textField}
          margin="dense"
          autoComplete="off"
          multiline
          minRows={4}
          maxRows={10}
        />
      </DialogContent>
      <DialogActions>
        <Button variant="contained" color="primary" onClick={createFaq}>
          {editMode ? "Update" : "Add"}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FaqDialog;

export const IS_DEVELOPMENT = /development/i.test(process.env.REACT_APP_ENVIRONMENT);
export const IS_STAGING = /staging/i.test(process.env.REACT_APP_ENVIRONMENT);

/* eslint-disable */
export const BACKEND_URL = IS_DEVELOPMENT
  ? "http://localhost:8000"
  : IS_STAGING
  ? "https://api.staging.carteapp.io"
  : "https://api.carteapp.io";

export const FRONTEND_URL = IS_DEVELOPMENT
  ? "http://localhost:3000"
  : IS_STAGING
  ? "https://admin.staging.carteapp.io"
  : "https://admin.carteapp.io";

export const SHOP_DOMAIN = IS_DEVELOPMENT
  ? "localhost:3002"
  : IS_STAGING
  ? "staging.carteapp.io"
  : "carteapp.io";

export const AUTH_ENDPOINT = IS_DEVELOPMENT ? "" : "https://auth.carteapp.io";

export const API_VERSION = "v1";

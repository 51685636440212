import React, { Fragment } from "react";
import { makeStyles, Typography } from "@material-ui/core";

import useAuth from "../../../hooks/useAuth";
import GenericContainer from "../../../components/containers/GenericContainer";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
  pending: {
    color: theme.palette.warning.main,
  },
  successful: {
    color: theme.palette.success.main,
  },
}));

const ReferralHistory = () => {
  const classes = useStyles();
  const { vendor } = useAuth();

  if (!vendor) return <Fragment />;

  const curr_referrals =
    vendor.curr_referrals &&
    vendor.curr_referrals.reduce(
      (prev, curr) => {
        if (curr.status === "Successful") {
          prev.successful += 1;
        } else {
          prev.pending += 1;
        }
        return prev;
      },
      {
        pending: 0,
        successful: 0,
      },
    );

  return (
    <GenericContainer style={{ gap: 12 }}>
      <Typography variant="h5" className={classes.header}>
        Referral Status
      </Typography>

      {curr_referrals && (
        <Fragment>
          <Typography variant="body1">
            Discounts are automatically applied to your next payment. Your referrals this month (
            <b>{new Date().toLocaleString("default", { month: "long" })}</b>):
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Pending Referrals: <span className={classes.pending}>{curr_referrals.pending}</span>
          </Typography>
          <Typography variant="body2" color="textSecondary">
            Successful Referrals:{" "}
            <span className={classes.successful}>{curr_referrals.successful}</span>
          </Typography>
        </Fragment>
      )}
      {vendor.referrals && vendor.referrals.length > 0 && (
        <Typography variant="body2" color="textSecondary">
          You have referred {vendor.referrals.length} friend(s), you're the best! No, seriously.
        </Typography>
      )}
    </GenericContainer>
  );
};

export default ReferralHistory;

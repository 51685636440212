import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Typography,
  FormHelperText,
  Radio,
  Switch,
  Button,
  IconButton,
  Divider,
} from "@material-ui/core";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import DebouncedPicker from "./DebouncedPicker";
import GenericContainer from "../../../components/containers/GenericContainer";
import useShopContext from "../../../hooks/useShopContext";
import { DropzoneAreaBase } from "material-ui-dropzone";
import { deleteShopLogo, updateShopLogo } from "../api";
import { Delete } from "@material-ui/icons";

const useStyles = makeStyles((theme) => ({
  header: {
    fontWeight: 400,
    margin: theme.spacing(1, 0, 1),
  },
  subheader: {
    marginBottom: theme.spacing(1),
  },
  textField: {
    margin: theme.spacing(1, 0),
  },
  colorPickerContainer: {
    display: "flex",
  },
  colorPicker: {
    height: 100,
  },
  divider: {
    margin: theme.spacing(1, 0),
  },
  toggleSection: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },
  imageItem: {
    height: "150px",
  },
  image: {
    height: "150px",
    objectFit: "contain",
  },
  deleteBtn: {
    position: "relative",
    bottom: 145,
    right: 18,
    height: 36,
    width: 35,
    [theme.breakpoints.down("sm")]: {
      right: 11,
      width: 20,
    },
  },
  previewContainer: {
    width: "100%",
    margin: 0,
  },
  previewGridItem: {
    maxWidth: "100%",
    flexBasis: "auto",
    padding: 0,
  },
  dropzoneContainer: {
    margin: theme.spacing(1, 0),
    width: "50%",
    maxWidth: 250,
    minHeight: 70,
    height: 110,
    // padding: theme.spacing(3),
    [theme.breakpoints.down("sm")]: {
      width: "100%",
      maxWidth: "none",
      height: 95,
    },
  },
  dropzoneParagraph: {
    fontSize: 14,
    padding: theme.spacing(0, 1),
  },
  paperSelectRoot: {
    display: "flex",
    [theme.breakpoints.down("sm")]: {
      flexDirection: "column",
    },
  },
  paperSelectContainer: {
    margin: theme.spacing(1),
    flex: 1,
  },
  paperSelectHeader: {
    display: "flex",
    alignItems: "center",
  },
  paperSelectContent: {
    marginLeft: "38px",
  },
  columnRoot: {
    height: 150,
    width: 75,
    margin: theme.spacing(1, 0),
    display: "flex",
    borderRadius: 10,
    flexDirection: "column",
  },
  columnItem: {
    margin: 4,
    flex: 1,
    borderRadius: 5,
  },
  gridRoot: {
    height: 150,
    width: 75,
    margin: theme.spacing(1, 0),
    borderRadius: 10,
    display: "grid",
    gridTemplateColumns: "50% 50%",
    gridTemplateRows: "25% 25% 25% 25%",
  },
  gridItem: {
    borderRadius: 5,
  },
}));

const ShopTheme = () => {
  const classes = useStyles();
  const { shopfront, setShopfront } = useShopContext();

  const handleAddLogo = (imageArr) => {
    setShopfront({ ...shopfront, logo: imageArr[0].data });
    updateShopLogo(shopfront.vendor.id, shopfront.shopfront_id, imageArr[0].file).then((res) => {
      //   console.log(res);
    });
  };

  const handleDeleteLogo = () => {
    setShopfront({ ...shopfront, logo: null });
    deleteShopLogo(shopfront.vendor.id, shopfront.shopfront_id).then((res) => {
      //   console.log(res);
    });
  };

  return (
    <GenericContainer>
      <Typography variant="h5" className={classes.header}>
        Theme & Layout
      </Typography>
      <Typography variant="body2" color="textSecondary" className={classes.subheader}>
        Customize the look and feel of your shop
      </Typography>

      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography>Background: </Typography>
        <DebouncedPicker
          initialColor={shopfront.background_color}
          color={shopfront.background_color}
          setColor={(color) => setShopfront({ ...shopfront, background_color: color })}
        />
        <FormHelperText>Light colors work best here.</FormHelperText>
      </div>
      <div style={{ display: "flex", alignItems: "center" }}>
        <Typography>Accent: </Typography>
        <DebouncedPicker
          initialColor={shopfront.accent_color}
          color={shopfront.accent_color}
          setColor={(color) => setShopfront({ ...shopfront, accent_color: color })}
        />
        <FormHelperText>Darker colors work best here.</FormHelperText>
      </div>

      <Divider className={classes.divider} />

      <div className={classes.toggleSection}>
        <Typography>Bordered Style</Typography>
        <Switch
          checked={shopfront.bordered}
          onChange={(e) => setShopfront({ ...shopfront, bordered: e.target.checked })}
          color="primary"
        />
      </div>

      <Divider className={classes.divider} />

      <div className={classes.toggleSection}>
        <Typography>Display Logo in Shop</Typography>
        <Switch
          checked={shopfront.display_logo}
          onChange={(e) => setShopfront({ ...shopfront, display_logo: e.target.checked })}
          color="primary"
        />
      </div>
      {shopfront.display_logo && (
        <>
          <div style={{ marginTop: 8 }}>
            {shopfront.logo ? (
              <div className={classes.imageItem}>
                <img src={shopfront.logo} className={classes.image} alt="shop's logo" />
                <IconButton
                  disableRipple
                  size="small"
                  className={classes.deleteBtn}
                  onClick={() => handleDeleteLogo()}
                >
                  <Delete />
                </IconButton>
              </div>
            ) : (
              <>
                <DropzoneAreaBase
                  dropzoneText={
                    <>
                      Drop image here or{" "}
                      <Button variant="contained" color="primary" style={{ color: "#fff" }}>
                        Upload Image
                      </Button>
                    </>
                  }
                  dropzoneParagraphClass={classes.dropzoneParagraph}
                  dropzoneClass={classes.dropzoneContainer}
                  acceptedFiles={["image/*"]}
                  filesLimit={1}
                  maxFileSize={10000000} // allow up to 10MB
                  onAdd={(newPhoto) => handleAddLogo(newPhoto)}
                />
                <Typography color="textSecondary" variant="body2">
                  Transparent PNG files work best! :)
                </Typography>
              </>
            )}
          </div>
          <div style={{ margin: "8px 0" }}>
            <ToggleButtonGroup
              value={shopfront.logo_size}
              exclusive
              onChange={(e, value) => {
                setShopfront({
                  ...shopfront,
                  logo_size: value,
                });
              }}
              aria-label="logo size"
              size="small"
            >
              <ToggleButton
                value="regular"
                aria-label="regular logo size"
                disableRipple
                disableTouchRipple
              >
                Regular (64px)
              </ToggleButton>
              <ToggleButton
                value="large"
                aria-label="large logo size"
                disableRipple
                disableTouchRipple
              >
                Large (96px)
              </ToggleButton>
            </ToggleButtonGroup>
          </div>
        </>
      )}

      <Divider className={classes.divider} />

      {/* <div>
        <FormControlLabel
          control={
            <Switch
              checked={vendor.enable_seasonal_features || false}
              onChange={(e) =>
                setVendor({
                  ...vendor,
                  enable_seasonal_features: e.target.checked,
                })
              }
              color="primary"
            />
          }
          label="Enable Seasonal Features"
        />
      </div> */}

      <Typography style={{ marginTop: 16 }}>Shop Layout: </Typography>
      <div className={classes.paperSelectRoot}>
        <div className={classes.paperSelectContainer}>
          <div className={classes.paperSelectHeader}>
            <Radio
              size="small"
              color="primary"
              checked={shopfront.layout === "column"}
              onChange={(e) => setShopfront({ ...shopfront, layout: "column" })}
            />
            <Typography
              onClick={(e) => setShopfront({ ...shopfront, layout: "column" })}
              color="textSecondary"
              variant="body1"
            >
              Column
            </Typography>
          </div>
          <div className={classes.paperSelectContent}>
            <Typography color="textSecondary">Large images and description</Typography>
            <div
              className={classes.columnRoot}
              style={{
                border: shopfront.bordered ? `2px solid ${shopfront.accent_color}` : "none",
                backgroundColor: shopfront.background_color,
              }}
            >
              {Array(3)
                .fill("")
                .map((item, index) => (
                  <div
                    key={`columnItem-${index}`}
                    className={classes.columnItem}
                    style={{
                      background: "#FFF",
                      border: shopfront.bordered ? `2px solid ${shopfront.accent_color}` : "none",
                      margin: shopfront.bordered ? 2 : 4,
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
        <div className={classes.paperSelectContainer}>
          <div className={classes.paperSelectHeader}>
            <Radio
              size="small"
              color="primary"
              checked={shopfront.layout === "grid"}
              onChange={(e) => setShopfront({ ...shopfront, layout: "grid" })}
            />
            <Typography
              onClick={(e) => setShopfront({ ...shopfront, layout: "grid" })}
              color="textSecondary"
              variant="body1"
            >
              Grid
            </Typography>
          </div>
          <div className={classes.paperSelectContent}>
            <Typography color="textSecondary">Two columns with smaller images</Typography>
            <div
              className={classes.gridRoot}
              style={{
                border: shopfront.bordered ? `2px solid ${shopfront.accent_color}` : "none",
                backgroundColor: shopfront.background_color,
              }}
            >
              {Array(8)
                .fill("")
                .map((item, index) => (
                  <div
                    key={`gridItem-${index}`}
                    className={classes.gridItem}
                    style={{
                      background: "#FFF",
                      border: shopfront.bordered ? `2px solid ${shopfront.accent_color}` : "none",
                      margin: shopfront.bordered ? 2 : 4,
                    }}
                  />
                ))}
            </div>
          </div>
        </div>
      </div>
    </GenericContainer>
  );
};

export default ShopTheme;

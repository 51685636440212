import Auth from "../../../Auth";
import { BACKEND_URL, API_VERSION } from "../../../config";

export const updateOrderStatus = ({ vendorId, id, status }) => {
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}/orders/${id}?status=${status}`,
  );
};

export const batchUpdateOrderStatus = ({ vendorId, status, transactionIds }) => {
  return Auth.client.patch(
    `${BACKEND_URL}/${API_VERSION}}/vendors/${vendorId}/orders?status=${status}`,
    {
      transactions: transactionIds,
    },
  );
};

export const sendDeliveryNotification = ({ transaction }) => {
  return Auth.client.post(
    `${BACKEND_URL}/${API_VERSION}/vendors/${transaction.vendor_id}/orders/${transaction.transaction_id}/delivery-notification`,
    {
      transaction: transaction,
    },
  );
};

export const updateVendorOrderColDefs = (vendorId, colDefs = []) => {
  const colDefStr = colDefs.join(",");
  return Auth.client.patch(`${BACKEND_URL}/${API_VERSION}/vendors/${vendorId}`, {
    order_column_defs: colDefStr,
  });
};

import React, { Fragment, useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Button, Switch } from "@material-ui/core";
import { Add, Check, Close } from "@material-ui/icons";
import { useSnackbar } from "notistack";
import { Helmet } from "react-helmet";

import PageTitle from "../../components/PageTitle";
import PromoCodeDialog from "./components/PromoCodeDialog";
import DeletePromoDialog from "./components/DeletePromoDialog";
import TableListing from "../../components/TableListing";
import useAuth from "../../hooks/useAuth";
import { CURRENCY_PREFIX_MAP } from "../../config/currency";

import {
  createPromoCode,
  deletePromoCode,
  editPromoCode,
  getVendorPromoCodes,
  updatePromoCodeStatus,
} from "./api";
import { fetchProducts } from "../ProductList/api";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "calc(100vh - 190px)", // padding: 30px * 2, title: 80px + 50px, total 190px
    marginBottom: 100,
    margin: 30,
  },
  button: {
    margin: theme.spacing(0, 1),
    [theme.breakpoints.down("sm")]: {
      minWidth: "100%",
      margin: theme.spacing(1, 0),
    },
  },
  check: {
    color: theme.palette.success.main,
  },
}));

const PromoCodes = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { vendor } = useAuth();

  const currencyPrefix = CURRENCY_PREFIX_MAP[vendor.currency];

  const [openDialog, setOpenDialog] = useState(false);

  const [rows, setRows] = useState([]);
  const [order, setOrder] = useState("desc");
  const [orderBy, setOrderBy] = useState("start_date");
  const [pageNum, setPageNum] = useState(0);
  const [totalCount, setTotalCount] = useState(0);

  const [products, setProducts] = useState([]);
  const [promoCode, setPromoCode] = useState({
    promo_code: "",
    discount_value: "",
    discount_type: "VALUE",
    start_date: new Date(),
    num_days: 1,
    min_spend: "",
    max_orders: "",
    first_time_customer: false,
    products: [],
  });

  const [loading, setLoading] = useState(true);

  const [editMode, setEditMode] = useState(false);

  const [deleteDialog, setDeleteDialog] = useState(false);
  const [deletePromoId, setDeletePromoId] = useState("");

  const displayPromoPeriod = (promoCode) => {
    if (promoCode) {
      if (promoCode.num_days) {
        return promoCode.num_days + " days";
      }
    }
    return "-";
  };

  const displayDiscount = (promoCode) => {
    if (promoCode) {
      if (promoCode.discount_type === "PERCENTAGE") {
        return parseFloat(promoCode.discount_value) + "%";
      } else if (promoCode.discount_type === "VALUE") {
        return currencyPrefix + parseFloat(promoCode.discount_value).toFixed(2);
      }
    }
    return "-";
  };

  const displayMinSpending = (promoCode) => {
    if (promoCode) {
      if (promoCode.min_spend) {
        return currencyPrefix + parseFloat(promoCode.min_spend).toFixed(2);
      }
    }
    return "-";
  };

  const displayMaxRedemptions = (promoCode) => {
    if (promoCode) {
      if (promoCode.max_orders) {
        return promoCode.max_orders;
      }
    }
    return "-";
  };

  const displayFirstTimeCustomer = (promoCode) => {
    if (promoCode && promoCode.first_time_customer) {
      return <Check className={classes.check} />;
    }
    return <Close />;
  };

  const displayApplicableProducts = (promoCode) => {
    if (promoCode) {
      if (promoCode.products.length > 0) {
        return promoCode.products.length;
      } else {
        return "All Products";
      }
    }
    return "-";
  };

  const activatePromoCodeToggle = (promoCode) => {
    return (
      <Fragment>
        <Switch
          color="primary"
          checked={promoCode.active}
          onClick={(e) => {
            // need to switch to onClick and add the next line
            // so as to not intefere with row onclick
            e.stopPropagation();
            updatePromoCodeStatus(vendor.id, promoCode)
              .then((res) => {
                fetchPromoAndProducts();
              })
              .catch((err) => {
                enqueueSnackbar((err.response && err.response.data) || "Error updating promotion", {
                  variant: "error",
                });
              });
          }}
        />
      </Fragment>
    );
  };

  const headCells = [
    { id: "promo_code", numeric: false, disablePadding: true, label: "Promo Code", width: "10%" },
    {
      id: "start_date",
      numeric: false,
      disablePadding: true,
      label: "Start Date",
      // sort: true,
      date: true,
      width: "8%",
    },
    {
      id: "num_days",
      numeric: false,
      disablePadding: true,
      label: "Promo Period",
      width: "8%",
      isFunction: { function: displayPromoPeriod },
    },
    {
      id: "discount_value",
      numeric: false,
      disablePadding: true,
      label: "Discount",
      width: "5%",
      isFunction: { function: displayDiscount },
    },
    {
      id: "min_spend",
      numeric: true,
      disablePadding: true,
      label: "Min. Spending",
      width: "8%",
      isFunction: { function: displayMinSpending },
    },
    {
      id: "max_orders",
      numeric: true,
      disablePadding: true,
      label: "Max. Redemptions",
      width: "8%",
      isFunction: { function: displayMaxRedemptions },
    },
    {
      id: "current_orders",
      numeric: true,
      disablePadding: true,
      label: "Current Redemptions",
      width: "8%",
    },
    {
      id: "products",
      numeric: true,
      disablePadding: true,
      label: "Applicable Product(s)",
      width: "10%",
      isFunction: { function: displayApplicableProducts },
    },
    {
      id: "first_time_customer",
      numeric: false,
      disablePadding: true,
      label: "One Redemption Per Customer",
      width: "8%",
      isFunction: { function: displayFirstTimeCustomer },
    },
    {
      id: "active",
      numeric: false,
      disablePadding: true,
      label: "Active",
      sort: false,
      width: "5%",
      isFunction: {
        function: activatePromoCodeToggle,
      },
    },
  ];

  const selectRow = (promoCode) => {
    setEditMode(true);
    setPromoCode({
      promo_code: promoCode.promo_code,
      discount_value: promoCode.discount_value,
      discount_type: promoCode.discount_type,
      start_date: new Date(promoCode.start_date),
      num_days: promoCode.num_days,
      min_spend: promoCode.min_spend ? promoCode.min_spend : "",
      max_orders: promoCode.max_orders ? promoCode.max_orders : "",
      id: promoCode.promo_code_id,
      first_time_customer: promoCode.first_time_customer,
      products: promoCode.products,
    });

    setOpenDialog(true);
  };

  const fetchPromoAndProducts = () => {
    Promise.all([
      fetchProducts(vendor.id, { active: true, variants: false }),
      getVendorPromoCodes(vendor.id),
    ])
      .then((res) => {
        // console.log(res);
        setProducts(res[0].data);
        setRows(res[1].data);
        setTotalCount(res[1].data.length);
        setLoading(false);
      })
      .catch((err) => console.log(err));
  };

  const handleDeletePromoCode = () => {
    deletePromoCode(vendor.id, deletePromoId)
      .then((res) => {
        setOpenDialog(false);
        setDeleteDialog(false);
        setDeletePromoId("");
        setRows(res.data);
        setTotalCount(res.data.length);
        enqueueSnackbar("Promo code deleted successfully!", { variant: "success" });
      })
      .catch((err) => console.log(err));
  };

  const handleEditPromoCode = (obj) => {
    editPromoCode(vendor.id, obj)
      .then((res) => {
        // console.log(res);
        setOpenDialog(false);
        setTimeout(() => {
          setPromoCode({
            promo_code: "",
            discount_value: "",
            discount_type: "VALUE",
            start_date: new Date(),
            num_days: 1,
            min_spend: "",
            max_orders: "",
            first_time_customer: false,
            products: [],
          });
          setEditMode(false);
        }, 500);
        enqueueSnackbar("Promo code updated successfully!", { variant: "success" });
        setRows(res.data);
        setTotalCount(res.data.length);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          // meaning promo code title already exists
          enqueueSnackbar("The entered promo code already exists. Please enter a different one.", {
            variant: "error",
          });
        } else {
          enqueueSnackbar((err.response && err.response.data) || "Error updating promotion", {
            variant: "error",
          });
        }
      });
  };

  const handleCreatePromoCode = (obj) => {
    createPromoCode(vendor.id, obj)
      .then((res) => {
        // console.log(res);
        setOpenDialog(false);
        setTimeout(() => {
          setPromoCode({
            promo_code: "",
            discount_value: "",
            discount_type: "VALUE",
            start_date: new Date(),
            num_days: 1,
            min_spend: "",
            max_orders: "",
            first_time_customer: false,
            products: [],
          });
        }, 500);
        enqueueSnackbar("Promo code created successfully!", { variant: "success" });
        setRows(res.data);
        setTotalCount(res.data.length);
      })
      .catch((err) => {
        if (err.response.status === 409) {
          // meaning promo code title already exists
          enqueueSnackbar("The entered promo code already exists. Please enter a different one.", {
            variant: "error",
          });
        } else {
          enqueueSnackbar((err.response && err.response.data) || "Error creating promotion", {
            variant: "error",
          });
        }
      });
  };

  useEffect(() => {
    fetchPromoAndProducts();
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.root}>
      <Helmet>
        <title>Promotions | Carte</title>
      </Helmet>
      <PageTitle title="Promo Codes" />
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          variant="contained"
          color="primary"
          className={classes.button}
          startIcon={<Add />}
          disableElevation
          onClick={() => setOpenDialog(true)}
        >
          New Promo Code
        </Button>
      </div>
      <div style={{ marginTop: 8 }}>
        <TableListing
          headCells={headCells}
          rows={rows}
          order={order}
          setOrder={setOrder}
          orderBy={orderBy}
          setOrderBy={setOrderBy}
          pageNum={pageNum}
          setPageNum={setPageNum}
          totalCount={totalCount}
          variable="Promo Codes"
          hover={true}
          rowOnClick={(row) => selectRow(row)}
          loading={loading}
        />
      </div>
      <PromoCodeDialog
        openDialog={openDialog}
        setOpenDialog={setOpenDialog}
        promoCode={promoCode}
        setPromoCode={setPromoCode}
        editMode={editMode}
        setEditMode={setEditMode}
        setDeleteDialog={setDeleteDialog}
        setDeletePromoId={setDeletePromoId}
        handleCreatePromoCode={handleCreatePromoCode}
        handleEditPromoCode={handleEditPromoCode}
        products={products}
      />
      <DeletePromoDialog
        deleteDialog={deleteDialog}
        setDeleteDialog={setDeleteDialog}
        setDeletePromoId={setDeletePromoId}
        handleDeletePromoCode={handleDeletePromoCode}
      />
    </div>
  );
};

export default PromoCodes;

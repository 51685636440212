import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
} from "@material-ui/core";
import { Add, Clear, DragHandle } from "@material-ui/icons";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSnackbar } from "notistack";

import useAuth from "../../../hooks/useAuth";
import { defaultColumns, loadingColumnMapping } from "../helpers/columns";
import { updateVendorOrderColDefs } from "../api";

const useStyles = makeStyles((theme) => ({
  paper: {
    width: "40%",
    maxHeight: "90vh",
    [theme.breakpoints.down("sm")]: {
      width: "90%",
    },
  },
  columnContainer: {
    display: "flex",
    flexDirection: "column",
    borderRadius: theme.spacing(0.5),
  },
  paperRoot: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    margin: theme.spacing(0.5, 0),
    padding: theme.spacing(1),
  },
  row: {
    display: "flex",
    gap: theme.spacing(1),
    alignItems: "center",
  },
}));

const ColumnDefinitionDialog = ({ open, setOpen }) => {
  const classes = useStyles();
  const { vendor, refresh } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [menuAnchor, setMenuAnchor] = useState(null);

  const [columns, setColumns] = useState(vendor?.order_column_defs || defaultColumns);
  const [deselectedColumns, setDeselectedColumns] = useState([]);

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    let reorderedColumns = reorder(columns, result.source.index, result.destination.index);
    setColumns(reorderedColumns);
  };

  const getListStyle = (isDraggingOver) => ({
    padding: 8,
    background: isDraggingOver ? "#FFF2D2" : "#ebebeb",
  });

  const handleUpdateColumnDefs = () => {
    updateVendorOrderColDefs(vendor.id, columns)
      .then(() => {
        refresh();
        enqueueSnackbar("Updated!", { variant: "success" });
        setOpen(false);
      })
      .catch((err) =>
        enqueueSnackbar(`Oops, something went wrong. Kindly reach out to us for assistance`, {
          variant: "error",
        }),
      );
  };

  const handleDeselectColumn = (column) => {
    setColumns(columns.filter((col) => col !== column));
    setDeselectedColumns([...deselectedColumns, column]);
  };

  const handleSelectColumn = (column) => {
    setDeselectedColumns(deselectedColumns.filter((col) => col !== column));
    setColumns([...columns, column]);
  };

  useEffect(() => {
    if (vendor?.order_column_defs) {
      setColumns(vendor.order_column_defs.split(","));
      setDeselectedColumns(
        defaultColumns.filter((col) => vendor.order_column_defs.indexOf(col) < 0),
      );
    }
  }, [vendor]);

  return (
    <Dialog open={open} onClose={() => setOpen(false)} classes={{ paper: classes.paper }}>
      <DialogTitle>Reorder Columns</DialogTitle>
      <DialogContent>
        <div className={classes.row}>
          <Typography>Selected Columns</Typography>
          <IconButton
            aria-controls="add-column"
            aria-haspopup="true"
            color="primary"
            size="small"
            variant="contained"
            onClick={(e) => setMenuAnchor(e.currentTarget)}
            disabled={deselectedColumns?.length <= 0}
          >
            <Add />
          </IconButton>
          <Menu
            id="add-column"
            anchorEl={menuAnchor}
            keepMounted
            open={Boolean(menuAnchor)}
            onClose={() => setMenuAnchor(null)}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            transformOrigin={{
              vertical: "top",
              horizontal: "left",
            }}
            getContentAnchorEl={null}
          >
            {deselectedColumns?.map((column) => (
              <MenuItem
                key={column}
                onClick={() => {
                  handleSelectColumn(column);
                  // Unmount menu when last column is added
                  if (deselectedColumns?.length === 1) {
                    setMenuAnchor(false);
                  }
                }}
              >
                {loadingColumnMapping[column]?.headerName}
              </MenuItem>
            ))}
          </Menu>
        </div>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId="droppable" direction="vertical">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                className={classes.columnContainer}
                style={getListStyle(snapshot.isDraggingOver)}
                {...provided.droppableProps}
              >
                {columns?.map((column, index) => (
                  <Draggable key={column} draggableId={column} index={index}>
                    {(provided, snapshot) => (
                      <Paper
                        className={classes.paperRoot}
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                      >
                        <Typography>{loadingColumnMapping[column]?.headerName}</Typography>
                        <div className={classes.row}>
                          <IconButton size="small" onClick={() => handleDeselectColumn(column)}>
                            <Clear fontSize="small" />
                          </IconButton>
                          <div {...provided.dragHandleProps}>
                            <DragHandle />
                          </div>
                        </div>
                      </Paper>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setOpen(false)}>Cancel</Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            handleUpdateColumnDefs();
          }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ColumnDefinitionDialog;

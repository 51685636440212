import { createContext, useContext, useEffect, useState } from "react";

import Auth from "../Auth";
import { API_VERSION, BACKEND_URL } from "../config";
import useAuth from "./useAuth";

const ShopContext = createContext({
  shopfront: {},
  shopfronts: [],
  updateShopfront: () => {},
  refreshShopfront: () => {},
});

export const ShopProvider = ({ children }) => {
  const { vendor, loading: vendor_loading } = useAuth();
  const [shopfront, setShopfront] = useState({
    shopfront_type: "shop",
    layout: "column",
    background_color: "#FFFBF6",
    accent_color: "#8B5725",
    title: "",
    description: "",
    is_active: true,
    display_logo: false,
    logo: null,
    logo_size: "regular",
    background_image: null,
    use_welcome_page: false,
    use_categories: false,
    meta_tag_content: "",
    meta_pixel_id: "",
    meta_tag_is_active: false,
    text_background_opacity: 0.8,
    text_color: "#000000",
    reorder_soldout_products: false,
    bordered: true,
    geo_blocked: false,
    restricted_countries: [],
    enter_button_text: "",
    shop_settings: {},
  });
  const [shopfronts, setShopfronts] = useState([]);
  const [loading, setLoading] = useState(true);

  const getShopfronts = () => {
    setLoading(true);
    Auth.client.get(`${BACKEND_URL}/${API_VERSION}/vendors/${vendor.id}/shopfronts`).then((res) => {
      const currentShop = res.data[0];
      setShopfronts(res.data);
      if (currentShop) {
        setShopfront(currentShop);
      }
      setLoading(false);
    });
  };

  const getShopfront = () => {
    setLoading(true);
    Auth.client
      .get(
        `${BACKEND_URL}/${API_VERSION}/vendors/${vendor.id}/shopfronts/${shopfront.shopfront_id}`,
      )
      .then((res) => {
        const currentShop = res.data;
        if (currentShop) {
          setShopfront(currentShop);
        }
        setLoading(false);
      });
  };

  const updateShopfront = () => {
    // current only updating 1 shopfront
    const formData = new FormData();
    for (const attr in shopfront) {
      if (attr === "background_image") {
        formData.append(attr, shopfront[attr]?.file ? shopfront[attr].file : shopfront[attr]);
      } else if (attr === "restricted_countries") {
        formData.append(attr, JSON.stringify(shopfront[attr]));
      } else if (attr === "shop_settings") {
        formData.append(attr, JSON.stringify(shopfront[attr]));
      } else {
        formData.append(attr, shopfront[attr]);
      }
    }

    return Auth.client
      .put(
        `${BACKEND_URL}/${API_VERSION}/vendors/${vendor.id}/shopfronts/${shopfront.shopfront_id}`,
        formData,
      )
      .then((res) => {
        const updatedShop = res.data;
        setShopfront(updatedShop);
      });
  };

  const refreshShopfront = () => {
    getShopfront();
  };

  useEffect(() => {
    if (!vendor_loading) {
      getShopfronts();
    }
    // eslint-disable-next-line
  }, [vendor_loading]);

  return (
    <ShopContext.Provider
      value={{
        shopfront,
        setShopfront,
        shopfronts,
        setShopfronts,
        updateShopfront,
        refreshShopfront,
        loading,
      }}
    >
      {children}
    </ShopContext.Provider>
  );
};

const useShopContext = () => {
  return useContext(ShopContext);
};

export default useShopContext;
